import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react'
import styled, {ThemeProvider} from 'styled-components'
import {useTranslation} from "react-i18next";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {motion} from "framer-motion";
import Tilt from '../../libs/Tilt'
import { useSelector, useDispatch } from 'react-redux'
import {
	ShimmerThumbnail
} from "react-shimmer-effects";
import {
	unselectByCategory,
	selectByCategory
} from '../../store/features/gearSelectorState'
import {API_HOST, fittingData, fittingGearData, getServerCategory} from "../../data/data";
import Loader from '../Loader'

import svgCheck from '../../assets/Svgs/check_circle.svg'
import axios from "axios";

const CardWrapper = styled(motion.div)`
	position: relative;
	width: calc(33.33% - 36px/3);
	
	${props => props.bgi && `background-image: url(${props.bgi})`};
	
	&.loading {
		pointer-events: none;
	}
	
	@media (max-width: 1100px) {
		width: calc(50% - 8px);
	}
	@media (max-width: 800px) {
		width: calc(50% - 6px);
	}
	
`
const Card = styled(motion.div)`
	background: rgba(255, 255, 255, 0.05);
	position: relative;
	cursor: pointer;
	border-radius: 12px;
	max-height: 180px;
	overflow: hidden;
	
	&.active {
		&.cat {
			background-color: ${props => props.theme.colors.pink};
		}
		&.ufo {
			background-color: ${props => props.theme.colors.green};
		}
	}
	
	span {
		position: absolute;
		bottom: 0;
		left: 0;
	} 
	
	
	
	span, div {
		width: 100%;
		height: 100%;
	}
	
	> div.text-center {
		position: absolute;
		top: -50%;
		left: -50%;
		width: 200%;
		height: 200%;
		transform: rotate(20deg);
	}
	
	.tilt {
		position: absolute;
		top: -50%;
		left: -50%;
		width: 200%;
		height: 200%;
		transform: rotate(20deg);
	}
	
	.lazyWrap {
		display: flex;
		align-items: flex-end;
	}
	
	.shimmer-thumb {
		width: 100%;
		height: 100% !important;
		animation: shimmer 3s cubic-bezier(0.54,-0.15, 0.58, 1) infinite forwards;
		margin-bottom: 0;
		background: linear-gradient(to right, 
		rgba(255, 255, 255, 0.05) 8%, 
		rgba(255, 255, 255, 0.15) 18%, 
		rgba(255, 255, 255, 0.05) 33%);
		background-size: 1000px 100%;
	}
`
const PlaceholderImage = styled.img`
	display: block;
	max-width: 100%;
	height: auto;
	opacity: 0;
	visibility: hidden;
`
const CheckImage = styled.img`
	position: absolute;
	right: 8px;
	top: 8px;
	z-index: 5;
`
const GearImage = styled.span`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	pointer-events: none;
	text-align: center;
	
	&.blank {
		z-index: 1;
	}
	
	img {
		display: block;
		position: absolute;
		margin: 0 auto;
		max-width: 100%;
		max-height: 100%;
		height: 100%;
		width: 100%;
		object-fit: contain;
		object-position: center bottom;
	}
`

const GearBackground = styled.span`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	pointer-events: none;
	
	img {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`

const FlipPlaceholder = styled.span`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	
	img {
		max-width: 100%;
		height: auto;
	}
`

const GearCard = (props) => {
	const gearCount = useSelector((state) => state.gearCount.countData)
	const selectedToy = useSelector((state) => state.toySelector.toy)
	const selectedGear = useSelector((state) => state.gearSelector)
	const selectedCategory = useSelector((state) => state.categorySelector.category)
	const isActive = selectedGear[selectedCategory].id === props.id

	const [loaded, setLoaded] = useState(false);
	const [imgSrc, setImgSrc] = useState(null);
	const dispatch = useDispatch()

	const cardClass = `
		gear-card ${selectedToy.toString()} ${isActive && 'active'}
	`

	const gearData = {
		id: props.id,
		image: props.image,
		gear: selectedCategory
	}

	const gearSelectEvent = new CustomEvent('gearSelect', { detail: selectedCategory });

	useEffect(() => {
		if(imgSrc) {
			gearData.image = imgSrc
		}
		//if(isActive) dispatch(selectByCategory(gearData))
	}, [imgSrc])

	useEffect(() => {
		if(gearCount[selectedToy][selectedCategory]){
			getImg()
		}
	}, [selectedToy])

	const getImg = async () => {
		const instance = axios.create({
			method: 'post',
			headers: {"Content-type": "image/png"},
			responseType: 'blob'
		});

		instance.post(API_HOST, {
			category: selectedCategory,
			type: selectedToy,
			size: 'small',
			model: props.id.toString(),
			color: "1"
		})
				.then(response => response.data)
				.then(data => {
					setImgSrc(URL.createObjectURL(data));
					//setImgSrc("https://cache.tonapi.io/imgproxy/q7oUhRNV7jVdTL0mxIFecKP7hxyAZHr1f5vCZcXaKHs/rs:fill:1500:1500:1/g:no/aHR0cHM6Ly9zZXJ2ZXIudG9uZ3V5cy5vcmcvbmZ0cy9pdGVtcy9zaGlydDlfOS5wbmc.webp");
					console.log("Картинка:" + data)
					setLoaded(true)
				})
	}

	const setSelectedGear = () => {
		if(isActive) {
			dispatch(unselectByCategory(selectedCategory))
			document.dispatchEvent(new CustomEvent('gearSelect', { detail: {category: selectedCategory, id: null, image: null}}));
		}else{
			const imageUrl = imgSrc || props.image
			dispatch(selectByCategory(gearData))
			document.dispatchEvent(new CustomEvent('gearSelect', { detail: {category: selectedCategory, id: props.id, image: imageUrl, toy: selectedToy}}));
		}
	}

	const getBlankToyImage = () => {
		return <LazyLoadImage
			effect="blur"
			wrapperClassName={'lazyWrap'}
			src={fittingData[selectedToy].blank} // use normal <img> attributes as props
		/>
	}

	const imageUrl = imgSrc || props.thumbnail || null
	const downloading = isActive && selectedGear[selectedCategory].loading

	return (
		<CardWrapper className={!loaded && 'loading'}>
			<Tilt className={'tilt'}>
				<Card
						whileHover={{scale: 1.05}}
						className={cardClass}
						data-flip-id={isActive ? `flip-view-${selectedCategory}` : ''}
						onClick={() => setSelectedGear()}
				>
					{downloading && <Loader/>}
					{selectedCategory === 'background' &&
					<GearBackground>
						<LazyLoadImage
								beforeLoad={() => getImg()}
								afterLoad={() => setLoaded(true)}
								effect="blur"
								src={imageUrl} // use normal <img> attributes as props
						/>
					</GearBackground>
					}

					<PlaceholderImage src={fittingData.cat.blank}/>
					{(props.isLoading || !loaded) && <ShimmerThumbnail className="shimmer-thumb" center rounded/>}
					{isActive && <CheckImage src={svgCheck}/>}
					<GearImage className={'blank'}>
						{selectedToy === 'cat' && getBlankToyImage()}
						{selectedToy === 'ufo' && getBlankToyImage()}
					</GearImage>

					{selectedCategory !== 'background' &&
					<GearImage>
						<LazyLoadImage
								beforeLoad={() => getImg()}
								afterLoad={() => setLoaded(true)}
								effect="blur"
								src={imageUrl} // use normal <img> attributes as props
						/>
					</GearImage>
					}
					{/*{(imgSrc && !props.isLoading) &&

					}*/}
				</Card>
			</Tilt>
		</CardWrapper>
	)
}

export default GearCard