import React, {useEffect, useRef, useState} from 'react';
import CloseModal from "../UI/Buttons/CloseModal";
import modal_top from "../../../assets/Images/mint/modals/Decompose_top.png";
import {Scrollbar} from "react-scrollbars-custom";
import styled from "styled-components";
import {selectError, selectMain, selectSuccess} from "../../../store/features/modalSelectorState";
import {useDispatch, useSelector} from "react-redux";
import MiniNFT from "../UI/Elements/MiniNFT";
import {motion} from "framer-motion";
import {CopyLinkSVG, socketServer, TonkeeperSVG} from "../../../data/data";
import QRCodeStyling from "qr-code-styling";
import ButtonStyle2 from "../UI/Buttons/ButtonStyle2";
import {unselectAll} from "../../../store/features/Mint/gearSelectorState";
import Update from "../../functions/updateInventory";
import useWebSocket from "react-use-websocket";
import api_mint from "../../../api/Mint";
import pendingJson from "../../../assets/lottie/Pending.json";
import Lottie from "lottie-react";
import mintLoader from "../../../assets/lottie/guys_mint_loader.json";
import errorHandler from "../../../utils/errors";

const isMobile = window.innerWidth <= 800

const itemVariants = {
    initial: { opacity: 0, height: 0 },
    open: { opacity: 1, height: 'auto'},
    closed: { opacity: 0, height: 0, scale: 0.2, display: 'none' }
};

const Decompose = () => {
    const dispatch = useDispatch()
    const DeLabConnector = useSelector((state) => state.authSelector.connector)
    const [openQR, setOpenQR] = useState(true);

    const [options, setOptions] = useState({
        width: 230,
        height: 230,
        type: 'canvas',
        data: '',
        //image: '/favicon.ico',
        qrOptions: {
            typeNumber: 0,
            mode: 'Byte',
            errorCorrectionLevel: 'Q'
        },
        dotsOptions: {
            color: '#000',
            type: 'rounded'
        },
        backgroundOptions: {
            color: 'white',
        },
        cornersSquareOptions: {
            color: '#222222',
            type: 'extra-rounded',
        },
        cornersDotOptions: {
            color: '#222222',
            type: 'dot',
        }
    });
    const [qrCode, setQRCode] = useState(new QRCodeStyling(options));
    const ref = useRef(null);
    const [link, setLink] = useState('');

    const selectedGear = useSelector((state) => state.gearMintSelector)
    const [item, setItem] = useState(null);
    const [undressStarted, setUndressStarted] = useState(false);

    const didUnmount = useRef(false);
    const [socketUrl, setSocketUrl] = useState(null);
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl,{
        shouldReconnect: (closeEvent) => {
            /*
            useWebSocket will handle unmounting for you, but this is an example of a
            case in which you would not want it to automatically reconnect
          */
            return didUnmount.current === false;
        },
        reconnectAttempts: 10,
        reconnectInterval: 3000,
    });


    useEffect(() => {
        return () => {
            didUnmount.current = true;
        };
    }, []);


    useEffect(() => {
        if(!selectedGear['character'].id){
            dispatch(selectError("Не выбран character"))
            return false
        }
        setItem(selectedGear['character'])
        const formData = new URLSearchParams()
        formData.append('nft_address', selectedGear['character'].item.address)
        formData.append('user_ton_wallet', DeLabConnector.address)


        api_mint.undressUpRequest(formData).then(data=>{
            if (data.code) {
                errorHandler(data, dispatch)
                return
            }
            if(data.success){
                dispatch(unselectAll())
                document.dispatchEvent(new CustomEvent('gearSelect', {detail: {category: 'all', image: null}}))
                Update.updateInventory(DeLabConnector.address, dispatch, false)

                setSocketUrl(socketServer + `${data.websocket_url}`)

                const trans = {
                    to: data.required_transaction.wallet,
                    value: String(Number(data.required_transaction.amount) * 1000000000), // string value in nano-coins
                    text: data.required_transaction.comment
                }
                DeLabConnector.sendTransaction(trans).then(data => {
                    setLink(data)
                })
            }
        })
            .catch(data=>{
                dispatch(selectError("Bad request"))
                return false
            })

        if (ref.current) {
            qrCode.append(ref.current);
        }
    }, []);

    useEffect(()=>{
        qrCode.update({
            data: link
        })
    }, [link])

    useEffect(()=> {

        if (lastMessage !== null) {
            let dataJson = JSON.parse(lastMessage.data)
            console.log(dataJson)
            switch (dataJson.type){
                case "undress_started":
                    setUndressStarted(true)
                    setOpenQR(false)
                    break;
                case "undress_request_success":
                    dispatch(unselectAll())
                    document.dispatchEvent(new CustomEvent('gearSelect', {detail: {category: 'all', image: null}}))
                    Update.updateInventory(DeLabConnector.address, dispatch, false)
                    dispatch(selectSuccess({title: "mintpage.success.nft_decompose", description: "mintpage.success.nft_decompose_description"}))
                    break;
                case 'undress_request_error':
                    dispatch(unselectAll())
                    document.dispatchEvent(new CustomEvent('gearSelect', {detail: {category: 'all', image: null}}))
                    Update.updateInventory(DeLabConnector.address, dispatch, false)
                    if(dataJson.args.reason === 'expired')
                        dispatch(selectError("Time out"))
                    else
                        errorHandler(dataJson.args, dispatch)
                    break;
                //dispatch(selectError("Не предвиденная ошибка"))
            }
        }
    },[lastMessage])

    function closeModal(){
        dispatch(selectMain())
    }
    //CloseModal onClick={closeModal}/>
    return (
        <Overlay>
            <Scrollbar noScrollX={true} >
                <Window>
                    <Content>
                        <MainBlock>
                            {
                                !undressStarted
                                    ?
                                    <Head>
                                        <h1>Разобрать NFT </h1>
                                        <span>Подтвердите транзакцию. Проверка транзакции займет еще некоторое время</span>
                                    </Head>
                                    :
                                    <Head>
                                        <h1>Разбираем NFT </h1>
                                        <span>Мы разбираем вашу NFT,  все вещи будут доступны в минточной.<span className={'white'}>Это займет 10 мин</span></span>
                                    </Head>
                            }
                            <Body>
                                {
                                    item &&
                                    <Flex>

                                        <MiniNFT
                                            img={item.item.actual_image_url}
                                            title={item.item.metadata.name}
                                            description={'CHARACTER'}
                                            maxwidth={true}
                                        />
                                        {
                                            undressStarted
                                                ?
                                                <Image>
                                                    <Lottie animationData={pendingJson} loop={true} />
                                                </Image>
                                                :
                                                <Image>
                                                    <Lottie animationData={mintLoader} loop={true} />
                                                </Image>
                                        }
                                    </Flex>
                                }

                            </Body>

                            {
                                (!isMobile)
                                    ?
                                <QRflex
                                    as={motion.div}
                                    variants={itemVariants}
                                    initial="initial"
                                    animate={(openQR && link !== '') ? "open" : "closed"}
                                >
                                    <OperationBlock>
                                        <h1>Отсканируйте QR-код в Tonkeeper </h1>
                                        <QRBlock ref={ref} />
                                        <LinkBlock
                                            onClick={()=>{
                                                navigator.clipboard.writeText(link)
                                                document.dispatchEvent(new CustomEvent('tooltipCopy'))
                                            }}
                                        >
                                            {CopyLinkSVG}
                                            <span>Скопировать адрес</span>
                                        </LinkBlock>
                                    </OperationBlock>
                                </QRflex>
                                    :
                                    <Links
                                        as={motion.a}
                                        variants={itemVariants}
                                        initial="initial"
                                        animate={(openQR && link !== '') ? "open" : "closed"}
                                        href={link}
                                        target="_blank"
                                    >
                                        <ButtonStyle2 img={TonkeeperSVG} text={"Подтвердить с Tonkeeper"}/>
                                    </Links>
                            }

                        </MainBlock>
                    </Content>
                </Window>
            </Scrollbar>
        </Overlay>
    );
};

export default Decompose;

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0, .5);
  z-index: 9999;

  @media (max-width: 800px) {
    background-color: #161616;
  }

`

const Window = styled.div`
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  gap: 40px;

  position: relative;
  width: 482px;
  left: 0;
  right: 0;
  margin: 120px auto 100px;
  
  background: #161616;
  border-radius: 22px;

  @media (max-width: 800px) {
    padding: 40px 20px;
    gap: 30px;
    margin: 0px 0px;
    position: relative;
    min-width: 100%;
    width: 100%;
    height: calc(100vh - calc(100vh - 100%));
    left: 0;
    top: 0;
    border-radius: 0px;
  }
`


const Content = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;

  width: 402px;

  @media (max-width: 800px) {
    width: 320px;
  }
`

const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 30px;
  width: 100%;
  
`

const Head = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 20px;

  img{
    width: 100%;
  }

  
  h1{
    font-family: 'Roboto';
    font-style: normal;
    font-size: 28px;
    line-height: 33px;
    line-height: 150%;
    /* identical to box height */

    text-align: center;

    color: #FFFFFF;

    @media (max-width: 800px) {
      font-size: 20px;
      line-height: 150%; 
    }
  }
  span{
    font-family: 'Roboto';
    font-style: normal;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */
    text-align: center;
    color: rgba(255, 255, 255, .5);

    @media (max-width: 800px) {
      font-size: 16px;
    }
  }
  span.white{
    color: rgb(255, 255, 255);
  }
`

const Body = styled.div`
    box-sizing: border-box;

    /* Auto layout */
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    gap: 10px;
    width: 100%;
    
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    `

const QRflex = styled.div`
    box-sizing: border-box;

    /* Auto layout */
    
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    
    width: 100%;
    
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 16px;

    
    h1{
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 130%;
        /* or 19px */
    
        display: flex;
        align-items: center;
        text-align: center;
    
        color: #FFFFFF;
  }
    `

const OperationBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 20px;

  width: 230px;
`

const QRBlock = styled.div`
    width: 230px;
    height: 230px;
    border-radius: 12px;
  
    svg, canvas{
      border-radius: 12px;
    }
  `

const LinkBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 5px;

  width: 100%;
  
  span{
    font-family: 'Roboto';
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;

    color: #207AF0;
  }

  :hover{
    svg{
      opacity: .7;
    }
    span{
      color: rgba(32, 122, 240, .7);
    }
  }
`

const Links = styled.a`
    width: 100%;
    text-decoration: none;
`

const Flex = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    `
const Image = styled.div`
  width: 30px;
  height: 30px;
  svg{
    width: 30px;
    height: 30px;
  }`