import React, {useEffect, useLayoutEffect, useRef} from 'react'
import styled from 'styled-components'
import {gsap, ScrollTrigger, TextPlugin} from "gsap/all";
import { browserName, CustomView } from 'react-device-detect';
import {useTranslation} from "react-i18next";

import imgBg from '../assets/Images/collection/ufo/ufo_collection_bg-min.jpg'
import imgB from '../assets/Images/game/game_b.svg'
import imgCat from '../assets/Images/game/cat-min.png'
import imgUfo from '../assets/Images/game/ufo-min.png'
import imgThunderGreen from '../assets/Images/game/thunder-green.svg'
import imgThunderPink from '../assets/Images/game/thunder-pink.svg'

let ww = window.innerWidth

const Section = styled.section`
	height: 400vh;
	width: 100vw;
	margin-top: -50vh;
	opacity: 0;
	overflow: hidden;
	
	@media (max-width: 800px) {
		height: auto;
		margin-top: 0;
		opacity: 1;
	}
`

const Container = styled.div`
	height: 100vh;
	width: 100vw;
	padding: 10vh 0 0;	
	background: url(${imgBg});
	background-size: cover;
	background-position: top center;
	
	@media (max-width: 800px) {
		height: auto;
		padding: 45px 0 0;
	}
`

const Inner = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
`

const Top = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	height: 15vh;
	margin-bottom: -10vh;
	
	.center {
		position: relative;
		padding: 0 50px;
		width: fit-content;
		text-align: center;
		
		h1 {
			font-family: 'Bebas Neue', sans-serif;
			font-weight: 700;
			font-size: 180px;
			line-height: 94%;
			text-transform: uppercase;
		}
		.description{
			font-weight: 500;
			font-size: 28px;
			line-height: 48px;
		}
	}
	
	@media (max-width: 800px) {
		height: auto;
		margin-bottom: -10%;
		.center {
			padding: 0;
			h1 {
				font-size: 94px;
				line-height: 94%;
			}
			.description{
				font-size: 24px;
				line-height: 32px;
			}
		}
	}
`

const Center = styled.div`
	display: flex;
	justify-content: space-between;
	position: relative;
	flex: 1;
	height: 100%;
	
	span {
		width: 50%;
		max-width: 50vmin;
		display: flex;
		align-items: flex-end;
		justify-content: center;
	}
	
	img {
		display: block;
		max-width: 100%;
	}
	.left {
		padding-left: 62px;
	}
	.right {
		padding-right: 62px;
	}
	
	@media (max-width: 800px) {
		.left, .right {
			padding: 0;
			
			img {
				width: 130%;
				max-width: none;
			}
		}
		
		.left {
			img {
				margin-left: -30%;
			}
		}
		.right {
			img {
				margin-right: -30%;
			}
		}
		
	}
`

const Bottom = styled.div`
	display: flex;
	position: relative;
	
	@media (max-width: 800px) {
		flex-direction: column;
	}
`

const Life = styled.div`
	background: rgba(255, 255, 255, 0.08);
	border: 1px solid rgba(255, 255, 255, 0.5);
	border-radius: 2px;
	height: 31px;
	padding: 3px 8px;
	position: absolute;
	top: 50%;
	margin-top: -15px;
	left: ${props => props.left ? 'auto' : '100%'};
	right: ${props => props.left ? '100%' : 'auto'};
	width: calc(45vw - 300px);
	
	@media (max-width: 800px) {
		display: none;
	}
`
const LifeBar = styled.div`
	position: absolute;
	background: linear-gradient(${props => props.left ? '90deg, #EF2ADC 0%, #E0B70F 100%' : '270deg, #EF2ADC 0%, #E0B70F 100%'});
	border-radius: 2px;
	bottom: 3px;
	top: 3px;
	padding: 3px 5px;
	left: ${props => props.left ? '3px' : 'auto'};
	right: ${props => props.left ? 'auto' : '3px'};
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	text-transform: uppercase;
	text-align: ${props => props.left ? 'left' : 'right'};
	width: ${props => props.left ? '80' : '90'}%;
`

const Item = styled.div`
	width: 50%;
	display: flex;
	flex-direction: column;
	
	img {
		display: block;
	}
	
	.bottom {
		position: relative;
		height: 100%;
		background-color: ${props => props.left ? props.theme.colors.pink : props.theme.colors.green};
		font-weight: 400;
		font-size: 2.4vmin;
		line-height: 127.19%;
		text-align: center;
		padding: 4vh 3vw 160px;
		z-index: 3;
		
		span {
			display: block;
			max-width: 516px;
			margin: 0 auto;
		}
	}
	
	.game-b {
		text-align: left;
		max-width: 400px;
		position: absolute;
		bottom: 40px;
		left: 40px;
		align-items: center;
		opacity: 0.4;
		color: #000;
		font-size: 20px;
		line-height: 127.19%;
		z-index: 4;
		display: ${props => props.left ? 'flex' : 'none'};
		img {
			margin-right: 13px;
		}
	}
	
	@media (max-width: 800px) {
		width: 100%;
		
		.bottom {
			display: flex;
			align-items: center;
			justify-content: ${props => props.left ? 'center' : 'space-between'};
			flex-direction: column;
			min-height: 337px;
			padding: 40px 20px;
			font-size: 24px;
			line-height: 127.19%;
		}
		
		.game-b {
			display: ${props => props.left ? 'none': 'flex'};
			max-width: 400px;
			position: static;
			margin-top: 28px;
			left: 40px;
			font-size: 16px;
			line-height: 127.19%;
			align-self: flex-start;
		}
		
	}
`

const Thunder = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 9vw;
	
	img {
		max-width: 100%;
		position: relative;
		z-index: 2;
		transform-origin: left center;
		+ img {
			position: absolute;
			z-index: 1;
			left:-8%;
			top: 0;
		}
	}
	
	@media (max-width: 800px) {
		width: 85px;
	}
`
const Game = (props) => {
	gsap.registerPlugin(ScrollTrigger, TextPlugin);
	const { t } = useTranslation();

	const sectionRef = useRef(null);
	const containerRef = useRef(null);
	const topRef = useRef(null);
	const healthLeftRef = useRef(null);
	const healthLeftTextRef = useRef(null);
	const healthRightRef = useRef(null);
	const healthRightTextRef = useRef(null);
	const bottomLeftImgRef = useRef(null);
	const bottomRightImgRef = useRef(null);
	const bottomLeftTextRef = useRef(null);
	const bottomRightTextRef = useRef(null);
	const thunderRef = useRef(null);
	const thunderPinkRef = useRef(null);
	const thunderGreenRef = useRef(null);
	const textLeftRef = useRef(null);
	const textRightRef = useRef(null);

	useEffect(() => {
		setTimeout(() => {
			props.onReady('game')
		}, 300)
	}, []);

	useLayoutEffect(() => {
		let element = sectionRef.current;
		let t1 = gsap.timeline()

		setTimeout(() => {

			if(ww > 800) {
				t1.to(element, {
					scrollTrigger: {
						trigger: element,
						start: 'top top',
						end: '300 top',
						scroller: '.App',
						scrub: true
					},
					opacity:1,
					zIndex: 2,
					ease: 'none'
				})
				t1.from(topRef.current, {
					scrollTrigger: {
						trigger: element,
						start: 'top top',
						end: '15% top',
						scroller: '.App',
						scrub: true
					},
					opacity:0,
					y: -200,
					ease: 'none'
				})
				t1.from(healthLeftRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '25% top',
						end: '35% top',
						scroller: '.App',
						scrub: true
					},
					opacity:0,
					x: -200,
					ease: 'none'
				})
				t1.from(healthRightRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '25% top',
						end: '35% top',
						scroller: '.App',
						scrub: true
					},
					opacity:0,
					x: 200,
					ease: 'none'
				})
				t1.from(healthLeftTextRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '40% top',
						end: '55% top',
						scroller: '.App',
						scrub: true
					},
					opacity:0,
					width: '100%',
					ease: 'none'
				})
				t1.from(healthRightTextRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '35% top',
						end: '45% top',
						scroller: '.App',
						scrub: true
					},
					opacity:0,
					width: '100%',
					ease: 'none'
				})

				t1.to(containerRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '90% bottom',
						end: 'bottom bottom',
						scroller: '.App',
						scrub: true,
					},
					opacity:0,
					zIndex: -1,
					ease: 'none'
				})

				t1.from([bottomLeftImgRef.current, bottomRightImgRef.current], {
					scrollTrigger: {
						trigger: element,
						start: '25% top',
						end: '35% top',
						scroller: '.App',
						scrub: true
					},
					opacity:0,
					yPercent: 100,
					ease: 'none'
				})
				t1.from([bottomLeftTextRef.current, bottomRightTextRef.current], {
					scrollTrigger: {
						trigger: element,
						start: '20% top',
						end: '35% top',
						scroller: '.App',
						scrub: true
					},
					opacity:.9,
					yPercent: 100,
					ease: 'none'
				})
				t1.from([textLeftRef.current, textRightRef.current], {
					scrollTrigger: {
						trigger: element,
						start: '30% top',
						end: '40% top',
						scroller: '.App',
						scrub: true
					},
					scale: 0.3,
					opacity: 0,
					ease: 'back.out(1.7)'
				})
				t1.from(thunderRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '30% top',
						end: '45% top',
						scroller: '.App',
						scrub: true
					},
					scale: 0.3,
					y: -500,
					opacity: 0,
					ease: 'back.out(1.7)'
				})
				t1.from(thunderGreenRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '40% top',
						end: '55% top',
						scroller: '.App',
						scrub: true
					},
					rotateY: -30,
					ease: 'back.out(1.7)'
				})
				t1.from(thunderPinkRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '40% top',
						end: '55% top',
						scroller: '.App',
						scrub: true
					},
					left: 0,
					opacity: 0,
					ease: 'back.out(1.7)'
				})


				t1.to(element, {
					scrollTrigger: {
						trigger: element,
						start: 'top top',
						end: 'bottom bottom',
						scroller: '.App',
						pin: containerRef.current,
					},
					ease: 'none'
				})
			}


			ScrollTrigger.refresh()
		}, 1000)


	}, []);

	return (
		<Section id="game" ref={sectionRef}>
			<Container ref={containerRef}>
				<Inner>
					<Top ref={topRef}>
						<div className="center">
							{ww > 800 &&
							<Life left ref={healthLeftRef}>
								<LifeBar ref={healthLeftTextRef} left>
									{t('cat')}
								</LifeBar>
							</Life>
							}
							<h1>{t('game.title')}</h1>
							<div className="description">{t('game.description')}</div>
							{ww > 800 &&
							<Life ref={healthRightRef}>
								<LifeBar ref={healthRightTextRef}>
									{t('ufo')}
								</LifeBar>
							</Life>
							}
						</div>
					</Top>
					<Center>
						<span className="left" ref={bottomLeftImgRef}><img src={imgCat} alt=""/></span>
						<span className="right" ref={bottomRightImgRef}><img src={imgUfo} alt=""/></span>
						<Thunder ref={thunderRef}>
							<img src={imgThunderGreen} ref={thunderGreenRef} alt=""/>
							<img src={imgThunderPink} ref={thunderPinkRef} alt=""/>
						</Thunder>
					</Center>
					<Bottom>
						<Item left>
							<div className="bottom" ref={bottomLeftTextRef}>
								<span ref={textLeftRef}>
									{t('game.leftText')}
								</span>
								<div className="game-b">
									<img src={imgB} alt=""/>
									{t('game.inDevelopment')}
								</div>
							</div>
						</Item>
						<Item>
							<div className="bottom" ref={bottomRightTextRef}>
								<span ref={textRightRef}>
									{t('game.rightText')}
								</span>
								<div className="game-b">
									<img src={imgB} alt=""/>
									{t('game.inDevelopment')}
								</div>
							</div>
						</Item>
					</Bottom>
				</Inner>
			</Container>
		</Section>
	)
}

export default Game