import { createSlice } from '@reduxjs/toolkit'

export const gearSelectorSlice = createSlice({
	name: 'gearSelector',
	initialState: {
		shirt: {id: null, image: null, item: null, loading: false},
		jacket: {id: null, image: null, item: null, loading: false},
		head: {id: null, image: null, item: null, loading: false},
		glasses: {id: null, image: null, item: null, loading: false},
		jewellery: {id: null, image: null, item: null, loading: false},
		background: {id: null, image: null, item: null, loading: false},
		special: {id: null, image: null, item: null,loading: false},
		body: {id: null, image: null, item: null, loading: false},
		character: {id: null, image: null, item: null, loading: false}
	},
	reducers: {
		selectTShirt: (state, action) => {
			state.shirt = action.payload
		},
		selectJacket: (state, action) => {
			state.jacket = action.payload
		},
		selectCap: (state, action) => {
			state.head = action.payload
		},
		selectGlass: (state, action) => {
			state.glasses = action.payload
		},
		selectDecor: (state, action) => {
			state.jewellery = action.payload
		},
		selectBackground: (state, action) => {
			state.background = action.payload
		},
		selectBonus: (state, action) => {
			state.special = action.payload
		},
		selectBody: (state, action) => {
			state.body = action.payload
		},
		selectCharacter: (state, action) => {
			state.character = action.payload
		},
		selectByCategory: (state, action) => {
			const {gear, id, image, item} = action.payload
			//console.log("Селект бай категори")
			//console.log(action.payload)
			state[gear] = {
				id: id,
				image: image,
				item: item
			}
		},
		selectSetLoadingByCategory: (state, action) => {
			const {gear, loading} = action.payload
			state[gear].loading = loading
		},
		unselectByCategory: (state, action) => {
			state[action.payload] = {id: null, image: null, item: null, loading: false}
		},
		unselectAll: (state) => {
			state.shirt = {id: null, image: null, item: null, loading: false}
			state.jacket = {id: null, image: null, item: null,loading: false}
			state.head = {id: null, image: null, item: null, loading: false}
			state.glasses = {id: null, image: null, item: null, loading: false}
			state.jewellery = {id: null, image: null, item: null, loading: false}
			state.background = {id: null, image: null, item: null, loading: false}
			state.special = {id: null, image: null, item: null, loading: false}
			state.body = {id: null, image: null, item: null, loading: false}
			state.character = {id: null, image: null, item: null, loading: false}
		}
	},
})

// Action creators are generated for each case reducer function
export const {
	selectTShirt,
	selectJacket,
	selectCap,
	selectGlass,
	selectDecor,
	selectBackground,
	selectBonus,
	selectByCategory,
	selectSetLoadingByCategory,
	unselectByCategory,
	unselectAll
} = gearSelectorSlice.actions

export default gearSelectorSlice.reducer