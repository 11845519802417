import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Scrollbar} from "react-scrollbars-custom";
import QRCodeStyling from "qr-code-styling";
import {CopyLinkSVG, socketServer, TonkeeperSVG} from "../../../data/data";
import {useDispatch, useSelector} from "react-redux";
import api from "../../../api/Mint"
import RemintInfoList from "../List/RemintInfoList";
import useWebSocket, {ReadyState} from "react-use-websocket";
import {unselectAll} from "../../../store/features/Mint/gearSelectorState";
import Update from "../../functions/updateInventory";
import {selectError, selectSuccess} from "../../../store/features/modalSelectorState";
import {motion} from "framer-motion";
import ButtonStyle2 from "../UI/Buttons/ButtonStyle2";
import errorHandler from "../../../utils/errors";

const isMobile = window.innerWidth <= 800

const itemVariants = {
    initial: { opacity: 0, height: 0 },
    open: { opacity: 1, height: 'auto'},
    closed: { opacity: 0, height: 0, scale: 0.2, display: 'none' }
};

const RemintStart = () => {
    const selectedDressUpRequestId = useSelector((state) => state.remintSelector.dress_up_request_id)
    const DeLabConnector = useSelector((state) => state.authSelector.connector)
    const dispatch = useDispatch()
    const [openQR, setOpenQR] = useState(true);

    const [options, setOptions] = useState({
        width: 230,
        height: 230,
        type: 'canvas',
        data: '',
        //image: '/favicon.ico',
        qrOptions: {
            typeNumber: 0,
            mode: 'Byte',
            errorCorrectionLevel: 'Q'
        },
        dotsOptions: {
            color: '#000',
            type: 'rounded'
        },
        backgroundOptions: {
            color: 'white',
        },
        cornersSquareOptions: {
            color: '#222222',
            type: 'extra-rounded',
        },
        cornersDotOptions: {
            color: '#222222',
            type: 'dot',
        }
    });
    const [qrCode, setQRCode] = useState(new QRCodeStyling(options));
    const ref = useRef(null);
    const [link, setLink] = useState('');
    const [wallet, setWallet] = useState('');
    const [items, setItems] = useState(null);

    const didUnmount = useRef(false);
    const [socketUrl, setSocketUrl] = useState(null);
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl,{
        shouldReconnect: (closeEvent) => {
            /*
            useWebSocket will handle unmounting for you, but this is an example of a
            case in which you would not want it to automatically reconnect
          */
            return didUnmount.current === false;
        },
        reconnectAttempts: 10,
        reconnectInterval: 3000,
    });

    useEffect(() => {
        return () => {
            didUnmount.current = true;
        };
    }, []);

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    useEffect(()=> {

        if (lastMessage !== null) {
            let dataJson = JSON.parse(lastMessage.data)
            console.log(dataJson)
            switch (dataJson.type){
                case "remint_started":
                    setOpenQR(false)
                    let newItems = {}
                    Object.keys(items).map((el, index)=>{
                        newItems[el] = items[el]
                        newItems[el].status = "processing"
                    })
                    console.log(newItems)
                    setItems({...newItems})
                    break;
                case "nft_reminted":
                    Object.keys(items).map((el, index)=>{
                        if(el === dataJson.args.old_address){
                            items[el].status = "success"
                        }
                    })
                    setItems({...items})
                    dispatch(unselectAll())
                    document.dispatchEvent(new CustomEvent('gearSelect', {detail: {category: 'all', image: null}}))
                    Update.updateInventory(DeLabConnector.address, dispatch, false)
                    break;
                case "remint_request_success":
                    dispatch(unselectAll())
                    document.dispatchEvent(new CustomEvent('gearSelect', {detail: {category: 'all', image: null}}))
                    Update.updateInventory(DeLabConnector.address, dispatch, false)
                    dispatch(selectSuccess({title: "mintpage.success.nft_reminted", description: "mintpage.success.nft_reminted_description"}))
                    break;
                case "remint_request_error":
                    dispatch(unselectAll())
                    document.dispatchEvent(new CustomEvent('gearSelect', {detail: {category: 'all', image: null}}))
                    Update.updateInventory(DeLabConnector.address, dispatch, false)
                    if(dataJson.args.reason === 'expired')
                        dispatch(selectError("Time out"))
                    else
                        errorHandler(dataJson.args, dispatch)
                    break;
                default:
                    console.log("Неизвестный type")
                //dispatch(selectError("Не предвиденная ошибка"))
            }
        }
    },[lastMessage])

    useEffect(() => {
        if (ref.current) {
            qrCode.append(ref.current);
        }
    }, []);
    useEffect(() => {
        if(DeLabConnector) {
            const formData = new URLSearchParams()
            formData.append('dress_up_request_id', selectedDressUpRequestId)
            api.remintRequest(formData)
                .then(data => {
                    if (data.code) {
                        //Если у нас есть код ошибки, то обрабатываем и выходим
                        errorHandler(data, dispatch)
                        return
                    }
                    if(data.success) {
                        setWallet(data.required_transaction.wallet)

                        let newItems = {}
                        data.nfts_to_remint
                        Object.keys(data.nfts_to_remint).map((el, index)=>{
                            newItems[el] = data.nfts_to_remint[el]
                            newItems[el].status = "wait"
                        })
                        setItems(newItems)
                        setSocketUrl(socketServer + `${data.websocket_url}`)
                        const trans = {
                            to: data.required_transaction.wallet,
                            value: String(Number(data.required_transaction.amount) * 1000000000), // string value in nano-coins
                            text: data.required_transaction.comment
                        }
                        DeLabConnector.sendTransaction(trans).then(data => {
                            setLink(data)
                        })
                    }
                })
        }
    }, [DeLabConnector]);

    useEffect(()=>{
        qrCode.update({
            data: link
        })
    }, [link])
    
    return (
        <Overlay>
            <Scrollbar noScrollX={true}
                       thumbYProps={{style: {background: 'rgba(255, 255, 255, 0.3)'}}}
            >
                <Window>
                    <Content>
                        <MainBlock>
                            {openQR
                                ?
                                <Head>
                                    <h1>Восстановить вещи</h1>
                                    <span>Подтвердите транзакцию в вашем кошельке.
После подтверждения проверка обычно занимает некоторое время</span>
                                </Head>
                                :
                                <Head>
                                    <h1>Начали восстановление</h1>
                                    <span>Мы восстанавливаем ваши вещи, они будут доступны в минточной. Как правило, это операция занимает до <span className={'white'}>пяти минут</span></span>
                                </Head>
                            }
                            {
                                !isMobile &&
                                <QRflex
                                    as={motion.div}
                                    variants={itemVariants}
                                    initial="initial"
                                    animate={(openQR) ? "open" : "closed"}
                                >
                                    <OperationBlock>
                                        <h1>Отсканируйте QR-код в Tonkeeper </h1>
                                        <QRBlock ref={ref} />
                                        <LinkBlock
                                            onClick={()=>{
                                                navigator.clipboard.writeText(wallet)
                                                document.dispatchEvent(new CustomEvent('tooltipCopy'))
                                            }}
                                        >
                                            {CopyLinkSVG}
                                            <span>Скопировать адрес</span>
                                        </LinkBlock>
                                    </OperationBlock>
                                </QRflex>
                            }

                            <Body>
                                { items &&
                                    <RemintInfoList list={items}/>
                                }
                            </Body>
                            {
                                isMobile &&
                                <Links
                                    as={motion.a}
                                    variants={itemVariants}
                                    initial="initial"
                                    animate={(openQR) ? "open" : "closed"}
                                    href={link}
                                    target="_blank"
                                >
                                    <ButtonStyle2 img={TonkeeperSVG} text={"Подтвердить с Tonkeeper"}/>
                                </Links>
                            }

                        </MainBlock>
                    </Content>
                </Window>
            </Scrollbar>
        </Overlay>
    );
};

export default RemintStart;

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0, .5);
  z-index: 9999;

  @media (max-width: 800px) {
    background-color: #161616;
  }

`

const Window = styled.div`
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  gap: 40px;

  position: relative;
  width: 482px;
  left: 0;
  right: 0;
  margin: 120px auto 100px;
  
  background: #161616;
  border-radius: 22px;

  @media (max-width: 800px) {
    margin: 0px 0px;
    position: relative;
    min-width: 100%;
    width: 100%;
    height: calc(100vh - calc(100vh - 100%));
    left: 0;
    top: 0;
    border-radius: 0px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;

  width: 402px;

  @media (max-width: 800px) {
    width: 320px;
  }
`

const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 30px;
  width: 100%;
`

const Head = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 20px;

  
  h1{
    font-family: 'Roboto';
    font-style: normal;
    font-size: 28px;
    line-height: 33px;
    line-height: 150%;
    /* identical to box height */

    text-align: center;

    color: #FFFFFF;

    @media (max-width: 800px) {
      font-size: 20px;
      line-height: 150%; 
    }
  }
  span{
    font-family: 'Roboto';
    font-style: normal;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */
    text-align: center;
    color: rgba(255, 255, 255, .5);

    @media (max-width: 800px) {
      font-size: 16px;
    }
  }
  span.white{
    color: rgb(255, 255, 255);
  }
`
const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 100%;
`

const QRflex = styled.div`
    box-sizing: border-box;

    /* Auto layout */
    
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    
    width: 100%;
    
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 16px;

    
    h1{
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 130%;
        /* or 19px */
    
        display: flex;
        align-items: center;
        text-align: center;
    
        color: #FFFFFF;
  }
    `

const OperationBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 20px;

  width: 230px;
`

const QRBlock = styled.div`
    width: 230px;
    height: 230px;
    border-radius: 12px;
  
    svg, canvas{
      border-radius: 12px;
    }
  `
const LinkBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 5px;
  
  
  span{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;

    color: #207AF0;
  }
  
  :hover{
    svg{
      opacity: .7;
    }
    span{
      color: rgba(32, 122, 240, .7);
    }
  }
`

const Links = styled.a`
    width: 100%;
    text-decoration: none;
`