import React, {useEffect, useRef, useState} from 'react';
import styled, {css} from "styled-components";
import RemintInfoList from "../List/RemintInfoList";
import {Scrollbar} from "react-scrollbars-custom";
import {PreMintPNG} from "../../../data/data";
import ButtonStyle1 from "../UI/Buttons/ButtonStyle1";
import {useDispatch} from "react-redux";
import {selectMain, selectMint} from "../../../store/features/modalSelectorState";

const isMobile = window.innerWidth <= 800
const PreMint = () => {
    const dispatch = useDispatch()
    const refOverlay = useRef(null);
    const [height, setHeight] = useState(false);

    useEffect(() => {
        setHeight(refOverlay.current.clientHeight)
    }, []);

    function handleClickBack(){
        dispatch(selectMain())
    }
    function handleClickNext(){
        dispatch(selectMint())
    }

    return (
        <Overlay ref={refOverlay}>
            <Scrollbar noScrollX={true}
                       thumbYProps={{style: {background: 'rgba(255, 255, 255, 0.3)'}}}
            >
                <Window height={height}>
                    <Image src={PreMintPNG}/>
                    <Content>
                        <MainBlock>
                            <Head>
                                <h1>Заминтить</h1>
                                <span>NFT body изменится на готового персонажа, а вещи сожгутся. Вы всегда можете разделить NFT в дальнейшем. При старте минта выбранные вами вещи будут заблокированы <span className={'white'}>в течение 1 часа</span></span>
                            </Head>
                            {
                                !isMobile &&
                                <Body>
                                    <ButtonStyle1 onClick={handleClickBack} text={"Назад"}/>
                                    <ButtonStyle1 onClick={handleClickNext} primary text={"Начать минт"}/>
                                </Body>
                            }
                        </MainBlock>
                    </Content>
                    {
                        isMobile &&
                        <Body>
                            <ButtonStyle1 onClick={handleClickBack} text={"Назад"}/>
                            <ButtonStyle1 onClick={handleClickNext} primary text={"Начать минт"}/>
                        </Body>
                    }
                </Window>
            </Scrollbar>
        </Overlay>
    );
};

export default PreMint;

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0, .5);
  z-index: 9999;

  @media (max-width: 800px) {
    background-color: #161616;
  }

`

const Window = styled.div`
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  gap: 40px;

  position: relative;
  width: 482px;
  left: 0;
  right: 0;
  margin: 120px auto 100px;
  
  background: #161616;
  border-radius: 22px;

  @media (max-width: 800px) {
    margin: 0px 0px;
    position: relative;
    min-width: 100%;
    width: 100%;
    
    ${props=>props.height && css`
      height: calc(100vh - calc(100vh - ${props.height}px));
    `}
    left: 0;
    top: 0;
    border-radius: 0px;
    justify-content: space-between;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  padding-top: 200px;
  gap: 40px;

  width: 402px;

  @media (max-width: 800px) {
    width: 320px;
  }
`

const Image = styled.img`
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 0;
`

const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 30px;
  width: 100%;
  
`

const Head = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 20px;

  img{
    width: 100%;
  }

  
  h1{
    font-family: 'Roboto';
    font-style: normal;
    font-size: 28px;
    line-height: 33px;
    line-height: 150%;
    /* identical to box height */

    text-align: center;

    color: #FFFFFF;

    @media (max-width: 800px) {
      font-size: 20px;
      line-height: 150%; 
    }
  }
  span{
    font-family: 'Roboto';
    font-style: normal;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */
    text-align: center;
    color: rgba(255, 255, 255, .5);

    @media (max-width: 800px) {
      font-size: 16px;
    }
  }
  span.white{
    color: rgb(255, 255, 255);
  }
`
const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 20px;

  @media (max-width: 800px) {
    width: 320px;
  }
`