import React, {useEffect, useLayoutEffect, useRef} from 'react'
import styled from 'styled-components'
import {gsap, ScrollTrigger} from "gsap/all";
//import { browserName } from 'react-device-detect';
import {useTranslation} from "react-i18next";

import {roadmapData} from "../data/data";

import checkSvg from "../assets/Svgs/check.svg";
import timerSvg from "../assets/Svgs/timer.svg";

let ww = window.innerWidth

const Section = styled.section`
	min-height: 100vh;
	width: 100vw;
	opacity: 0;
	overflow: hidden;
	padding-bottom: 100px;
	@media (max-width: 800px) {
		opacity: 1;
	}
	
`
const Title = styled.div`
	font-family: 'Bebas Neue', sans-serif;
	font-size:200px;
	line-height: 1;
	position: absolute;
	left: 40px;
	right: 40px;
	top: 114px;
	text-align: center;
	
	@media (max-width: 800px) {
		font-size: 78px;
		line-height: 94%;
		position: static;
	}
	
`
const Container = styled.div`
	min-height: 100vh;
	width: 100vw;
	position: relative;
	@media (max-width: 800px) {
		padding: 45px 20px;
	}
`

const Inner = styled.div`
	position: relative;
	padding-top:300px;
	max-width: 774px;
	margin: 0 auto;
	width: 100%;
	@media (max-width: 800px) {
		padding: 0;
		margin-top: 30px;
	}
`

const RoadmapWrapper = styled.div`
	position: relative;
	margin-top: 75px;
`

const RoadmapProgress = styled.div`
	position: absolute;
	width: 12px;
	left:10px;
	height: 100%;
	@media (max-width: 800px) {
		left: 8px;
	}
`

const ProgressLine = styled.div`
	position: absolute;
	top: 16px;
	height: calc(100% - 88px);
	width: 100%;
	background-color:#EF2ADC;
	z-index: 1;
	
	@media (max-width: 800px) {
		height: calc(100% - 98px);
	}
`

const ProgressComplete = styled.div`
	position: absolute;
	top: 22px;
	height: ${props => props.percent ? props.percent : 0}%;
	width: 100%;
	background-color:#00ED75;
	z-index: 2;
`

const ProgressFront = styled.div`
	position: absolute;
	background-color: #272727;
	z-index: 3;
	height: 100%;
	width: 100%;
	bottom: 0;
	overflow: hidden;
	&:before {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 80px;
		background-color:#000;
	}
	
	@media (max-width: 800px) {
		height: 0;
		/*&:before {
			height: 84px;
		}*/
	}
`

const Item = styled.div`
	display: block;
	position: relative;
	padding-left: 65px;
	min-height: 96px;
	
	.date {
		font-size: 16px;
		line-height: 32px;
		color: #737373;
		transition: 0.6s 0.2s ease;
	}
	
	.title {
		font-size: 24px;
		line-height: 28px;
		opacity: 0.2;
		transition: 0.6s 0s ease;
	}
	
	.icon {
		background: #4A4A4A;
		position: absolute;
		left: 0;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 32px;
		height: 32px;
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 50%;
		z-index: 5;
		transition: 1.3s 0s ease;
		overflow: hidden;
		
		img {
			position: relative;
			display: block;
			max-width: 100%;
			opacity: 0;
			z-index: 3;
			transition: 0.3s 0s ease;
		}
	}
	
	&.active {
		.title{
			opacity: 1;
		}
		.icon {
		
			&.pink{
				background-color: ${props => props.theme.colors.pink};
			}
			&.green{
				background-color: ${props => props.theme.colors.green};
			}
	
			img {
				opacity: 1;
			}
		}
	}
	
	@media (max-width: 800px) {
		padding-left: 44px;
		min-height: 92px;
		.date {
			font-size: 16px;
			line-height: 28px;
		}
		.title {
			font-size: 16px;
			line-height: 20px;
			opacity: 1;
		}
		.icon {
			width: 28px;
			height: 28px;
			img {
				opacity: 1;
			}
			&:before {
				display: none;
			}
			&.pink{
				background-color: ${props => props.theme.colors.pink};
				img {
					margin-left: -2px;
				}
			}
			&.green{
				background-color: ${props => props.theme.colors.green};
			}
		}
	}
`

const RoadMap = (props) => {
	gsap.registerPlugin(ScrollTrigger);
	const { t } = useTranslation();

	const sectionRef = useRef(null);
	const containerRef = useRef(null);
	const titleRef = useRef(null);

	const roadmapRef = useRef(null);
	const fillRef = useRef(null);
	const itemRef = useRef(null);


	const roadmapVars = {
		totalCount: roadmapData.length,
		completeCount: roadmapData.filter(item => item.complete).length
	}

	useEffect(() => {
		setTimeout(() => {
			props.onReady('roadmap')
		}, 300)
	}, []);

	useLayoutEffect(() => {
		let element = sectionRef.current;
		let t1 = gsap.timeline()

		const roadmapSel = gsap.utils.selector(roadmapRef.current)

		setTimeout(() => {
			if(ww > 800) {

				t1.to(element, {
					scrollTrigger: {
						trigger: element,
						start: 'top bottom',
						end: '20% bottom',
						scroller: '.App',
						scrub: true
					},
					opacity:1,
					zIndex: 2,
					ease: 'none'
				})

				t1.from(titleRef.current, {
					scrollTrigger: {
						trigger: element,
						start: 'top bottom',
						end: '20% bottom',
						scroller: '.App',
						scrub: true,
					},
					top: -400,
					opacity: 0,
					ease: 'none'
				})
				t1.to(fillRef.current,{
					scrollTrigger: {
						trigger: roadmapRef.current,
						start: 'top center',
						end: 'bottom center',
						oggleActions: "play none pause pause",
						scroller: '.App',
						//once: true,
						scrub: true,
					},
					height: 0,
					ease: 'none'
				})

				roadmapSel('.item').map(item => {
					t1.to(item,{
						scrollTrigger: {
							trigger: item,
							start: 'top center',
							end: 'bottom center',
							scroller: '.App',
							scrub: true,
							onEnter: () => {
								item.classList.add('active')
							},
							onLeaveBack: () => {
								item.classList.remove('active')
							}
						},
						ease: 'none'
					})
				})

				t1.to(containerRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '90% top',
						end: 'bottom top',
						scroller: '.App',
						scrub: true,
					},
					opacity:0,
					zIndex: -1,
					ease: 'none'
				})
			}

			ScrollTrigger.refresh()
		}, 1000)

	}, []);

	const roadmapItem = (item, i) => {
		const iconClass = item.complete ? 'green' : 'pink'
		return (
			<Item className="item" ref={itemRef} key={`roadmap-item-${i}`}>
				<div className={`icon ${iconClass}`}>
					<img src={item.complete ? checkSvg : timerSvg} alt=""/>
				</div>
				<div className="date">{t(`roadmap.item${i}.date`)}</div>
				<div className="title">{t(`roadmap.item${i}.title`)}</div>
			</Item>
		)
	}

	const getRoadmapCompletePercent = () => {
		return ((100 / roadmapVars.totalCount) * roadmapVars.completeCount - 1).toFixed()
	}

	return (
		<Section id="roadmap" ref={sectionRef}>
			<Container ref={containerRef}>
				<Title ref={titleRef}>
					Roadmap
				</Title>
				<Inner>
					<RoadmapWrapper ref={roadmapRef}>
						<RoadmapProgress>
							<ProgressLine/>
							<ProgressComplete percent={getRoadmapCompletePercent()}/>
							<ProgressFront ref={fillRef}/>
						</RoadmapProgress>
						{roadmapData.map((item, i) => roadmapItem(item, i))}
					</RoadmapWrapper>
				</Inner>
			</Container>
		</Section>
	)
}

export default RoadMap