// Variables for themes

export const light = {
	colors: {
		background: '#000',
		text: '#fff',
		pink: '#EF2ADC',
		green: '#00ED75',
		blue: '#1E3082',
		textGradient: '90.46deg, #BF22B0 10.27%, #E0B70F 96.57%',
		lineGradient: '90deg, #EF2ADC 0%, #E0B70F 100%',
		gold: '#DBAE00',
		buttonHeight: '40px'
	},
	rgb: {
		pink: '239, 42, 220',
		green: '0, 237, 117',
		blue: '30, 48, 130',
		black: '0, 0, 0'

	}
}