import {configureStore} from '@reduxjs/toolkit'
import toySelectorReducer from './features/toySelectorState'
import gearCountReducer from './features/gearCountState'
import gearSelectorReducer from './features/gearSelectorState'
import gearMintSelectorReducer from './features/Mint/gearSelectorState'
import gearScrollReducer from './features/gearScrollState'
import gearVisibilityReducer from './features/gearVisibilityState'
import gearMintVisibilityReducer from './features/Mint/gearVisibilityState'
import categorySelectorReducer from './features/categorySelectorState'
import modalSelectorReducer from "./features/modalSelectorState"
import categoryMintSelectorReducer from "./features/Mint/categorySelectorState"
import authMintSelectorReducer from "./features/Mint/authSelectorState"
import remintSelectorReducer from "./features/Remint/remintSelectorState"
import continueMintSelectorReducer from "./features/ContinueMint/continueMintSelectorState"

export default configureStore({
	reducer: {
		toySelector: toySelectorReducer,
		gearCount: gearCountReducer,

		gearSelector: gearSelectorReducer,
		gearMintSelector: gearMintSelectorReducer,

		gearScroll: gearScrollReducer,
		gearVisibility: gearVisibilityReducer,
		gearMintVisibility: gearMintVisibilityReducer,

		categorySelector: categorySelectorReducer,
		categoryMintSelector: categoryMintSelectorReducer,
		modalSelector: modalSelectorReducer,
		authSelector: authMintSelectorReducer,

		remintSelector: remintSelectorReducer,
		continueMintSelector: continueMintSelectorReducer,
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
})