import React from 'react';
import styled, {css} from "styled-components";

const Tooltip = (props) => {
    return (
        <Overlay {...props}>
            {
                props.img &&
                <div>{props.img}</div>
            }
            <span>
                {props.text}
            </span>
        </Overlay>
    );
};

export default Tooltip;

const Overlay = styled.div`
    box-sizing: border-box;

    /* Auto layout */
    
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 12px;
    gap: 6px;
  
    height: 33px;
    background: #2D2D2D;
    ${props=>props.classic && css`
      background: #2D2D2D;
    `}
    ${props=>props.black && css`
      background: #161616;
    `}
    
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
  
    span{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      /* identical to box height, or 21px */

      display: flex;
      align-items: center;
      color: #FFFFFF;
      
      ${props=>props.classic && css`
        color: #FFFFFF;
      `}
      ${props=>props.black && css`
        color: rgba(255, 255, 255. .5);
      `}
    }
    svg{
      width: 14px;
      height: 14px;
    }
    `