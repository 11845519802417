import React, {useState} from 'react';
import HistoryItemsList from "./HistoryItemsList";
import styled from "styled-components";

const Day = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;
`

const EventsList = ({list, dayIndex, updateHistory}) => {
    return (
        <Day>
            {list.map((el, index)=>(
                <HistoryItemsList
                    key={index}
                    list={el}
                    first={(index === 0) ? true : false}
                    event={list}
                    eventIndex={index}
                    updateHistory={updateHistory}
                    dayIndex={dayIndex}
                />
            ))}
        </Day>
    );
};

export default EventsList;