import React, {useEffect, useRef} from "react";
import styled from "styled-components";
import {motion} from "framer-motion";
import { MouseParallaxContainer, MouseParallaxChild } from "react-parallax-mouse";

import {gsap} from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger'

import pImg1 from '../assets/Images/collection/p1-min.png'
import pImg2 from '../assets/Images/collection/p2-min.png'
import pImg3 from '../assets/Images/collection/p3-min.png'
import pImg4 from '../assets/Images/collection/p4-min.png'
import pImg5 from '../assets/Images/collection/p5-min.png'
import pImg6 from '../assets/Images/collection/p6-min.png'
import pImg7 from '../assets/Images/collection/p7-min.png'
import pImg8 from '../assets/Images/collection/p8-min.png'
import pImg9 from '../assets/Images/collection/p9-min.png'
import pInstead from '../assets/Images/mobile/parallax_collection_instead-min.png'
import {useTranslation} from "react-i18next";

let ww = window.innerWidth

const Section = styled.section`
	position: relative; 
	
	min-height: 100vh;
	width: 100vw;
	
	background-color: ${props => props.theme.colors.pink};
	color: ${props => props.theme.colors.text};
	
	display: flex;
	justify-content: flex-start;
	align-items: center;	
	
	@media (max-width: 800px) {
		z-index: 3;
	}
`

const Container = styled.div`
	position: relative;
	min-height: 100vh;
	width: 100vw;
	margin: 0 auto;
	padding: 10vh 0;	
	
	display: flex;
	flex-direction: column;
	align-items:flex-start;
	justify-content: center;
	
	z-index: 2;
`
const TextLine = styled.div`
	position: relative;
	padding: 0 5vw; 
	font-size: 17vmin;
	line-height: 0.9;
	text-transform: uppercase;
	font-family: 'Bebas Neue', sans-serif;
	
	z-index: 10;	
	
	pointer-events: none;
	
	span{
		display: block;
	}
	span.green {
		color: ${props => props.theme.colors.green}
	}
	
`

const Parallax = styled.div`
	position: absolute;
	left: 0;
	top:0;
	height: 100vh;
	width: 100vw;
	z-index: 1;
`
const layerStyle = {
	'position': 'absolute',
	'width': '100vw',
	'height':'100vh',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
}

const ParallaxInstead = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	
	img {
		width: 100%;
		height: auto;
	}
`

const Image = styled(motion.img)`
	position: absolute;
	display: block;
	${props => props.blur && 'filter: blur('+ props.blur +'px);' }
`

const Collection = (props) => {
	gsap.registerPlugin(ScrollTrigger);
	const { t, i18n } = useTranslation();

	const sectionRef = useRef()
	const containerRef = useRef()

	useEffect(() => {
		setTimeout(() => {
			props.onReady('collection')
		}, 300)
	}, []);

	return (
		<Section id="about" ref={sectionRef}>
			<Container ref={containerRef} id="ctn">
				<Parallax>
					{ww > 800 ?
						<MouseParallaxContainer
							className="parallax"
							containerStyles={{
								position: 'absolute',
								left: 0,
								top: 0,
								width: '100vw',
								height: '100vh',
								overflow: 'visible'
							}}
							resetOnLeave
						>
							<MouseParallaxChild
								factorX={-0.2}
								factorY={-0.08}
								updateStyles={layerStyle}
							>
								<Image style={{bottom: '13vh', left: '21vw'}}
											 width={213}
											 blur={3}
											 initial={{opacity: 0, zoom: 0.7}}
											 whileInView={{opacity: 1, zoom: 1}}
											 viewport={{once: false}}
											 transition={{duration: 2, ease: 'easeOut'}}
											 src={pImg1} alt=""/>
							</MouseParallaxChild>
							<MouseParallaxChild
								factorX={0.05}
								factorY={0.05}
								updateStyles={layerStyle}
							>
								<Image style={{bottom: '1vh', left: '6vw', x: '-50%', y: '-40%'}}
											 width={153}
											 blur={5}
											 initial={{opacity: 0, zoom: 0.7}}
											 whileInView={{opacity: 1, zoom: 1}}
											 viewport={{once: false}}
											 transition={{duration: 2, ease: 'easeOut'}}
											 src={pImg7} alt=""/>
							</MouseParallaxChild>
							<MouseParallaxChild
								factorX={0.2}
								factorY={0.1}
								updateStyles={layerStyle}
							>
								<Image style={{bottom: 0, left: 0, x: '-30%', y: '40%'}}
											 width={332}
											 blur={2}
											 initial={{opacity: 0, zoom: 0.7}}
											 whileInView={{opacity: 1, zoom: 1}}
											 viewport={{once: false}}
											 transition={{duration: 2, ease: 'easeOut'}}
											 src={pImg2} alt=""/>
							</MouseParallaxChild>
							<MouseParallaxChild
								factorX={0.5}
								factorY={0.4}
								updateStyles={layerStyle}
							>
								<Image style={{top: '25vh', left: '31vw', x: '-50%', y: '-50%'}}
											 width={330}
											 blur={5}
											 initial={{opacity: 0, zoom: 0.7}}
											 whileInView={{opacity: 1, zoom: 1}}
											 viewport={{once: false}}
											 transition={{duration: 2, ease: 'easeOut'}}
											 src={pImg3} alt=""/>
							</MouseParallaxChild>
							<MouseParallaxChild
								factorX={0.1}
								factorY={0.2}
								updateStyles={layerStyle}
							>
								<Image style={{bottom: '-1vh', left: '50vw', x: '-50%'}}
											 width={167}
											 initial={{opacity: 0, zoom: 0.7}}
											 whileInView={{opacity: 1, zoom: 1}}
											 viewport={{once: false}}
											 transition={{duration: 2, ease: 'easeOut'}}
											 src={pImg6} alt=""/>
							</MouseParallaxChild>
							<MouseParallaxChild
								factorX={0.12}
								factorY={0.1}
								updateStyles={layerStyle}
							>
								<Image style={{top: '1vh', left: '4vw', x: '-50%', y: '-50%', rotateX: '180deg', blurRadius: 2}}
											 blur={5}
											 width={210}
											 initial={{opacity: 0, zoom: 0.7}}
											 whileInView={{opacity: 1, zoom: 1}}
											 viewport={{once: false}}
											 transition={{duration: 2, ease: 'easeOut'}}
											 src={pImg8} alt=""/>
							</MouseParallaxChild>
							<MouseParallaxChild
								factorX={0.25}
								factorY={0.4}
								updateStyles={layerStyle}
							>
								<Image style={{top: '10vh', right: '9vw', x: '-50%', y: '-50%'}}
											 width={160}
											 blur={5}
											 initial={{opacity: 0, zoom: 0.7}}
											 whileInView={{opacity: 1, zoom: 1}}
											 viewport={{once: false}}
											 transition={{duration: 2, ease: 'easeOut'}}
											 src={pImg4} alt=""/>
							</MouseParallaxChild>
							<MouseParallaxChild
								factorX={0.09}
								factorY={-0.12}
								updateStyles={layerStyle}
							>
								<Image style={{bottom: '-2vh', right: '-1vw', x: '10%', y: '10%'}}
											 width={324}
											 initial={{opacity: 0, zoom: 0.7}}
											 whileInView={{opacity: 1, zoom: 1}}
											 viewport={{once: false}}
											 transition={{duration: 2, ease: 'easeOut'}}
											 src={pImg5} alt=""/>
							</MouseParallaxChild>
							<MouseParallaxChild
								factorX={0.04}
								factorY={0.2}
								updateStyles={layerStyle}
							>
								<Image style={{top: '50vh', left: 0, x: '-50%', y: '-50%'}}
											 width={328}
											 initial={{opacity: 0, zoom: 0.7}}
											 whileInView={{opacity: 1, zoom: 1}}
											 viewport={{once: false}}
											 transition={{duration: 2, ease: 'easeOut'}}
											 src={pImg9} alt=""/>
							</MouseParallaxChild>
						</MouseParallaxContainer>
						:
						<ParallaxInstead>
							<img src={pInstead} alt=""/>
						</ParallaxInstead>
					}
				</Parallax>

				<TextLine>
					{ww > 800 ?
						<span data-scroll data-scroll-direction="horizontal"
								data-scroll-speed="-6" data-scroll-target="#about">{t('about.title.first')}</span>
						:
						<span>{t('about.title.first')}</span>
					}
				</TextLine>
				<TextLine>
					{ww > 800 ?
						<span data-scroll data-scroll-direction="horizontal"
									data-scroll-speed="6" data-scroll-target="#ctn">{t('about.title.second')}</span>
						:
						<span>{t('about.title.second')}</span>
					}
				</TextLine>
				<TextLine>
					{ww > 800 ?
						<span data-scroll data-scroll-direction="horizontal"
									data-scroll-speed="-10" data-scroll-target="#ctn">{t('about.title.third')}</span>
						:
						<span>{t('about.title.third')}</span>
					}
				</TextLine>
				<TextLine>
					{ww > 800 ?
						<span data-scroll data-scroll-direction="horizontal"
									data-scroll-speed="12" data-scroll-target="#ctn" className="green">TON</span>
						:
						<span className="green">TON</span>
					}
				</TextLine>
			</Container>

		</Section>
	)
}

export default Collection