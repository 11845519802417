import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Scrollbar} from "react-scrollbars-custom";
import RemintInfoList from "../List/RemintInfoList";
import axios from "axios";
import {apiServer, mintData, RestartSVG} from "../../../data/data";
import ButtonStyle2 from "../UI/Buttons/ButtonStyle2";
import ButtonStyle1 from "../UI/Buttons/ButtonStyle1";
import {useDispatch, useSelector} from "react-redux";
import api from "../../../api/Mint"
import {selectMain, selectRemintStart} from "../../../store/features/modalSelectorState";
import CloseModalMob from "../UI/Buttons/Mobile/CloseModalMob";
import CloseModal from "../UI/Buttons/CloseModal";

const isMobile = window.innerWidth <= 800
const RemintItemsInfo = () => {
    const selectedItems = useSelector((state) => state.remintSelector.items)
    const selectedDressUpRequestId = useSelector((state) => state.remintSelector.dress_up_request_id)
    const [summ, setSumm] = useState(0);
    const [comission, setComission] = useState(0);
    const [comissionOneItem, setComissionOneItem] = useState();
    const dispatch = useDispatch()

    useEffect(()=>{
        api.getRemintComissions(selectedDressUpRequestId).then(data=>{
            if(data.success) {
                setComission(data.remint_transaction_amount)
                setComissionOneItem(data.one_item_remint_transaction_amount)
            }
        })
        /*let formData = new URLSearchParams()
        formData.append("dress_up_request_id", 173)
        axios
            .post(apiServer + '/api/remint_request/?key=123', formData,{
                headers: {
                    'Content-Type': `application/x-www-form-urlencoded`,
                }
            })
            .then(data =>{
                console.log(data)
                setItems(data.data.nfts_to_remint)
            })
         */
    }, [])

    function handleClick(){
        dispatch(selectRemintStart())
    }

    return (
        <Overlay>
            <Scrollbar noScrollX={true}
                       thumbYProps={{style: {background: 'rgba(255, 255, 255, 0.3)'}}}
            >
                {isMobile
                    ?
                    <CloseMobBlock onClick={()=>dispatch(selectMain())}>
                        <CloseModalMob/>
                    </CloseMobBlock>
                    :
                    <CloseModal onClick={()=>dispatch(selectMain())}/>
                }

                <Window>
                    <Content>
                        <MainBlock>
                            <Head>
                                <h1>Восстановить вещи </h1>
                                <span>Вы можете восстановить все утерянные вещи заплатив комиссию <span className={'white'}>{comissionOneItem} TON</span> за каждую</span>
                            </Head>
                            <Body>
                                { selectedItems &&
                                    <RemintInfoList list={selectedItems} comission={comissionOneItem}/>
                                }
                            </Body>
                            <Footer>
                                <FooterHead>
                                    <span>Вы заплатите</span>
                                    <span>{comission} TON</span>
                                </FooterHead>
                                <Line/>
                                <TextFooter>
                                    Утерянные вещи восстановятся отдельными NFT на вашем аккаунте
                                </TextFooter>
                            </Footer>
                            {(isMobile)
                                ? <ButtonStyle2 onClick={handleClick} img={RestartSVG} text={"Восстановить вещи"}/>
                                : <ButtonStyle1 onClick={handleClick} img={RestartSVG} primary text={"Восстановить вещи"}/>
                            }
                        </MainBlock>
                    </Content>
                </Window>
            </Scrollbar>
        </Overlay>
    );
};

export default RemintItemsInfo;

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0, .5);
  z-index: 9999;

  @media (max-width: 800px) {
    background-color: #161616;
  }

`

const Window = styled.div`
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  gap: 40px;

  position: relative;
  width: 482px;
  left: 0;
  right: 0;
  margin: 120px auto 100px;
  
  background: #161616;
  border-radius: 22px;

  @media (max-width: 800px) {
    margin: 0px 0px;
    position: relative;
    min-width: 100%;
    width: 100%;
    height: calc(100vh - calc(100vh - 100%));
    left: 0;
    top: 0;
    border-radius: 0px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;

  width: 402px;

  @media (max-width: 800px) {
    width: 320px;
  }
`

const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 30px;
  width: 100%;
`

const Head = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 20px;

  
  h1{
    font-family: 'Roboto';
    font-style: normal;
    font-size: 28px;
    line-height: 33px;
    line-height: 150%;
    /* identical to box height */

    text-align: center;

    color: #FFFFFF;

    @media (max-width: 800px) {
      font-size: 20px;
      line-height: 150%; 
    }
  }
  span{
    font-family: 'Roboto';
    font-style: normal;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */
    text-align: center;
    color: rgba(255, 255, 255, .5);

    @media (max-width: 800px) {
      font-size: 16px;
    }
  }
  span.white{
    color: rgb(255, 255, 255);
  }
`
const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 100%;
`

const Footer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 20px;
    width: 100%;  
  
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 16px;
`

const FooterHead = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 0px;
    span{
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-size: 16px;
      line-height: 130%;
      text-align: right;
      color: #FFFFFF;
    }
`

const Line = styled.div`
    opacity: 0.1;
    height: 1px;
    background: #FFFFFF;
    width: 100%;
    `

const TextFooter = styled.span`
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 130%;
    /* or 18px */
    
    
    color: rgba(255, 255, 255, 0.5);
  
    span.white{
      color: #FFFFFF;
    }
    span.yellow{
      color: #FFA000;
    }
    `

const CloseMobBlock = styled.div`
  position: absolute;
  z-index: 1;
  right: 20px;
  top: 20px;
`

const Close = styled.div`
  position: absolute;
  right: 0px;
  left: 0px;
  margin: 120px auto;
  right: -550px;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background: rgba(255, 255, 255, .15);
  img{
    position: absolute;
    margin: auto;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 20px;
  }
`