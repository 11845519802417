import { createSlice } from '@reduxjs/toolkit'

export const authSelectorSlice = createSlice({
	name: 'authSelector',
	initialState: {
		auth: false,
		connector: null
	},
	reducers: {
		selectAuthorized: (state, action) => {
			const {auth, connector} = action.payload
			state.auth = auth
			state.connector = connector
		},
	},
})

// Action creators are generated for each case reducer function
export const {
	selectAuthorized
} = authSelectorSlice.actions

export default authSelectorSlice.reducer