import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";

import QRCodeStyling, {
    DrawType,
    TypeNumber,
    Mode,
    ErrorCorrectionLevel,
    DotType,
    CornerSquareType,
    CornerDotType,
    Extension,
    Options
} from "qr-code-styling";
import {selectAuth, selectMain} from "../../store/features/modalSelectorState";
import Loader from "../Loader";
import {CopyLinkSVG} from "../../data/data";


const isMobile = window.innerWidth <= 800

const ModalTonIntegr = styled.div`
  position: absolute;
  width: 502px;
  height: 728px;
  left: calc(50% - 502px/2);
  top: calc(50% - 728px/2);

  background: #161616;
  border-radius: 22px;
  z-index: 15;

  @media (max-width: 800px) {
    position: relative;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    border-radius: 0px;
  }
`

const QRPosition = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  top: 80px;

  border-radius: 22px;
  
  svg{
    border-radius: 22px;
  }
`

const TitleText = styled.div`
  position: absolute;
  width: 402px;
  height: 66px;
  left: calc(50% - 402px/2);
  bottom: 228px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  text-align: center;

  color: #FFFFFF;
  
  @media (max-width: 800px) {
    width: 249px;
    height: 23px;
    font-size: 20px;
    line-height: 23px;
    left: calc(50% - 249px/2 + 5.5px);
    bottom: 310px;
  }
  
`

const DescriptionText = styled.div`
  position: absolute;
  width: 360px;
  height: 72px;
  left: calc(50% - 360px/2 - 1px);
  bottom: 136px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */

  text-align: center;

  color: #FFFFFF;

  opacity: 0.5;

  @media (max-width: 800px) {
    width: 309px;
    height: 120px;
    left: calc(50% - 309px/2 + 5.5px);

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }
`

const ModalButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 10px;
  gap: 10px;

  position: absolute;
  width: 422px;
  height: 56px;
  left: calc(50% - 422px/2);

  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  img{
    width: 19.74px;
    height: 20px;
  }
  :hover{
    background: rgba(255, 255, 255, 0.3);
  }
  @media (max-width: 800px) {
    width: 320px;
    height: 56px;
    left: calc(50% - 320px/2);
  }
`
const LinkBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 5px;

  width: 100%;
  position: absolute;
  top: 394px;
  left: 0;
  right: 0;
  margin: 0 auto;
  
  span{
    font-family: 'Roboto';
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;

    color: #207AF0;
  }

  :hover{
    svg{
      opacity: .7;
    }
    span{
      color: rgba(32, 122, 240, .7);
    }
  }
`



const ModalTonIntegration = (props) => {
    const [loaded, setLoaded] = useState(false)
    const selectedModal = useSelector((state) => state.modalSelector.modal)
    const selectorLoading = useSelector((state) => state.modalSelector.modal)
    const selectedAuth = useSelector((state) => state.modalSelector.auth)
    const dispatch = useDispatch()

    const [firstRender, setFirstRender] = useState(false)

    const [link, setLink] = useState('');

    useEffect(()=>{
        setLink(props.link)
        setLoaded(true)
    },[])

    useEffect(()=>{
        options.data = link
        setOptions({...options})
        setQRCode(new QRCodeStyling(options))
        if(isMobile)
            ButtonLink.current?.click();

    }, [link])



    const [options, setOptions] = useState({
        width: 294,
        height: 294,
        type: 'svg',
        data: '',
        //image: '/favicon.ico',
        margin: 10,
        qrOptions: {
            typeNumber: 0,
            mode: 'Byte',
            errorCorrectionLevel: 'Q'
        },
        dotsOptions: {
            color: '#000',
            type: 'rounded'
        },
        backgroundOptions: {
            color: 'white',
        },
        cornersSquareOptions: {
            color: '#222222',
            type: 'extra-rounded',
        },
        cornersDotOptions: {
            color: '#222222',
            type: 'dot',
        }
    });
    const [qrCode, setQRCode] = useState(new QRCodeStyling(options));
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) {
            qrCode.append(ref.current);
        }
    }, [qrCode, ref]);


    return (
        <ModalTonIntegr>
            {!loaded && <Loader/>}
            <QRPosition>
                <div ref={ref} />
            </QRPosition>
            <LinkBlock
                onClick={()=>{
                    navigator.clipboard.writeText(link)
                    document.dispatchEvent(new CustomEvent('tooltipCopy'))
                }}
            >
                {CopyLinkSVG}
                <span>Скопировать адрес</span>
            </LinkBlock>
            <TitleText>
                Отсканируйте QR-код камерой вашего телефона
            </TitleText>
            <DescriptionText>
                Мы не получаем и не храним данные для входа в ваш кошелёк, поэтому ваши TON находятся в безопасности
            </DescriptionText>

            <ModalButtonDiv style={{bottom: "40px"}} onClick={()=>{
                props.setIsMain(true)
            }}>
                Назад
            </ModalButtonDiv>
        </ModalTonIntegr>
    );
};

export default ModalTonIntegration;