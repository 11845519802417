import { createSlice } from '@reduxjs/toolkit'

export const toySelectorSlice = createSlice({
	name: 'toySelector',
	initialState: {
		toy: 'cat',
		loading: false
	},
	reducers: {
		selectCat: (state, action) => {
			state.toy = 'cat'
			state.loading = true
		},
		selectUfo: (state, action) => {
			state.toy = 'ufo'
			state.loading = true
		},
		selectLoaded: (state) => {
			state.loading = false
		}
	},
})

// Action creators are generated for each case reducer function
export const { selectCat, selectUfo, selectLoaded } = toySelectorSlice.actions

export default toySelectorSlice.reducer