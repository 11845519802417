import * as React from "react"

const SecretSvg = (props) => (
	<svg width="109" height="238" viewBox="0 0 109 238" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M66.6111 185.895C65.6019 181.525 65.2654 177.155 65.2654 172.785C65.2654 129.757 109 120.345 109 59.1638C109 21.5141 90.4969 0 54.5 0C18.5031 0 0 21.5141 0 59.1638V82.3587H34.9877V56.8107C34.9877 40.0028 41.7161 33.6158 53.4907 33.6158C65.2654 33.6158 71.9938 40.0028 71.9938 56.8107C71.9938 115.302 31.6235 120.008 31.6235 171.441C31.6235 177.492 32.2963 181.862 33.642 185.895H66.6111ZM31.9599 202.367V238H67.6204V202.367H31.9599Z"
					fill={props.fill ? props.fill : '#EF2ADC'}/>
	</svg>
)

export default SecretSvg
