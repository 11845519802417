import { createSlice } from '@reduxjs/toolkit'

export const gearCountSlice = createSlice({
	name: 'gearVisibility',
	initialState: {
		countData: null,
		inventory: null
	},
	reducers: {
		setCountDataState: (state, action) => {
			state.countData = action.payload
		},
		setInventoryDataState: (state, action) => {
			state.inventory = action.payload
		},
	},
})

// Action creators are generated for each case reducer function
export const {
	setCountDataState,
	setInventoryDataState
} = gearCountSlice.actions

export default gearCountSlice.reducer