import React, {useEffect, useRef, useState} from 'react';
import styled, {css} from "styled-components";
import {AnimatePresence, motion} from "framer-motion";
import {SuccessSVG, ErrorSVG, CopyLinkSVG, TonkeeperSVG} from "../../data/data";
import Lottie from "lottie-react";
import ButtonStyle2 from "./UI/Buttons/ButtonStyle2";
import mintLoader from "../../assets/lottie/guys_mint_loader.json";
import QRCodeStyling from "qr-code-styling";
import Loader from "../Loader";
import {useTranslation} from "react-i18next";
import MiniNFT from "./UI/Elements/MiniNFT";
import Tooltip from "./UI/Elements/Tooltip";
import Pending from "../../assets/lottie/Pending.json";

const isMobile = window.innerWidth <= 800
// {SuccessSVG}

const itemVariants = {
    initial: { opacity: 0, height: 0 },
    open: { opacity: 1, height: 'auto'},
    closed: { opacity: 0, height: 0, scale: 0.2 }
};

const MintItemElement = (props) => {
    const [opened, setOpened] = useState(false)
    const [firstRender, setFirstRender] = useState(false)
    const [link, setLink] = useState('');
    const [loaded, setLoaded] = useState(false)
    const {t} = useTranslation();
    //qrcode
    const [options, setOptions] = useState({
        width: 230,
        height: 230,
        type: 'canvas',
        data: '',
        //image: '/favicon.ico',
        qrOptions: {
            typeNumber: 0,
            mode: 'Byte',
            errorCorrectionLevel: 'Q'
        },
        dotsOptions: {
            color: '#000',
            type: 'rounded'
        },
        backgroundOptions: {
            color: 'white',
        },
        cornersSquareOptions: {
            color: '#222222',
            type: 'extra-rounded',
        },
        cornersDotOptions: {
            color: '#222222',
            type: 'dot',
        }
    });
    const [qrCode, setQRCode] = useState(new QRCodeStyling(options));
    const ref = useRef(null);
    //timers
    const [ seconds, setSeconds ] = useState(120);
    const [ timerActive, setTimerActive ] = useState(false);

    const [error, setError] = useState(false)

    useEffect(() => {
        if(props.list[props.el].status === 'success') {
            setTimerActive(false)
            setError(false)
            setOpened(false)
        }


        if(props.list[props.el].url && !firstRender) {
            setFirstRender(true)
            setLink(props.list[props.el].url)

            if(props.list[props.el].open) {
                setOpened(true)
                if (!timerActive) setTimerActive(true)
            }
        }
        if (ref.current) {
            qrCode.append(ref.current);
        }
    }, [props]);

    useEffect(() => {
        if(props.list[props.el].open) {
            setOpened(true)
            if (!timerActive) setTimerActive(true)
        }
    }, [props.list]);

    useEffect(()=>{
        qrCode.update({
            data: link
        })
        setLoaded(true)
    }, [link])

    function handleOpen(){
        if(props.list[props.el].status !== 'success') {
            if (!timerActive) setTimerActive(true)
            setOpened(!opened)
        }
    }

    React.useEffect(() => {
        if (seconds > 0 && timerActive) {
            setTimeout(setSeconds, 1000, seconds - 1);
        } else {
            setTimerActive(false);
        }

        if(!seconds){
            setError(true)
            setOpened(true)
        }
    }, [ seconds, timerActive ]);

    function statusView(){
        if(error && props.list[props.el].status === 'wait'){
            return <Lottie animationData={mintLoader} loop={true} />;
        }
        switch (props.list[props.el].status){
            case 'wait':
                return <Lottie animationData={mintLoader} loop={true} />
            case 'processing':
                return <Lottie animationData={Pending} loop={true} />
            case 'success':
                return SuccessSVG
            case 'error':
                return ErrorSVG
        }
    }

    return (
        <MintItem opened={opened} {...props}>
            {!loaded && <Loader/>}
            <MintItemHead onClick={()=>handleOpen()}>
                {}
                <MiniNFT
                    img={(props.list[props.el].item) ? props.list[props.el].item.cropped_image_url : props.list[props.el].json_before_burn.cropped_image_url}
                    title={(props.list[props.el].item) ? props.list[props.el].item.metadata.name : props.list[props.el].json_before_burn.metadata.name}
                    description={(props.list[props.el].item) ? props.list[props.el].item.metadata.attributes.Class : props.list[props.el].json_before_burn.metadata.attributes.Class}
                />
                <StatusBlock>
                    {statusView()}
                </StatusBlock>
            </MintItemHead>

            <MintItemBody
                as={motion.div}
                variants={itemVariants}
                initial="initial"
                animate={opened ? "open" : "closed"}
            >
                            <MintItemBodyOperationStatusText error={error}>
                                                        <span>{!error
                                                        ? t("mintpage.modalmint.accept_transaction")
                                                        : t("mintpage.modalmint.operation_aborted")}</span>
                            </MintItemBodyOperationStatusText>
                {isMobile
                    ?
                    <ButtonStyle2 img={TonkeeperSVG} href={link} target="_blank" text={'Подтвердить с Tonkeeper'}/>
                    :
                    <OperationBlock>
                        <h1>Отсканируйте QR-код в Tonkeeper </h1>
                        <QRBlock ref={ref} />
                        <LinkBlock
                            onClick={()=> {
                                navigator.clipboard.writeText(props.list[props.el].burning_address)
                                document.dispatchEvent(new CustomEvent('tooltipCopy'))
                                }
                            }
                        >
                            {CopyLinkSVG}
                            <span>Скопировать адрес</span>
                        </LinkBlock>
                    </OperationBlock>

                }
            </MintItemBody>


        </MintItem>
    );
};

export default MintItemElement;

const MintItem = styled.div`
  width: 100%;
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  ${props=>props.opened && css`
    padding: 0px 0px 30px;
  `}
  
  border-radius: 0px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  
  ${props=> props.start && css`
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  `}
  ${props=> props.end && css`
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  `}
`

const MintItemHead = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  gap: 10px;
  isolation: isolate;

  width: 100%;
  height: 100px;
`

const ItemInfoBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  
  height: 60px;`

const ItemImage = styled.div`
  width: 60px;
  height: 60px;

  background: #424242;
  border-radius: 10px 0px 0px 10px;
  
  img{
    width: 100%;
    height: 100%;
  }
`

const ItemTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 20px 10px 15px;
  gap: 5px;
  
  height: 60px;

  background: #2D2D2D;
  border-radius: 0px 10px 10px 0px;
  
  h1{
    font-family: 'Roboto';
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    display: flex;
    align-items: center;

    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    color: #FFFFFF;
    
    max-width: 213px;
    @media (max-width: 800px) {
      max-width: 141px;
    }
  }
  span{
    font-family: 'Roboto';
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;

    color: #FFFFFF;

    opacity: 0.5;
  }
`

const StatusBlock = styled.div`
  display: flex;
  
  //justify-content: center;
  //align-items: center;
  
  width: 30px;
  height: 30px;
  
  svg{
    width: 30px;
    height: 30px;
  }
  
`

const MintItemBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 20px;

  width: 362px;

  @media (max-width: 800px) {
    width: 280px;
  }

`

const MintItemBodyOperationStatusText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px;
  gap: 10px;

  width: 100%;
  
  background: #1D1D1D;
  border-radius: 8px;
  
  ${props=>props.error && css`
    background: #2C1A19;
  `}
  
  span{
    font-family: 'Roboto';
    font-style: normal;
    font-size: 14px;
    line-height: 130%;
    /* or 17px */
    
    color: rgba(255, 255, 255, 0.5);

    ${props=>props.error && css`
      color: #BE514D;
    `}
  }
`

const OperationBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 20px;

  width: 230px;
  
  h1{
    font-family: 'Roboto';
    font-style: normal;
    font-size: 16px;
    line-height: 120%;
    /* or 19px */

    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
  }
  
`

const QRBlock = styled.div`
    width: 230px;
    height: 230px;
    border-radius: 12px;
  
    svg, canvas{
      border-radius: 12px;
    }
  `

const LinkBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 5px;

  width: 100%;
  span{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    color: #207AF0;
  }
  
  :hover{
    svg{
      opacity: .7;
    }

    span{
      color: rgba(32, 122, 240, .7);
    }
  }
`

