import React, {useEffect, useState} from 'react';
import styled, {css} from "styled-components";
import {SuccessSVG, BurnedSVG, TimeOutSVG, ErrorSVG, CanBeReminted, Reminted, RenderStarted} from "../../../data/data";
import StatusBlock from "../UI/layouts/StatusBlock";
import mintLoader from "../../../assets/lottie/guys_mint_loader.json";
import pendingJson from "../../../assets/lottie/Pending.json";
import Lottie from "lottie-react";
import MiniNFT from "../UI/Elements/MiniNFT";

const isMobile = window.innerWidth <= 800
const HistoryItemElement = (props) => {
    const [statusImg, setStatusImg] = useState(null);
    const [statusText, setStatusText] = useState(null);
    const [tagImg, setTagImg] = useState(null);
    const [textTag, setTextTag] = useState(null);
    const [showTextComment, setShowTextComment] = useState(false);
    const [noneLast, setNoneLast] = useState(false);
    const [time, setTime] = useState(null);
    //console.log(props.dataItem)
    useEffect(() => {
        var date = new Date(props.time)
        var hours = String(date.getHours());
        var minutes = String(date.getMinutes());

        if (hours.length == 1)
            hours = "0" + hours;
        if (minutes.length == 1)
            minutes = "0" + minutes;

        var time = hours + ':' + minutes;
        setTime(time)

        switch (props.type){
            case 'dress_up_request':
                if(props.el.burned && props.status === 'success'){
                    setStatusImg(SuccessSVG)
                    setStatusText("Успешно одето")
                    setTagImg(BurnedSVG)
                    setTextTag("Burned")
                    setShowTextComment(false)
                }else if(props.status === 'aborted'){
                    setStatusImg(ErrorSVG)
                    setStatusText("Отмена")
                    setTagImg(null)
                    setTextTag("Failed")
                    setShowTextComment(true)
                }else if(props.nfts_reminted && props.status === 'expired'){
                    setStatusImg(Reminted)
                    if(isMobile)
                        setStatusText("Возвращена")
                    else
                        setStatusText("Возвращена в минточную")
                    setTagImg(null)
                    setTextTag(null)
                    setShowTextComment(false)
                }
                else if(props.can_be_reminted && props.el.burned && props.status === 'expired'){
                    setStatusImg(CanBeReminted)
                    if(isMobile)
                        setStatusText("Вещь утеряна")
                    else
                        setStatusText("Вещь сожжена и утеряна")
                    setTagImg(null)
                    setTextTag(null)
                    setShowTextComment(true)
                    setNoneLast(true)
                }
                else if(!props.el.burned && props.status === 'expired'){
                    setStatusImg(Reminted)
                    if(isMobile)
                        setStatusText("Возвращена")
                    else
                        setStatusText("Возвращена в минточную")
                    setTagImg(null)
                    setTextTag(null)
                    setShowTextComment(false)
                    setNoneLast(false)
                    if(props.can_be_reminted)
                        setNoneLast(true)
                }
                else if(!props.el.burned && props.status === 'waiting'){
                    setStatusImg(<Lottie animationData={mintLoader} loop={true} />)
                    if(isMobile)
                        setStatusText("Ожидает")
                    else
                        setStatusText("Ожидает действий")
                    setTagImg(TimeOutSVG)
                    setTextTag("Time out")
                    setShowTextComment(true)
                    setNoneLast(true)
                }
                else if(props.el.burned && (props.status === 'waiting')){
                    setStatusImg(<Lottie animationData={pendingJson} loop={true} />)
                    setStatusText("Рендер начался")
                    setTagImg(null)
                    setTextTag(null)
                    setShowTextComment(false)
                    setNoneLast(true)
                }
                else if(props.el.burned && (props.status === 'pending')){
                    setStatusImg(<Lottie animationData={pendingJson} loop={true} />)
                    setStatusText("Рендер начался")
                    setTagImg(null)
                    setTextTag(null)
                    setShowTextComment(false)
                    setNoneLast(false)
                }
                break;
            case 'undress_request':
                if(props.status === 'success'){
                    setStatusImg(SuccessSVG)
                    setStatusText("Успешно разминчено")
                    setTagImg(null)
                    setTextTag(null)
                    setShowTextComment(false)
                }
                break;

        }
    }, [props, props.dataItem, props.status]);

    return (
        <Item {...props} noneLast={noneLast}>
            <EventHead>

                <StatusBlock
                    statusImg={statusImg}
                    text={statusText}
                    tagImg={tagImg}
                    textTag={textTag}
                />
                {time &&
                    <HeadTimeText>{time}</HeadTimeText>
                }

            </EventHead>
            {
                showTextComment
                ?
                    <ItemInfoBlockDIV>
                        <FlexWithRed>
                            <RedCircle/>

                            <ItemInfoPosWithRed>
                                <MiniNFT
                                    img={props.dataItem.cropped_image_url}
                                    title={props.dataItem.metadata.name}
                                    description={props.dataItem.metadata.attributes.Class}
                                />
                            </ItemInfoPosWithRed>
                        </FlexWithRed>
                    </ItemInfoBlockDIV>
                :
                    <ItemInfoPosition>
                        <MiniNFT
                            img={props.dataItem.cropped_image_url}
                            title={props.dataItem.metadata.name}
                            description={props.dataItem.metadata.attributes.Class}
                        />
                    </ItemInfoPosition>

            }


            {
                (showTextComment) &&
                <TextComment
                    error={props.status === 'aborted'}
                    warn={props.can_be_reminted && props.el.burned && props.status === 'expired'}
                >
                    {props.status === 'aborted'
                        ?
                        <span>Операция прервана.<br/>
                            <a href="https://t.me/TONGuys_support" target="_blank">
                            <span
                                className={'link'}
                            >
                                Обратитесь в поддержку
                            </span>

                        </a>
                        </span>

                        :
                        props.can_be_reminted && props.el.burned && props.status === 'expired'
                                ?
                                <span>Эта вещь была сожжена и утеряна, так как мы не дождались от вас действий в течение тайм-аута минта и запрос был просрочен. <span onClick={props.textClick} className={'link'}>Восстановить</span></span>
                                :
                                <span>Для удачного завершения минта сожгите эту вещь. <span onClick={props.textClick} className={'link'}>Продолжить минт</span></span>

                    }

                </TextComment>
            }
        </Item>
    );
};

export default HistoryItemElement;

const Item = styled.div`
    box-sizing: border-box;

    /* Auto layout */
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 20px;
    width: 100%;  
  
    border: 1px solid rgba(255, 255, 255, 0.1); 

      ${props=> props.start && css`
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      `}
      ${props=> props.end && css`
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
      `}

      ${props=> props.noneLast && css`
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      `}
    `

const EventHead = styled.div`
  width: 100%;
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const HeadTimeText = styled.span`
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    display: flex;
    align-items: center;
    text-align: right;

    color: rgba(255, 255, 255, .5);

`

const ItemInfoPosition = styled.div`
    margin-left: 45px;
`
const ItemInfoPosWithRed = styled.div`
    margin-left: 25px;
`

const ItemInfoBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  
  height: 60px;`

const ItemImage = styled.div`
  width: 60px;
  height: 60px;

  background: #424242;
  border-radius: 10px 0px 0px 10px;
  
  img{
    width: 100%;
    height: 100%;
  }
`

const ItemTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 20px 10px 15px;
  gap: 5px;
  
  height: 60px;

  background: #2D2D2D;
  border-radius: 0px 10px 10px 0px;
  
  h1{
    font-family: 'Roboto';
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    display: flex;
    align-items: center;

    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    color: #FFFFFF;
    
    max-width: 213px;
    @media (max-width: 800px) {
      max-width: 141px;
    }
  }
  span{
    font-family: 'Roboto';
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;

    color: #FFFFFF;

    opacity: 0.5;
  }
`

const TextComment = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 15px;
    gap: 10px;
    
    background: #1D1D1D;
    border-radius: 8px;
    width: 100%;

    ${props => props.warn && css`
      background: #2E271D;
    `}
    ${props => props.error && css`
      background: #2C1A19;
    `}
    
    span{
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-size: 14px;
      line-height: 130%;
      /* or 18px */
      color: rgba(255, 255, 255, 0.5);
      
      ${props => props.error && css`
        color: #BE514D;
      `}

    }
    span.link{
      color: #207AF0;
      :hover{
        color: #020DCA;
      }
      
      ${props => props.warn && css`
      
        color: #FFA000;
    
        :hover{
          color: rgba(255, 160, 0, .7)
        }
      
    `}
    }
  
`
const ItemInfoBlockDIV = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px 0px 10px;
  gap: 25px;
`
const FlexWithRed = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;`

const RedCircle = styled.div`
    width: 10px;
    height: 10px;
    left: 10px;
    border-radius: 5px;
    
    background: #F33A33;
`