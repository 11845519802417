import React, {useEffect, useLayoutEffect, useRef} from 'react'
import styled from 'styled-components'
import {motion} from "framer-motion";
import {gsap} from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger'
import {useTranslation} from "react-i18next";

import imgBg from '../assets/Images/collection/collection-bg-min.png'
import imgCat from '../assets/Images/collection/cat/cat_count-min.png'
import imgUfo from '../assets/Images/collection/ufo/ufo_count-min.png'
import {calculateFontSize} from "../utils";

let ww = window.innerWidth

const Section = styled.section`
	position: relative;
	margin-top:-100vh;
	width: 100vw;
	height: 200vh;
	opacity: 0;
	
	@media (max-width: 800px) {
		height: auto;
		margin-top: 0;
		opacity: 1;
	}
`

const Container = styled.div`
	position: relative;
	width: 100vw;
	height: 100vh;
	z-index: 2;
	
	@media (max-width: 800px) {
		height: auto;
	}
`

const Inner = styled(motion.div)`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	width: 100vw;
	height: 100vh;
	justify-content: space-between;
	padding: 100px 40px 40px;
	position: relative;
	z-index: 2;	
	@media (max-width: 800px) {
		height: auto;
		flex-direction: column;
		padding: 20px;
	}
`

const Background = styled.div`
	position: absolute;
	top: -25vh;
	left: -25vw;
	width: 150vw;
	height: 150vh;
	opacity: 0;
	transform: rotate(45deg);
	background-image: url(${imgBg});
	background-size: contain;
	background-repeat: no-repeat;
	background-position:center;
`

const Block = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: calc(50% - 1vw);
	padding: 50px 60px;
	border-radius: 42.3174px;
	background-color: ${props => props.left ? props.theme.colors.green : props.theme.colors.pink};
	justify-content: ${props => props.left ? 'flex-start' : 'flex-end'};
	overflow: hidden;
	height: 100%;
	max-height: 65vw;
	min-height: 600px;
	
	img {
		display: block;
		position: absolute;
		left:0;
		top: ${props => props.left ? 'auto' : '0'};
		bottom: ${props => props.left ? '0' : 'auto'};
		z-index: 1;
		max-width: 100%;
	}
	
	.num {
		position: relative;
		z-index: 2;
		font-family: 'Bebas Neue',sans-serif;
		font-size: ${calculateFontSize(50, 300)};
		line-height: 1;
		letter-spacing: -1vw;
	}
	
	.name {
		position: relative;
		z-index: 2;
		font-weight: 300;
		font-size: 50.7809px;
		line-height: 60px;
	}
	
	@media (max-width: 800px) {
		border-radius: 21.4864px;
		max-width: 360px;
		width: 100%;
		height: 450px;
		padding: 26px 26px 31px;
		max-height: none;
		min-height: 0;
		
		&:nth-child(2) {
			margin-top: 20px;
		}
		
		.num {
			font-size: 180px;
			line-height: 0.8;
			letter-spacing: -10px;
		}
		
		.name {
			font-size: 25.7836px;
			line-height: 30px;
			margin-top: 10px;
		}
	}
`

const CountByToy = (props) => {
	gsap.registerPlugin(ScrollTrigger);
	const { t } = useTranslation();

	const sectionRef = useRef(null);
	const containerRef = useRef(null);
	const innerRef = useRef(null);
	const bgRef = useRef(null);

	useEffect(() => {
		setTimeout(() => {
			props.onReady('countByToy')
		}, 300)
	}, []);

	useLayoutEffect(() => {
		let element = sectionRef.current;
		let t1 = gsap.timeline()

		setTimeout(() => {

			if(ww > 800) {
				t1.to(containerRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '-5% top',
						end: '-4% top',
						scroller: '.App',
						scrub: true
					},
					display: 'block',
					zIndex: 7,
					ease: 'none'
				})
				t1.to(element, {
					scrollTrigger: {
						trigger: element,
						start: 'top top',
						end: 'bottom bottom',
						scroller: '.App',
						pin: containerRef.current,
						pinSpacing: false,
						scrub: true,
					},
					ease: 'none'
				})
				t1.to(element, {
					scrollTrigger: {
						trigger: element,
						start: 'top top',
						end: '25% top',
						scroller: '.App',
						scrub: true,
					},
					opacity: 1,
					duration: 10,
					ease: 'none'
				})

				t1.to(bgRef.current, {
					scrollTrigger: {
						trigger: element,
						start: 'top top',
						end: '25% top',
						scroller: '.App',
						scrub: true,
					},
					rotate: 360,
					scale: 1,
					opacity: 1,
					duration: 10,
					ease: 'none'
				})

				t1.to(containerRef.current, {
					scrollTrigger: {
						trigger: element,
						start: 'center top',
						end: 'bottom top',
						scroller: '.App',
						scrub: true,
					},
					opacity: 0,
					yPercent: -50,
					ease: 'none'
				})
			}else{
				//Mobile
				/*t1.to(element, {
					scrollTrigger: {
						trigger: element,
						start: '20% bottom',
						end: '25% center',
						scroller: '.App',
						scrub: true,
					},
					opacity: 1,
					duration: 10,
					ease: 'none'
				})

				t1.to(containerRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '50% center',
						end: 'bottom center',
						scroller: '.App',
						scrub: true,
					},
					opacity: 0,
					ease: 'none'
				})
				t1.to(bgRef.current, {
					scrollTrigger: {
						trigger: element,
						start: 'center bottom',
						end: 'top top',
						scroller: '.App',
						scrub: true,
					},
					rotate: 360,
					scale: 1,
					opacity: 1,
					duration: 10,
					ease: 'none'
				})*/
			}

			ScrollTrigger.refresh()
		}, 1000)


	}, []);

	return (
		<Section ref={sectionRef}>
			<Container ref={containerRef}>
				<Background ref={bgRef}/>
				<Inner ref={innerRef}>
					<Block>
						<img src={imgCat} alt=""/>
						<div className="num">3141</div>
						<div className="name">{t('collection.cat')}</div>
					</Block>
					<Block left>
						<div className="num">3141</div>
						<div className="name">{t('collection.ufo')}</div>
						<img src={imgUfo} alt=""/>
					</Block>
				</Inner>
			</Container>
		</Section>
	)
}

export default CountByToy