import { createSlice } from '@reduxjs/toolkit'

export const remintSelectorSlice = createSlice({
	name: 'remintSelector',
	initialState: {
		items: null,
		dress_up_request_id: 0
	},
	reducers: {
		selectRemintItems: (state, action) => {
			const {items, dress_up_request_id} = action.payload
			state.items = items
			state.dress_up_request_id = dress_up_request_id
		},
	},
})

// Action creators are generated for each case reducer function
export const {
	selectRemintItems
} = remintSelectorSlice.actions

export default remintSelectorSlice.reducer