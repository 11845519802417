import React, {useEffect, useRef, useState} from 'react';
import HistoryItemElement from "./HistoryItemElement";
import styled from "styled-components";
import Time from "../UI/Time/Time";
import ButtonStyle1 from "../UI/Buttons/ButtonStyle1";
import ButtonStyle2 from "../UI/Buttons/ButtonStyle2";
import { useTimer } from "react-timer-and-stopwatch";
import moment from 'moment';
import {RestartSVG, UpdateSVG} from "../../../data/data";
import {useDispatch} from "react-redux";
import {selectRemintItems} from "../../../store/features/Remint/remintSelectorState";
import {selectContinueMint, selectRemint} from "../../../store/features/modalSelectorState";
import {selectContinueData} from "../../../store/features/ContinueMint/continueMintSelectorState";
import Lottie from "lottie-react";
import UpdateBlueJSON from "../../../assets/lottie/UpdateBlue.json";


const isMobile = window.innerWidth <= 800
const HistoryItemsList = ({list, first, event, eventIndex, updateHistory, dayIndex}) => {
    const dispatch = useDispatch()
    const lottieUpdateRef = useRef(null);
    const [isUpdated, setIsUpdated] = useState(false);
    const timer = useTimer({
        create: {
            timerWithDuration: {
                time: { // Set to a duration of 30 seconds
                    seconds: 0
                }
            }
        }
    });
    const {resetTimer, timerText} = timer;

    const getMonthNameInGenitiveCase = (date = new Date) =>
        [
            'Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня',
            'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря',
        ][date.getMonth()];

    useEffect(() => {
        document.addEventListener('updatedHistory', updatedHistory)
        return () => {
            document.removeEventListener('updatedHistory', updatedHistory)
        };
    }, []);
    //{event[eventIndex].status === 'waiting' &&

    //было обсуждено что expired и waiting при разминте мы не показываем
    if(
        event[eventIndex].type === 'undress_request'
        && (event[eventIndex].status === 'expired' || event[eventIndex].status === 'waiting' || event[eventIndex].status === 'pending')
        && event.length <= 1
    )
        return false

    if(
        event[eventIndex].type === 'undress_request'
        && (event[eventIndex].status === 'expired' || event[eventIndex].status === 'waiting' || event[eventIndex].status === 'pending')
        && event.length > 1
    )
    return first &&
        <FirstFlex>
            <EventDayTitle>
                {new Date(event[eventIndex].created_at).getDate()} {getMonthNameInGenitiveCase(new Date(event[eventIndex].created_at))}
            </EventDayTitle>
            {dayIndex === 0 &&
                <UpdateFlex onClick={handleUpdate}>
                    <Lottie
                        lottieRef={lottieUpdateRef}
                        animationData={UpdateBlueJSON}
                        loop={true}
                        autoplay={false}
                        onLoopComplete={() => {
                            if (isUpdated) {
                                lottieUpdateRef.current.stop()
                                setIsUpdated(false)
                            }
                        }
                        }
                    />
                    <span>
                    Обновить
                </span>
                </UpdateFlex>
            }
        </FirstFlex>

    useEffect(() => {
        if(event[eventIndex].type === 'dress_up_request' && event[eventIndex].status === 'waiting'){
            let data = moment(event[eventIndex].expiration_datetime).valueOf();
            resetTimer({
                create: {
                    timerWithUnixTimestamp: {
                        unixTimestampMilliseconds: data
                    }
                }
            }, true)
        }
    }, []);

    useEffect(() => {

    }, [list]);

    function handleRemint(){
        let remintedItems = {}
        Object.keys(list.nft_items_to_burn).map((el, index)=>{
            if(list.nft_items_to_burn[el].can_be_reminted){
                console.log(list.nft_items_to_burn[el])
                remintedItems[el] = list.nft_items_to_burn[el]
            }
        })
        dispatch(selectRemintItems({
            items: remintedItems,
            dress_up_request_id: list.request_data.dress_up_request_id
        }))
        dispatch(selectRemint())
    }

    function handleContinueMint(){
        console.log(event[eventIndex])
        dispatch(selectContinueData(event[eventIndex]))
        dispatch(selectContinueMint())
    }

    function handleUpdate(){
        setIsUpdated(false)
        lottieUpdateRef.current.play()
        updateHistory()
    }

    function updatedHistory(){
        setIsUpdated(true)
    }

    //console.log(event[eventIndex])
    return (
        <EventDay>
            {first &&
                <FirstFlex>
                    <EventDayTitle>
                        {new Date(event[eventIndex].created_at).getDate()} {getMonthNameInGenitiveCase(new Date(event[eventIndex].created_at))}
                    </EventDayTitle>
                    {dayIndex === 0 &&
                        <UpdateFlex onClick={handleUpdate}>
                            <Lottie
                                lottieRef={lottieUpdateRef}
                                animationData={UpdateBlueJSON}
                                loop={true}
                                autoplay={false}
                                onLoopComplete={()=>{
                                    if(isUpdated) {
                                        lottieUpdateRef.current.stop()
                                        setIsUpdated(false)
                                    }
                                }
                            }
                            />
                            <span>
                                Обновить
                            </span>
                        </UpdateFlex>
                    }
                </FirstFlex>
            }
            <Items>
                {(event[eventIndex].type === 'dress_up_request') &&
                    Object.keys(list.nft_items_to_burn).map((el, index)=>(
                            <HistoryItemElement
                                key={el}
                                el={list.nft_items_to_burn[el]}
                                start={index === 0 && true}
                                end={index === Object.keys(list.nft_items_to_burn).length - 1}
                                dataItem={list.nft_items_to_burn[el].json_before_burn}
                                type={event[eventIndex].type}
                                status={event[eventIndex].status}
                                can_be_reminted={event[eventIndex].burned_nfts_can_be_reminted}
                                nfts_reminted={event[eventIndex].nfts_reminted}
                                time={event[eventIndex].created_at}
                                textClick={(event[eventIndex].burned_nfts_can_be_reminted && event[eventIndex].status === 'expired') ? handleRemint : (event[eventIndex].status === 'waiting') ? handleContinueMint : false}
                            />
                        ))
                }
                {(event[eventIndex].type === 'undress_request'
                        && event[eventIndex].status !== 'expired'
                        && event[eventIndex].status !== 'waiting'
                        && event[eventIndex].status !== 'aborted'
                        && event[eventIndex].status !== 'pending') &&
                    Object.keys(list.new_items_nfts_data).map((el, index)=>(

                        <HistoryItemElement
                            key={el}
                            el={list.new_items_nfts_data[el]}
                            start={index === 0 && true}
                            end={index === Object.keys(list.new_items_nfts_data).length - 1}
                            dataItem={list.new_items_nfts_data[el]}
                            type={event[eventIndex].type}
                            status={event[eventIndex].status}
                            can_be_reminted={null}
                            time={event[eventIndex].created_at}
                        />
                    ))
                }

                {event[eventIndex].status === 'waiting' && event[eventIndex].type !== 'undress_request' &&
                    <BottomBlock>
                        <Time
                            first1={timerText.charAt(timerText.length - 5)}
                            first2={timerText.charAt(timerText.length - 4)}
                            two1={timerText.charAt(timerText.length - 2)}
                            two2={timerText.charAt(timerText.length - 1)}
                        />
                        {(isMobile)
                            ? <ButtonStyle2 onClick={handleContinueMint} text={"Продолжить минт"}/>
                            : <ButtonStyle1 onClick={handleContinueMint} primary text={"Продолжить минт"}/>
                        }
                        <span className={"classic"}>
                        В случае бездействия по истечении этого времени, минт будет завершен,<span className={"main"}> а сожжённые вами NFT будут утеряны</span>. Дальнейшее их восстановление будет возможно только оплатив комиссию.
                    </span>
                    </BottomBlock>
                }
                {(event[eventIndex].burned_nfts_can_be_reminted && event[eventIndex].status === 'expired') &&
                    <BottomBlock>

                        {(isMobile)
                            ? <ButtonStyle2 onClick={handleRemint} img={RestartSVG} text={"Восстановить вещи"}/>
                            : <ButtonStyle1 onClick={handleRemint} img={RestartSVG} primary text={"Восстановить вещи"}/>
                        }
                        <span className={"classic"}>
                        Вы можете восстановить все утерянные вещи заплатив комиссию<span className={"main"}> 0.7 TON</span> за каждую вещь
                    </span>
                    </BottomBlock>

                }

            </Items>
        </EventDay>
    );
};

export default HistoryItemsList;

const EventDay = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
`
const EventDayTitle = styled.div`
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
`
const Items = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
`

const BottomBlock = styled.div`
    box-sizing: border-box;

    /* Auto layout */
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 20px;
    width: 100%;  
  
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  
    span.classic{
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-size: 14px;
      line-height: 130%;
      /* or 18px */
      color: rgba(255, 255, 255, 0.5);
    }
    span.main{
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-size: 14px;
      line-height: 130%;
      color: #FFFFFF;
    }
      
  `

const FirstFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
`

const UpdateFlex = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 5px;
  
    span{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 125% */


      color: #207AF0;
    }
    :hover{
      span{
        color: rgba(32, 122, 240, .7);
      }
      svg{
        opacity: .7;
      }
    }
    `