import React from 'react';
import styled from 'styled-components'

const LoaderWrapper = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background: #000000;
	opacity: 0.5;
	z-index: 9999;
`
const LoaderContent = styled.div`
	display: inline-block;
  position: relative;
  width: 40px !important;
  height: 40px !important;
  
  div {
	  box-sizing: border-box;
	  display: block;
	  position: absolute;
	  width: 32px;
	  height: 32px;
	  margin: 4px;
	  border: 4px solid #fff;
	  border-radius: 50%;
	  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	  border-color: #fff transparent transparent transparent;
  }
  div:nth-child(1) {
	  animation-delay: -0.45s;
	}
	div:nth-child(2) {
	  animation-delay: -0.3s;
	}
	div:nth-child(3) {
	  animation-delay: -0.15s;
	}
	@keyframes lds-ring {
	  0% {
	    transform: rotate(0deg);
	  }
	  100% {
	    transform: rotate(360deg);
	  }
	}
`

const Loader = () => {
	return (
			<LoaderWrapper>
				<LoaderContent>
					<div> </div>
					<div> </div>
					<div> </div>
					<div> </div>
				</LoaderContent>
			</LoaderWrapper>
	)
}

export default Loader