import { createSlice } from '@reduxjs/toolkit'

export const categorySelectorSlice = createSlice({
	name: 'categorySelector',
	initialState: {
		category: null
	},
	reducers: {
		selectCategory: (state, action) => {
			state.category = action.payload
		},
	},
})

// Action creators are generated for each case reducer function
export const {
	selectCategory
} = categorySelectorSlice.actions

export default categorySelectorSlice.reducer