import React from 'react';
import {CloseModalMobSVG} from "../../../../../data/data";
import styled from "styled-components";

const CloseModalMob = (props) => {
    return (
        <Overlay {...props}>
            {CloseModalMobSVG}
        </Overlay>
    );
};

export default CloseModalMob;

const Overlay = styled.div`
    width: 20px;
    height: 20px;
  `