import React from 'react';
import styled, {css} from 'styled-components'

const ModalButtonDiv = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 10px;
  gap: 10px;
  
  width: 100%;
  height: 44px;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;

  background: #207AF0;
  border-radius: 16px;
  
  text-decoration: none;
  img{
    width: 19.74px;
    height: 20px;
  }
  :hover{
    background: #1963C6;
  }
  @media (max-width: 800px) {
    //width: 320px;
  }
  
  span{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    color: #FFFFFF;
  }
`

const ButtonStyle1 = (props) => {
    return (
        <ModalButtonDiv {...props}>
            {props.img &&
                <div>{props.img}</div>
            }
            {props.text}
        </ModalButtonDiv>
    );
};

export default ButtonStyle1;