import React from 'react';
import styled, {css} from "styled-components";
import Lottie from "lottie-react";
import mintLoader from "../../../assets/lottie/guys_mint_loader.json";
import Pending from "../../../assets/lottie/Pending.json";
import {ErrorSVG, SuccessSVG} from "../../../data/data";
import MiniNFT from "../UI/Elements/MiniNFT";

const RemintInfoElement = (props) => {

    function statusBlock(){
        switch (props.list[props.el].status){
            case 'wait':
                return <Lottie animationData={mintLoader} loop={true} />
            case 'processing':
                return <Lottie animationData={Pending} loop={true} />
            case 'success':
                return SuccessSVG
            case 'error':
                return ErrorSVG
        }
    }

    return (
        <Overlay {...props}>
            <MintItemHead>
                <MiniNFT
                    img={props.img}
                    title={props.name}
                    description={props.classEl}
                />
                {props.comission &&
                    <PriceText>
                        {props.comission} TON
                    </PriceText>
                }
                {props.list[props.el].status &&
                    statusBlock()
                }

            </MintItemHead>

        </Overlay>
    );
};

export default RemintInfoElement;

const Overlay = styled.div`
  width: 100%;
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  ${props=>props.opened && css`
    padding: 0px 0px 30px;
  `}
  
  border-radius: 0px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  
  ${props=> props.start && css`
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  `}
  ${props=> props.end && css`
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  `}
`

const MintItemHead = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  gap: 10px;
  isolation: isolate;

  width: 100%;
  height: 100px;
`
const PriceText = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: right;

  color: rgba(255, 255, 255, .5)`

const ItemInfoBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  
  height: 60px;`

const ItemImage = styled.div`
  width: 60px;
  height: 60px;

  background: #424242;
  border-radius: 10px 0px 0px 10px;
  
  img{
    width: 100%;
    height: 100%;
  }
`

const ItemTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 20px 10px 15px;
  gap: 5px;
  
  height: 60px;

  background: #2D2D2D;
  border-radius: 0px 10px 10px 0px;
  
  h1{
    font-family: 'Roboto';
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    display: flex;
    align-items: center;

    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    color: #FFFFFF;
    
    max-width: 213px;
    @media (max-width: 800px) {
      max-width: 141px;
    }
  }
  span{
    font-family: 'Roboto';
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;

    color: #FFFFFF;

    opacity: 0.5;
  }
`