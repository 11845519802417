import React from 'react';
import {selectMain} from "../../../../store/features/modalSelectorState";
import {mintData} from "../../../../data/data";
import styled from "styled-components";

const CloseModal = (props) => {
    return (
        <Close {...props}>
            <img src={mintData.modals.closeImage}/>
        </Close>
    );
};

export default CloseModal;

const Close = styled.div`
  position: absolute;
  right: 0px;
  left: 0px;
  margin: 120px auto;
  right: -570px;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background: rgba(255, 255, 255, .15);
  img{
    position: absolute;
    margin: auto;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 20px;
  }
`