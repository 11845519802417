import React, {useEffect, useRef, useState} from 'react';
import styled, {css} from "styled-components";
import {Scrollbar} from "react-scrollbars-custom";
import image from "../../../assets/Images/mint/modals/green_success.png";
import ButtonStyle1 from "../UI/Buttons/ButtonStyle1";
import {selectMain} from "../../../store/features/modalSelectorState";
import {useDispatch, useSelector} from "react-redux";
import {Trans, useTranslation} from "react-i18next";

const isMobile = window.innerWidth <= 800
const Success = () => {
    const selectedText = useSelector((state) => state.modalSelector.text)
    const dispatch = useDispatch()
    const {t} = useTranslation();
    const refOverlay = useRef(null);
    const [height, setHeight] = useState(false);

    useEffect(() => {
        setHeight(refOverlay.current.clientHeight)
    }, []);

    function handleClickBack(){
        dispatch(selectMain())
    }

    /* <Head>
                            <h1>{t(selectedText.title)}</h1>
                            <span>{t(selectedText.description)}</span>
                        </Head

                            <h1>{t(selectedText.title)}</h1>
                            <span><Trans i18nKey={selectedText.description} /></span>
     */
    return (
        <Overlay ref={refOverlay}>
            <Scrollbar noScrollX={true}
                       thumbYProps={{style: {background: 'rgba(255, 255, 255, 0.3)'}}}
            >
            <Window height={height}>
                <Content>
                    <MainBlock>
                        <Image>
                            <img src={image}/>
                        </Image>
                        <Head>
                            <h1>{t(selectedText.title)}</h1>
                            <span><Trans i18nKey={selectedText.description} /></span>
                        </Head>
                        {
                            !isMobile &&
                            <ButtonStyle1
                                onClick={handleClickBack}
                                text={"OK"}
                            />
                        }
                    </MainBlock>
                </Content>
                {
                    isMobile &&
                    <ButtonStyle1
                        onClick={handleClickBack}
                        text={"OK"}
                    />
                }
            </Window>
            </Scrollbar>
        </Overlay>
    );
};

export default Success;

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0, .5);
  z-index: 9999;

  @media (max-width: 800px) {
    background-color: #161616;
  }

`

const Window = styled.div`
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  gap: 40px;

  position: relative;
  width: 482px;
  left: 0;
  right: 0;
  margin: 120px auto 100px;
  
  background: #161616;
  border-radius: 22px;

  @media (max-width: 800px) {
    margin: 0px 0px;
    position: relative;
    min-width: 100%;
    width: 100%;
    //height: calc(100vh - calc(100vh - 100%));

    ${props=>props.height && css`
      height: calc(100vh - calc(100vh - ${props.height}px));
    `}
    left: 0;
    top: 0;
    border-radius: 0px;
    justify-content: space-between;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;

  width: 402px;

  @media (max-width: 800px) {
    width: 320px;
  }
`

const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 40px;
  width: 100%;
`

const Image = styled.div`
  width: 175px;
  height: 175px;

  img{
    width: 175px;
    height: 175px;
  }
`

const Head = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 20px;


  h1{
    font-family: 'Roboto';
    font-style: normal;
    font-size: 28px;
    line-height: 33px;
    line-height: 150%;
    /* identical to box height */

    text-align: center;

    color: #FFFFFF;

    @media (max-width: 800px) {
      font-size: 20px;
      line-height: 150%;
    }
  }
  span{
    font-family: 'Roboto';
    font-style: normal;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */
    text-align: center;
    color: rgba(255, 255, 255, .5);
    

    @media (max-width: 800px) {
      font-size: 16px;
    }
  }

  span.white{
    color: rgb(255, 255, 255);
  }

  strong{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: rgb(255, 255, 255);
  }
`