import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {motion} from "framer-motion";
import {gsap, ScrollTrigger, TextPlugin} from "gsap/all";
import {useTranslation} from "react-i18next";

//Desktop
import imgMain from '../assets/Images/customization/katnipp-min.png'
import imgGearC01 from '../assets/Images/customization/customC_01-min.png'
import imgGearG01 from '../assets/Images/customization/customG_01-min.png'
import imgGearH01 from '../assets/Images/customization/customH_01-min.png'
import imgGearJ01 from '../assets/Images/customization/customJ_01-min.png'
import imgGearN01 from '../assets/Images/customization/customN_01-min.png'
import imgGearT01 from '../assets/Images/customization/customT_01-min.png'

import imgGearC02 from '../assets/Images/customization/customC_02-min.png'
import imgGearG02 from '../assets/Images/customization/customG_02-min.png'
import imgGearH02 from '../assets/Images/customization/customH_02-min.png'
import imgGearJ02 from '../assets/Images/customization/customJ_02-min.png'
import imgGearN02 from '../assets/Images/customization/customN_02-min.png'
import imgGearT02 from '../assets/Images/customization/customT_02-min.png'

import imgCloud1 from '../assets/Images/customization/message/cloud_1.svg'
import imgCloud2 from '../assets/Images/customization/message/cloud_2.svg'
import imgCloud3 from '../assets/Images/customization/message/cloud_3.svg'
import imgCloud4 from '../assets/Images/customization/message/cloud_4.svg'

//Mobile
import imgMain_mobile from '../assets/Images/customization/mobile/katnipp_mobile-min.png'
import imgGearC01_mobile from '../assets/Images/customization/mobile/customC_01_mobile-min.png'
import imgGearG01_mobile from '../assets/Images/customization/mobile/customG_01_mobile-min.png'
import imgGearH01_mobile from '../assets/Images/customization/mobile/customH_01_mobile-min.png'
import imgGearJ01_mobile from '../assets/Images/customization/mobile/customJ_01_mobile-min.png'
import imgGearN01_mobile from '../assets/Images/customization/mobile/customN_01_mobile-min.png'
import imgGearT01_mobile from '../assets/Images/customization/mobile/customT_01_mobile-min.png'

import imgGearC02_mobile from '../assets/Images/customization/mobile/customC_02_mobile-min.png'
import imgGearG02_mobile from '../assets/Images/customization/mobile/customG_02_mobile-min.png'
import imgGearH02_mobile from '../assets/Images/customization/mobile/customH_02_mobile-min.png'
import imgGearJ02_mobile from '../assets/Images/customization/mobile/customJ_02_mobile-min.png'
import imgGearN02_mobile from '../assets/Images/customization/mobile/customN_02_mobile-min.png'
import imgGearT02_mobile from '../assets/Images/customization/mobile/customT_02_mobile-min.png'

import {customizationData} from "../data/data";

let ww = window.innerWidth

const Section = styled.section`
	position: relative;
	width: 100vw;
	height: 1200vh;
	overflow: hidden;
	@media (max-width: 800px) {
		height: auto;
	}
`

const Container = styled.div`
	position: relative;
	padding-top:100px;
	width: 100vw;
	height: 100vh;
	z-index: 2;
	
	@media (max-width: 800px) {
		height: auto;
		padding-top: 0;  	
	}
`

const Inner = styled.div`
	position: relative;
	height: calc(100vh - 100px);
	
	@media (max-width: 800px) {
		height: 100vh;
		padding-top: 45px;
		overflow: hidden;
	}
`

const Top = styled.div`
	text-align: center;
	opacity: 0;
	
	.badge{
		display: flex;
		align-items: center;
		justify-content: center;
		background: ${props => props.theme.colors.pink};
		border-radius: 61px;
		width: fit-content;
		margin: 0 auto;	
		height: 40px;
		padding: 10px 30px;
		
		@media (max-width: 800px) {
			height: 27px;
			border-radius: 48.8px;
			font-size: 12px;
			line-height: 14px;
			font-weight: 500;
		}
	}
	
	h2 {
		margin-top: 35px;
		font-size: 100px;
		line-height: 94%;
		text-transform: uppercase;
		background-image: linear-gradient(90.46deg, #BF22B0 0, #E0B70F 50%, #BF22B0 100%);
		background-size: 200% auto;
		background-position: 0 0;
		-webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  
    
    @media (max-width: 800px) {
    	margin-top: 15px;
			font-size: 60px;
			line-height: 94%;
			letter-spacing: 0;
		}
	}
	
	.description {
		max-width: 915px;
		margin: 0 auto;
		font-weight: 300;
		font-size: 28px;
		line-height: 36px;
		
		@media (max-width: 800px) {
			font-size: 16px;
			line-height: 28px;
		}
	}
	@media (max-width: 800px) {
		opacity: 1;
	}
`

const Katnip = styled.div`
	position: absolute;
	width: 50%;
	max-width:750px;
	bottom: 0;
	left: 50%;
	transform: translateX(-40%);
	z-index: 3;
	
	.main-image {
		position: relative;
		width: fit-content;
	}
	
	img {
		display: block;
		max-width: 100%;
	}
	
	@media (max-width: 800px) {
		width: 100%;
		max-width: 220px;
		max-height: calc(100% - 175px);
		transform: translateX(-50%);
		left: 50%;
		display: flex;
		text-align: center;
		
		.main-image {
			max-width: 360px;
			width: 220px;
  	  max-height: 100%;
		}
		
		img {
			max-height: 100%;
			margin: 0 auto;
		}
	}
`

const Gear = styled.div`
	position: absolute;
	top:0;
	max-width: 100%;
	height: 100%;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	opacity: 0;
	display: flex;
	align-items: center;
	
	img {
		position: absolute;
		left:0;
		top:0;
	}
	
	.gear-c {
		top: -100vh;
	}
	.gear-t {
		left: -100vw;
	}
	.gear-n {
		left: -100vw;
		top: -30vh;
	}
	.gear-j {
		top: 30vh;
		left: 100vw;
	}
	.gear-g {
		left: 100vw;
		top: -10vh;
	}
	.gear-h {
		top: 100vh;
	}
	.gear-fix {
		position: static;
		opacity: 0;
		visibility: hidden;
	}
	
	@media (max-width: 800px) {
		opacity: 1;
	}
	
`

const ListWrapper = styled.div`
	position: absolute;
	height: 100vh;
	left: 46%;
	right: 3vw;
	top: 0;	
	overflow: hidden;
	
	@media (max-width: 800px) {
		position: static;
		height: auto;
		left: 20px;
		right: 20px;
	}
`

const ListInner = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	position: relative;
	width: 100%;
	top: 100vh;
	opacity: 0;
	max-width: 685px;
	
	> div {
		position: relative;
		width: calc(33.33% - 40px/3);
		max-width: 215px;
	}
	
	.left {
		transform: translateY(110%)
	}
	
	.center {
		transform: translateY(-130%)
	}
	
	.right {
		transform: translateY(110%)
	}
	
	@media (max-width: 800px) {
		top: 0;
		opacity: 1;
		max-width: none;
		padding: 50px 10px;
		.mobile {
			max-width: none;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			gap: 10px;
			
			span {
				margin: 0;
				width: calc(33.33% - 20px/3);
			}
		}
	}
	
`

const Card = styled(motion.span)`
	display: block;
	background: #141414;
	border-radius: 7.02524px;
	margin-top: 20px;	
	
	img {
		display: block;
		width: 100%;
		max-width: 100%;
	}
`

const Message = styled.div`
	position: absolute;
	top:0;
	left:0;
	width: 100%;
	height: 100%;
	z-index: 2;
	
	> div {
		position: absolute;
	}
	.cloud-1 {
		width: 4.4%;
		top: 63.7%;
    left: 55%;
	}
	
	.cloud-2 {
		width: 8%;
		top: 61.7%;
    left: 64%;
	}
	
	.cloud-3 {
		width: 18%;
		top: 54%;
    left: 74.5%;
    z-index:2;
	}
	
	.cloud-4 {
		width: 60%;
		left: 83%;
    top: 24%;
    transform: scale(1);
    transform-origin: left bottom;
	}
	
	.inner {
		position: absolute;
		padding:20px;
		left:50%;
		top:50%;
		transform: translate(-50%, -50%);
		width:100%;
		text-align:center;	
		font-size: 28px;
		line-height: 33px;
		max-width: 380px;
	}
	
	.pre {
		position: relative;
		display: inline-block;
		transform: scale(1);
		transform-origin: left center;
	}
	
	.pre, .last {
		opacity: 0.5
	}
	
	@media (max-width: 800px) {
		display: none;
		/*.cloud-1 {
			top: 55.7%;
		}
		
		.cloud-2 {
			top: 46.7%;
    	left: 60%;
		}
		
		.cloud-3 {
			top: 27%;
    	left: 62.5%;
    	z-index: 2;
    	transform: rotate(-46deg);
		}
		
		.cloud-4 {
			width: 72%;
    	left: 28%;
    	top: -25%;
    	transform-origin: center bottom;
		}
		
		.inner {
			font-size: 16px;
			line-height: 19px;
			max-width: 210px;
		}*/
			
	}
`



const Customization = (props) => {
	const [messageComplete, setMessageComplete] = useState(false);
	gsap.registerPlugin(ScrollTrigger, TextPlugin);
	const { t, i18n } = useTranslation();

	const sectionTRef = useRef(null);
	const containerRef = useRef(null);
	const innerRef = useRef(null);
	const topRef = useRef(null);
	const titleRef = useRef(null);
	const gearFirstRef = useRef(null);
	const gearSecondRef = useRef(null);
	const katnipRef = useRef(null);
	const katnipImgRef = useRef(null);
	const messageRef = useRef(null);
	const listWrapperRef = useRef(null);
	const listInnerRef = useRef(null);

	let t2 = gsap.timeline()
	const messageSel = !!messageRef.current && gsap.utils.selector(messageRef.current)

	//const random = gsap.utils.random(-200, 200, 1, true);


	useEffect(() => {
		setTimeout(() => {
			props.onReady('customization')
		}, 300)
	}, []);

	useEffect(() => {

		if(messageComplete && !!messageRef.current) {
			setMessageAnimation()
			t2.play()
		}
	}, [i18n.language]);

	const setMessageAnimation = () => {
		t2.to(messageSel('.main'), {
			text: {value: t('customization.message.one'),delimiter: ''},
			duration: 1,
			onStart: () => setMessageComplete(false)
		}).to(messageSel('.pre'), {duration: 0,scale: 1}, '-=1')
			.to(messageSel('.pre'), {duration: .3,text: {value: t('customization.message.two'),delimiter: ''}},
				'-=0.3')
			.to(messageSel('.last'), {text: {value: t('customization.message.three'),delimiter: ' '},
				duration: .3,
				onComplete: () => setMessageComplete(true)
				},
				'-=0.3'
			)
		t2.pause()
	}

	useLayoutEffect(() => {
		let element = sectionTRef.current;
		let t1 = gsap.timeline()
		let t3 = gsap.timeline()
		const gearFirstSel = gsap.utils.selector(gearFirstRef.current)
		const gearSecondSel = gsap.utils.selector(gearSecondRef.current)
		const listScrollableSel = gsap.utils.selector(listInnerRef.current)

		const messageSel = !!messageRef.current && gsap.utils.selector(messageRef.current)

		if(!!messageRef.current){
			t2.to(messageSel('.main'), {
				text: {value: t('customization.message.one'),delimiter: ''},
				duration: 4,
				onStart: () => setMessageComplete(false)
			}).to(messageSel('.pre'), {duration: 0,scale: 1}, '-=1')

				.to(messageSel('.pre'), {duration: 1,text: {value: t('customization.message.two'),delimiter: ''}})
				.fromTo(messageSel('.pre'), {left: 30,duration: 0,},{duration: 1,left: 0,}, '-=1')
				.to(messageSel('.pre'), {duration: 0.4,scale: 1.2,ease: 'back.in(2.7)'})
				.to(messageSel('.pre'), {duration: .01,x: 3,yoyo: true,repeat: 30})
				.from(messageSel('.pre'), {duration: 1,scale: 1.2})

				.to(messageSel('.last'), {text: {value: t('customization.message.three'),delimiter: ' '}, duration: 2}, '-=0.5')
				.from(messageSel('.last'), {yPercent: 150, duration: 2, onComplete: () => setMessageComplete(true)}, '-=2')
			t2.pause()
		}

		setTimeout(() => {
			if(ww > 800){
				gsap.to(titleRef.current, {
					scrollTrigger: {
						trigger: element,
						toggleActions: 'restart pause resume pause',
						start: 'top bottom',
						end: 'bottom top',
						scroller: '.App',
					},
					duration: 3,
					repeatDelay: 2,
					repeat: -1,
					backgroundPosition: '-200%',
					yoyo: true,
					ease: 'sine.out'
				})
				t1.fromTo(topRef.current, {
						scrollTrigger: {
							trigger: element,
							start: '-10% top',
							end: '-10% top',
							scroller: '.App',
							scrub: true,
						},
						yPercent: -100,
						rotateY: -40,
						opacity: 0,
						ease: 'none'
					},
					{
						scrollTrigger: {
							trigger: element,
							start: '1% top',
							end: '4% top',
							scroller: '.App',
							scrub: true,
						},
						yPercent: 0,
						rotateY: 0,
						autoAlpha: 1,
						ease: 'back.out(1.7)'
					})
				t1.from(katnipRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '2% top',
						end: '8% top',
						scroller: '.App',
						scrub: true,
					},
					yPercent: 100,
					opacity: 0,
					ease: 'power2.out'
				})
				t1.fromTo([gearFirstRef.current, gearSecondRef.current], {
						scrollTrigger: {
							trigger: element,
							start: 'top top',
							end: 'top top',
							scroller: '.App',
							scrub: true,
						},
						opacity: 0,
						ease: 'none'
					},
					{
						scrollTrigger: {
							trigger: element,
							start: '6% top',
							end: '6% top',
							scroller: '.App',
							scrub: true,
						},
						opacity: 1,
						ease: 'none'
					})
					t1.to(gearFirstSel('img'), {
						scrollTrigger: {
							trigger: element,
							start: '8% top',
							end: '23% top',
							scroller: '.App',
							scrub: true
						},
						top: 0,
						left: 0,
						duration: 1,
						stagger: {
							from: 'start',
							each: .3
						},
						yoyo: true,
						ease: 'back.out(0.5)',
					})
					t1.fromTo(gearFirstRef.current, {
							scrollTrigger: {
								trigger: element,
								start: '17% top',
								end: '17% top',
								scroller: '.App',
								scrub: true,
							},
							opacity: 1,
							ease: 'none'
						},
						{
							scrollTrigger: {
								trigger: element,
								start: '23% top',
								end: '29% top',
								scroller: '.App',
								scrub: true,
							},
							scale: 1.1,
							duration: 1,
							opacity: 0,
							ease: 'back.in(1.5)'
						})
				t1.to(gearSecondSel('img'), {
					scrollTrigger: {
						trigger: element,
						start: '30% top',
						end: '45% top',
						scroller: '.App',
						scrub: true
					},
					top: 0,
					left: 0,
					duration: 1,
					stagger: {
						from: 'start',
						each: .3
					},
					yoyo: true,
					ease: 'back.out(0.5)'
				})
				t1.to(katnipRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '45% top',
						end: '50% top',
						scroller: '.App',
						scrub: true,
					},
					duration: 1,
					x: 0,
					left: 0,
					ease: 'back.in(2.7)'
				})
				t1.fromTo(topRef.current, {
						scrollTrigger: {
							trigger: element,
							start: '40% top',
							end: '41% top',
							scroller: '.App',
							scrub: true,
						},
						duration: 1,
						autoAlpha: 1,
						ease: 'none'
					},
					{
						scrollTrigger: {
							trigger: element,
							start: '40% top',
							end: '49% top',
							scroller: '.App',
							scrub: true,
						},
						duration: 1,
						autoAlpha: 0.2,
						ease: 'none'
					})

				//Scroll Animation
				t1.to(listInnerRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '40% top',
						end: '40% top',
						scroller: '.App',
						scrub: true,
					},
					y: '-100vh',
					opacity: 1,
					ease: 'none'
				})
				t1.to(listScrollableSel('.left'), {
					scrollTrigger: {
						trigger: element,
						start: '48% top',
						end: '73% top',
						scroller: '.App',
						scrub: true,
					},
					duration: 1,
					y: `-${listScrollableSel('.left')[0].offsetHeight}`,
					ease: 'none'
				})
				t1.to(listScrollableSel('.center'), {
					scrollTrigger: {
						trigger: element,
						start: '49% top',
						end: '74% top',
						scroller: '.App',
						scrub: true,
					},
					duration: 1,
					y: `${listScrollableSel('.center')[0].offsetHeight}`,
					ease: 'none'
				})
				t1.to(listScrollableSel('.right'), {
					scrollTrigger: {
						trigger: element,
						start: '55% top',
						end: '74% top',
						scroller: '.App',
						scrub: true,
					},
					duration: 1,
					y: `-${listScrollableSel('.right')[0].offsetHeight}`,
					ease: 'none'
				})
				t1.to(listWrapperRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '75% top',
						end: '75% top',
						scroller: '.App',
						scrub: true,
					},
					duration: 1,
					opacity: 0,
					ease: 'none'
				})

				//Message Animation
				t1.from(messageSel('.cloud-1'), {
					scrollTrigger: {
						trigger: element,
						start: '70% top',
						end: '73% top',
						scroller: '.App',
						scrub: true,
					},
					duration: 4,
					opacity: 0,
					x: -20,
					y: -10,
					ease: 'none'
				})
				t1.from(messageSel('.cloud-2'), {
					scrollTrigger: {
						trigger: element,
						start: '72% top',
						end: '75% top',
						scroller: '.App',
						scrub: true,
					},
					duration: 4,
					opacity: 0,
					x: -15,
					ease: 'none'
				})
				t1.from(messageSel('.cloud-3'), {
					scrollTrigger: {
						trigger: element,
						start: '74% top',
						end: '78% top',
						scroller: '.App',
						scrub: true,
					},
					duration: 4,
					opacity: 0,
					x: -15,
					y: 15,
					ease: 'none'
				})
				t1.from(messageSel('.cloud-4'), {
					scrollTrigger: {
						trigger: element,
						start: '77% top',
						end: '79% top',
						scroller: '.App',
						scrub: true,
					},
					duration: 4,
					opacity: 0,
					x: -15,
					yPercent: -20,
					scale: 0.2,
					ease: 'none',
					onComplete: () => t2.play()
				})

				t1.to(containerRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '90% bottom',
						end: 'bottom bottom',
						scroller: '.App',
						scrub: true
					},
					opacity: 0,
					zIndex: -1,
					ease: 'none'
				})

				t1.to(element, {
					scrollTrigger: {
						trigger: element,
						start: 'top top',
						end: 'bottom bottom',
						scroller: '.App',
						pin: containerRef.current,
					},
					ease: 'none'
				})
			}else{


				t3.to(gearFirstSel('img'), {top: 0,left: 0,opacity: 1,
					stagger: {from: 'start',each: .3},
					duration: 1,
					ease: 'back.out(0.5)',
				}).fromTo(gearFirstRef.current, {opacity: 1,ease: 'none',duration: 0},
					{scale: 1.1,duration: .3,opacity: 0,
						ease: 'back.in(1.5)'
					}).to(gearSecondSel('img'), {top: 0,left: 0, opacity: 1,
					stagger: {from: 'start',each: .3},
					duration: 1,
					ease: 'back.out(0.5)'
				})
				t3.pause()

				/*t1.from(topRef.current, {
						scrollTrigger: {
							trigger: element,
							start: 'top center',
							end: 'top top',
							scroller: '.App',
							scrub: true,
							once: true
						},
						yPercent: -100,
						opacity: 0,
						ease: 'none'
					})*/
				t1.from(katnipImgRef.current, {
					scrollTrigger: {
						trigger: element,
						start: 'top center',
						end: 'top top',
						scroller: '.App',
						scrub: true,
						once: true
					},
					ease: 'power2.out',
					onComplete: () => t3.play(),
				})
				/*t1.to([gearFirstRef.current, gearSecondRef.current], {
						scrollTrigger: {
							trigger: element,
							start: 'top 20%',
							end: 'top 20%',
							scroller: '.App',
							scrub: true,
							once: true
						},
						onComplete: () => t3.play(),
						opacity: 1,
						ease: 'none'
					})*/



				/*t1.to(element, {
					scrollTrigger: {
						trigger: element,
						start: 'top top',
						end: '+=275% center',
						scroller: '.App',
						pin: containerRef.current,
					},
					ease: 'none'
				})*/
			}


			ScrollTrigger.refresh()
		}, 1000)


	}, []);

	const GearItem = ((img, index) => {
		return (
			<Card
					key={`cus-${index}`}
					initial={{filter: 'grayscale(100%)'}}
					whileInView={{filter: 'grayscale(0%)'}}
					transition={{duration: 0.5}}
					viewport={{once: false, amount: 'all'}}
			>
				<img src={img} alt=""/>
			</Card>
		)
	})

	return (
		<Section ref={sectionTRef}>
			<Container ref={containerRef}>
				<Inner ref={innerRef}>
					<Top ref={topRef}>
						<div className="badge">{t('customization.badge')}</div>
						<h2 ref={titleRef}>{t('customization.title')}</h2>
						<div className="description">{t('customization.description')}</div>
					</Top>
					<Katnip ref={katnipRef}>
						<div ref={katnipImgRef} className="main-image">
							<img src={ww > 800 ? imgMain : imgMain_mobile} alt=""/>
							{ww > 800 &&
							<Message ref={messageRef}>
								<div className="cloud-1">
									<img src={imgCloud1} alt=""/>
								</div>
								<div className="cloud-2">
									<img src={imgCloud2} alt=""/>
								</div>
								<div className="cloud-3">
									<img src={imgCloud3} alt=""/>
								</div>
								<div className="cloud-4">
									<img src={imgCloud4} alt=""/>
									<div className="inner">
										<span className="main"> </span>
										<span className="pre"> </span>
										<div className="last"> </div>
									</div>
								</div>
							</Message>
							}
						</div>
						<Gear ref={gearFirstRef}>
							<img src={ww > 800 ? imgGearC01 : imgGearC01_mobile} alt="" className="gear-c"/>
							<img src={ww > 800 ? imgGearT01 : imgGearT01_mobile} alt="" className="gear-t"/>
							<img src={ww > 800 ? imgGearN01 : imgGearN01_mobile} alt="" className="gear-n"/>
							<img src={ww > 800 ? imgGearJ01 : imgGearJ01_mobile} alt="" className="gear-j"/>
							<img src={ww > 800 ? imgGearG01 : imgGearG01_mobile} alt="" className="gear-g"/>
							<img src={ww > 800 ? imgGearH01 : imgGearH01_mobile} alt="" className="gear-h"/>
							<img src={ww > 800 ? imgMain : imgMain_mobile} alt="" className="gear-fix"/>
						</Gear>
						<Gear ref={gearSecondRef}>
							<img src={ww > 800 ? imgGearC02 : imgGearC02_mobile} alt="" className="gear-c"/>
							<img src={ww > 800 ? imgGearT02 : imgGearT02_mobile} alt="" className="gear-t"/>
							<img src={ww > 800 ? imgGearN02 : imgGearN02_mobile} alt="" className="gear-n"/>
							<img src={ww > 800 ? imgGearJ02 : imgGearJ02_mobile} alt="" className="gear-j"/>
							<img src={ww > 800 ? imgGearG02 : imgGearG02_mobile} alt="" className="gear-g"/>
							<img src={ww > 800 ? imgGearH02 : imgGearH02_mobile} alt="" className="gear-h"/>
							<img src={ww > 800 ? imgMain : imgMain_mobile} alt="" className="gear-fix"/>
						</Gear>
					</Katnip>
				</Inner>

				<ListWrapper ref={listWrapperRef}>
					<ListInner id="customizationList" ref={listInnerRef}>
						{ww > 800 ? <>
						<div className="left">
							{customizationData.left.map((imgSrc, i) => GearItem(imgSrc, i))}
						</div>
						<div className="center">
							{customizationData.center.map((imgSrc, i) => GearItem(imgSrc, i))}
						</div>
						<div className="right">
							{customizationData.right.map((imgSrc, i) => GearItem(imgSrc, i))}
						</div>
						</>
						:
							<div className="mobile">
								{customizationData.mobile.map((imgSrc, i) => GearItem(imgSrc, i))}
							</div>
						}
					</ListInner>

				</ListWrapper>
			</Container>
		</Section>
	)
}

export default Customization