import { createSlice } from '@reduxjs/toolkit'

export const continueMintSelectorSlice = createSlice({
	name: 'continueMintSelector',
	initialState: {
		data: null,
	},
	reducers: {
		selectContinueData: (state, action) => {
			state.data = action.payload
		},
	},
})

// Action creators are generated for each case reducer function
export const {
	selectContinueData
} = continueMintSelectorSlice.actions

export default continueMintSelectorSlice.reducer