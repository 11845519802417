import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import styled, {ThemeProvider} from 'styled-components'
import GearCard from "./GearCard";
import {fittingData, fittingGearData, getServerCategory} from '../../data/data'
import {useDispatch, useSelector} from "react-redux";
import CategoriesList from "./CategoriesList";
import {Scrollbar} from "react-scrollbars-custom";
import {setScrollByCategory} from "../../store/features/gearScrollState";
import {useTranslation} from "react-i18next";


const GearContent = styled.div`
	padding: 16px;
	
	@media (max-width: 800px) {
		padding: 0 12px 12px;
	}
`
const List = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	
	> span {
		margin-top: 20px;
	}
	
	@media (max-width: 800px) {
		gap: 12px;
		> span {
			margin-top: 12px;
		}
	}
`

const getDummyItems = (count = 6) => {
	return new Array(count).fill('dummy', 0, count)
}

const GearList = (props) => {
	const selectedToy = useSelector((state) => state.toySelector.toy)
	const selectedCategory = useSelector((state) => state.categorySelector.category)
	const scrollCategory = useSelector((state) => state.gearScroll)
	const gearCount = useSelector((state) => state.gearCount.countData)
	const gearInventory = useSelector((state) => state.gearCount.inventory)
	const dispatch = useDispatch()


	const scrollRef = useRef();
	const {t} = useTranslation();

	const [items, setItems] = useState(null);
	const [viewItems, setViewItems] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if(gearCount && gearCount[selectedToy] && gearCount[selectedToy][selectedCategory]){
			//setItems(getDummyItems(gearCount[selectedToy][selectedCategory]))
			setItems(gearInventory)
			//console.log(items[selectedCategory])
		}else{
			setItems([])
		}

		//Тестовые превью фона
		//if(selectedCategory === 'background') setItems(fittingGearData.background)
	}, [selectedToy])

	const handleScroll = (e) => {
		if(!selectedCategory) return false
		dispatch(setScrollByCategory({gear: selectedCategory, scroll: e.scrollTop}))
	}
	//console.log(selectedCategory)
	//console.log(items[selectedCategory])

	//console.log(gearInventory[selectedCategory])
	return (
			<Scrollbar ref={scrollRef} scrollTop={scrollCategory[selectedCategory]} noScrollX={true} onScrollStop={(e) => handleScroll(e)}>
				<GearContent>
					<List>
						{gearInventory && gearInventory[selectedCategory]
							&& (selectedCategory !== "body" && gearInventory[selectedCategory].length) ?
								gearInventory[selectedCategory].map((item, i) => {
									const imageId = i + 1
									const thumbnail = item.preview || null
									const image = item.url || item.large || null
									return (
											<GearCard
													id={imageId}
													key={`gear-card-${item.id || i}`}
													isLoading={isLoading}
													thumbnail={image}
													image={item.actual_image_url}
													item={item}
											/>
									)
								})
								: (gearInventory && gearInventory[selectedCategory]
								&& ((selectedCategory === "body" || selectedCategory === "character") && gearInventory[selectedCategory][selectedToy].length)
								? gearInventory[selectedCategory][selectedToy].map((item, i) => {
									const imageId = i + 1
									const thumbnail = item.preview || null
									const image = item.url || item.large || null
									return (
										<GearCard
											id={imageId}
											key={`gear-card-${item.id || i}`}
											isLoading={isLoading}
											thumbnail={image}
											image={item.actual_image_url}
											item={item}
										/>
									)
								})
							: <span>
									{t(`fitting.noResult`)}
								</span>)
						}
					</List>

				</GearContent>
			</Scrollbar>
	)
}

export default GearList