import React from 'react';
import {BurnedSVG, SuccessSVG} from "../../../../data/data";
import styled from "styled-components";

const StatusBlock = ({statusImg, text, tagImg, textTag}) => {
    return (
        <Main>
            <Image>
                {statusImg}
            </Image>
            <StatusTextAndTag>
                <span>{text}</span>
                {textTag
                &&
                    <Tag>
                        {tagImg}
                        <span>{textTag}</span>
                    </Tag>
                }

            </StatusTextAndTag>
        </Main>
    );
};

export default StatusBlock;

const Main = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 15px;
  
`

const Image = styled.div`
  width: 30px;
  height: 30px;
  svg{
    width: 30px;
    height: 30px;
  }`

const StatusTextAndTag = styled.div`
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 10px;
  
      span{
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height, or 125% */
        color: #FFFFFF;
      }
`

const Tag = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 10px 6px 8px;
  gap: 4px;
  
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  
  span{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-size: 10px;
    line-height: 10px;

    display: flex;
    align-items: center;

    color: rgba(255, 255, 255, .5);
  }
`