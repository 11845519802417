import React, {useEffect, useLayoutEffect, useRef} from 'react'
import styled from 'styled-components'
import {gsap, ScrollTrigger} from "gsap/all";
import {motion} from "framer-motion";
import {tgLink} from "../data/data";
import { browserName } from 'react-device-detect';
import {useTranslation} from "react-i18next";

import imgTg from "../assets/Svgs/tg.svg";
import imgCat from "../assets/Images/sub_cat-min.png";
import imgUfo from "../assets/Images/sub_ufo-min.png";
import {MouseParallaxChild, MouseParallaxContainer} from "react-parallax-mouse";
import pImg12 from "../assets/Images/collection/p12-min.png";
import pImg13 from "../assets/Images/collection/p13-min.png";
import pImg2 from "../assets/Images/collection/p2-min.png";
import pImg6 from "../assets/Images/collection/p6-min.png";
import pImg4 from "../assets/Images/collection/p4-min.png";
import pSubBlur from "../assets/Images/subscribe_blur-min.png";

let ww = window.innerWidth

const Section = styled.section`
	min-height: 100vh;
	width: 100vw;
	overflow: hidden;
	position: relative;
	opacity: 0;
	@media (max-width: 800px) {
		height: auto;
		opacity: 1;
	}
`

const Container = styled.div`
	background-color: ${props => props.theme.colors.pink};
	min-height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	
	:before{
		content: '';
		position: absolute;
		height: 100%;
		width: 100%;
		top:-40%;
		left: -15%;
		border-radius: 50%;
		background-image:url(${pSubBlur});
		background-position:center;
		background-repeat: no-repeat;
		background-size: contain;
	}
	
	@media (max-width: 800px) {
		height: auto;
		min-height: 100vh;
		padding: 50px 15px;
	}
	
`

const Inner = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	text-align: center;
	z-index:2;
	pointer-events: none;
	
	.center {
		width: 100%;
		max-width: 540px;
	}
	
	.left, .right {
		position: relative;
	}
	
	img {
		display: block;
		max-width: 100%;
	}
	
	@media (max-width: 800px) {
		flex-wrap: wrap;
		.left, .right {
			position: relative;
			width: 50%;
		}
		
		.center {
			order: 2;
			width: 100%;
		}
		
	}
`

const Title = styled.h1`
	font-family: 'Bebas Neue';
	font-weight: 700;
	font-size: 80px;
	line-height: 1;
	text-align: center;
	text-transform: uppercase;
	
	@media (max-width: 800px) {
		font-size: 48px;
		line-height: 94%;
		margin-top: 18px;
	}
`
const Telegram = styled.a`
	display: flex;
	align-items: center;
	justify-content: center;
	
	padding: 15px 44px 16px 49px;
	height: 110px;
	
	font-size: 41.25px;
	line-height: 48px;
	
	background: #000000;
	border-radius: 167.75px;
	margin-top: 26px;
	
	pointer-events: all;
	
	img {
		display: block;
		width: 55px;
		
		margin-right: 16px;
	}
	
	@media (max-width: 800px) {
		height: 68px;
		font-size: 28px;
		line-height: 33px;
		width: 300px;
		margin: 11px auto 0;
		img {
			width: 30px;
			margin-right: 10px;
		}
	}
`
const Parallax = styled.div`
	position: absolute;
	left: 0;
	top:0;
	height: 100vh;
	width: 100vw;
	z-index: 1;
	@media (max-width: 800px) {
		height: 100%;
	}
`
const layerStyle = {
	'position': 'absolute',
	'width': '100vw',
	'height':'100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
}

const Image = styled(motion.img)`
	position: absolute;
	display: block;
	${props => props.blur && 'filter: blur('+ props.blur +'px);' }
`
const Subscribe = (props) => {
	gsap.registerPlugin(ScrollTrigger);
	const { t } = useTranslation();

	const sectionRef = useRef(null);
	const containerRef = useRef(null);
	const titleRef = useRef(null);
	const parallaxRef = useRef(null);
	const imgLeftRef = useRef(null);
	const imgRightRef = useRef(null);
	const buttonRef = useRef(null);


	useEffect(() => {
		setTimeout(() => {
			props.onReady('subscribe')
		}, 300)
	}, []);

	useLayoutEffect(() => {
		let element = sectionRef.current;
		let t1 = gsap.timeline()

		setTimeout(() => {
			if(ww > 800) {
				t1.to(element, {
					scrollTrigger: {
						trigger: element,
						start: 'top center',
						end: 'top 20%',
						scroller: '.App',
						scrub: true
					},
					opacity: 1,
					zIndex: 2,
					ease: 'none'
				})
				t1.from(titleRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '50% bottom',
						end: '95% bottom',
						scroller: '.App',
						scrub: true,
					},
					y: -200,
					opacity: 0,
					ease: 'back.out(2.7)'
				})
				t1.from(imgLeftRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '10% center',
						end: '25% center',
						scroller: '.App',
						scrub: true,
					},
					left: -300,
					opacity: 0,
					scale: 1.5,
					ease: 'back.out(1.7)'
				})
				t1.from(imgRightRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '50% bottom',
						end: '85% bottom',
						scroller: '.App',
						scrub: true,
					},
					left: 300,
					opacity: 0,
					scale: 1.5,
					ease: 'back.out(1.7)'
				})
				t1.from(buttonRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '50% bottom',
						end: '95% bottom',
						scroller: '.App',
						scrub: true,
					},
					y: 300,
					opacity: 0,
					ease: 'back.out(1.7)'
				})
			}


			ScrollTrigger.refresh()
		}, 1000)


	}, []);

	return (
		<Section id="subscribe" ref={sectionRef}>
			<Container ref={containerRef}>
				{(ww > 800) &&
					<Parallax ref={parallaxRef}>

						<MouseParallaxContainer
							className="parallax"
							/*useWindowMouseEvents={true}*/
							containerStyles={{
								position: 'absolute',
								left: 0,
								top: 0,
								width: '100vw',
								height: '100%',
								overflow: 'visible'
							}}
							resetOnLeave
						>
							<MouseParallaxChild
								factorX={-0.3}
								factorY={0.1}
								updateStyles={layerStyle}
							>
								<Image style={{bottom: '10vh', left: '26vw', x: '-50%', y: '-40%', rotate: '100deg', scale: 1.6}}
											 width={153}
											 src={pImg12} alt=""/>
							</MouseParallaxChild>
							<MouseParallaxChild
								factorX={0.2}
								factorY={-0.08}
								updateStyles={layerStyle}
							>
								<Image style={{top: '25vh', right: '21vw', x: '-50%', y: '-50%', scale: 0.6}}
											 width={330}
											 src={pImg13} alt=""/>
							</MouseParallaxChild>
							<MouseParallaxChild
								factorX={0.15}
								factorY={0.04}
								updateStyles={layerStyle}
							>
								<Image style={{top: '1vh', right: '39vw', x: '-50%', y: '-50%', zIndex: 1, scale: 1.5}}
											 width={160}
											 blur={5}
											 src={pImg2} alt=""/>
							</MouseParallaxChild>
							<MouseParallaxChild
								factorX={0.09}
								factorY={-0.12}
								updateStyles={layerStyle}
							>
								<Image style={{bottom: '-2vh', right: '-1vw', x: '10%', y: '10%', zIndex: 10, scale: 0.8}}
											 width={324}
											 src={pImg6} alt=""/>
							</MouseParallaxChild>
							<MouseParallaxChild
								factorX={0.12}
								factorY={0.1}
								updateStyles={layerStyle}
							>
								<Image style={{top: '40vh', left: '5vw', x: '-50%', y: '-50%', rotate: '10deg', scale: 0.8}}
											 width={210}
											 blur={5}
											 src={pImg4} alt=""/>
							</MouseParallaxChild>
						</MouseParallaxContainer>

					</Parallax>
				}
				<Inner>
					<div ref={imgLeftRef} className="left">
						<img src={imgUfo} alt=""/>
					</div>
					<div className="center">
						<Title ref={titleRef}>{t('subscribe.title')}</Title>
						<Telegram ref={buttonRef} target="_blank" rel="noreferrer" href={tgLink}>
							<img src={imgTg} alt="Telegram"/>
							{t('subscribe.button')}
						</Telegram>
					</div>
					<div ref={imgRightRef} className="right">
						<img src={imgCat} alt=""/>
					</div>
				</Inner>
			</Container>
		</Section>
	)
}

export default Subscribe