import React from 'react';
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import image from "../../assets/Images/mint/modals/red_close.png";
import {selectMain, selectTopkeeper} from "../../store/features/modalSelectorState";
import {mintData} from "../../data/data";
import image_topkeeper from "../../assets/Images/tonkeeper.png";
import {useTranslation} from "react-i18next";

const Overlay = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0, .5);
    z-index: 9999;
`

const ModalWindow = styled.div`
  position: absolute;
  width: 502px;
  height: 543px;
  left: calc(50% - 502px/2);
  top: calc(50% - 653px/2 - 9.5px);

  background: #161616;
  border-radius: 22px;
  z-index: 15;

  @media (max-width: 800px) {
    position: relative;
    min-width: 100%;
    width: 100%;
    height: calc(100vh - calc(100vh - 100%));
    left: 0;
    top: 0;
    border-radius: 0px;
  }
`
const ModalImage = styled.div`
  
  
  img{
    position: absolute;
    top: 40px;
    
    width: 175px;
    height: 175px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    border-radius: 22px;
  }
  @media (max-width: 800px) {
    img{
      border-radius: 0px;
    }
  }
`

const TextModal = styled.div`
  position: absolute;
  width: 349px;
  height: 33px;
  left: calc(50% - 349px/2 + 0.5px);
  top: 256px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  /* identical to box height */

  text-align: center;
  color: #FFFFFF;

  @media (max-width: 800px) {
    width: 249px;
    height: 23px;
    font-size: 20px;
    line-height: 23px;
    left: calc(50% - 249px/2 + 5.5px);
    top: 261.12px;
  }

`

const Close = styled.div`
  position: absolute;
  right: -80px;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background: rgba(255, 255, 255, .15);
  img{
    position: absolute;
    margin: auto;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 20px;
  }
`

const ModalButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 10px;
  gap: 10px;

  position: absolute;
  width: 422px;
  height: 56px;
  left: calc(50% - 422px/2);

  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  img{
    width: 19.74px;
    height: 20px;
  }
  :hover{
    background: rgba(255, 255, 255, 0.3);
  }
  @media (max-width: 800px) {
    left: calc(50% - 320px/2);
    width: 320px;
  }
  
  span{
    font-family: 'Roboto';
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */


    color: #FFFFFF;
  }
`

const ModalError = () => {
    const selectedError = useSelector((state) => state.modalSelector.error)
    const dispatch = useDispatch()
    const {t} = useTranslation();

    return (
        <Overlay>
            <ModalWindow>
                <Close onClick={()=>dispatch(selectMain())}>
                    <img src={mintData.modals.closeImage}/>
                </Close>
                <ModalImage>
                    <img src={image}/>
                </ModalImage>
                <TextModal>
                    {t(selectedError)}
                </TextModal>
                <ModalButtonDiv style={{bottom: "40px"}} onClick={()=>{
                    dispatch(selectMain())
                }}>
                    <span>{t("mintpage.buttons.ok")}</span>
                </ModalButtonDiv>
            </ModalWindow>
        </Overlay>
    );
};

export default ModalError;