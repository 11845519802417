import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {NoScrollStyles} from "../styles/GlobalStyles";
import {Scrollbar} from 'react-scrollbars-custom';
import CategoriesList from "../components/Fitting/CategoriesList";
import GearList from "../components/Fitting/GearList";
import {useTranslation} from "react-i18next";
import { useSelector, useDispatch } from 'react-redux'
import {
	unselectAll,
	unselectByCategory
} from '../store/features/gearSelectorState'

import {API_HOST, fittingData} from "../data/data";

import svgTrash from '../assets/Svgs/trash.svg'
import svgBack from '../assets/Svgs/arrow-back.svg'
import svgOpen from '../assets/Svgs/t-shirt-icon.svg'
import svgClose from '../assets/Svgs/close.svg'

import 'react-lazy-load-image-component/src/effects/blur.css';
import {selectCategory} from "../store/features/categorySelectorState";
import FittingView from "../components/Fitting/FittingView";
import Switcher from "../components/Fitting/Switcher";
import FittingBackground from "../components/Fitting/FittingBg";
import axios from "axios";
import {setCountDataState} from "../store/features/gearCountState";
import Loader from "../components/Loader";

let ww = window.innerWidth
const isMobile = window.innerWidth <= 800

const Section = styled.section`
	min-height: 100%;
	width: 100vw;
	position: relative;
	overflow: hidden;
	
	img {
		display: block;
	}
`

const Container = styled.div`
	background-color: ${props => props.theme.colors.black};
	min-height: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;	
`

const GearWrapper = styled.div`
	position: absolute;
	right: 12px;
	top: 12px;
	width: 40%;
	max-width: 544px;
	height: calc(100% - 24px);
	z-index: 6;	
	
	@media (max-width: 800px) {
		top: 0;
		height: 100%;
		left: 0;
		right: auto;
		width: 100%;
		max-width: none;
		z-index: 6000;
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		transition: 0.3s 0s ease;
		
		&.active {
			opacity: 1;
			visibility: visible;
			pointer-events: all;
		}
	}
`

const GearInner = styled.div`
	display: flex;
	flex-direction: column;
	background: rgba(36, 36, 36, 0.6);
	backdrop-filter: blur(337px); //TODO Удалить при торомзах в сраной сафаре
	border-radius: 24px;
	max-height: 100%;
	height: 100%;
	padding: 0 28px 20px;
	
	.ScrollbarsCustom-Track {
		right: -18px !important;
		top: 8px !important;
	}
	.ScrollbarsCustom-Wrapper {
		inset: 0 !important;
		margin: -16px -16px -20px !important;
		padding: 16px;
	}
	.ScrollbarsCustom-Scroller{
		overflow-x: hidden !important;
	}
	
	@media (max-width: 1000px) {
		padding: 0 12px 12px;
	}
	@media (max-width: 800px) {
		padding: 0 12px;
		border-radius: 0;
		background: #1c1c1c;
		backdrop-filter: none; 
		
		.ScrollbarsCustom-Track {
			right: -12px !important;
		}
		.ScrollbarsCustom-Wrapper {
			margin: 0 -12px !important;
			padding: 0 12px;
		}
	}
`
const GearHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	padding: 20px 0;
	min-height: 73px;	
	font-weight: 700;
	font-size: 28px;
	line-height: 33px;
	
	@media (max-width: 800px) {
		justify-content: center;
		flex-wrap: wrap;
		font-size: 20px;
		line-height: 23px;
		padding: 16px 0 12px;
		min-height: auto;
	}
`
const TrashButton = styled.button`
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	outline: none;
	background: none;
	cursor: pointer;
	
	.TrashButtonText{
		display: none;
	}
	
	@media (max-width: 800px) {
		width: 100%;
		margin-top: 16px;
		height: 36px;
		background: rgba(255, 255, 255, 0.05);
		border-radius: 8.25641px;
		opacity: 0.2;
		pointer-events: none;
		transition: 0.3s 0s ease;
		
		
		&.active {
			opacity: 1;
			pointer-events: all;
		}
		
		.TrashButtonText{
			display: block;
			font-size: 13px;
			line-height: 15px;
			margin-left: 4px;
			color: #fff;
		}
	}
`
const BackButton = styled.button`
	width: 32px;
	height: 32px;
	display: block;
	margin-right: 16px;
	border: none;
	outline: none;
	background: none;
	cursor: pointer;
	
	@media (max-width: 800px) {
		position: absolute;
		top: 12px;
		left: 0;
	}
`

const CloseButton = styled.div`
	width: 32px;
	height: 32px;
	padding: 4px;
	cursor: pointer;
	position: absolute;
	top: 12px;
	right: -4px;
	display: none;
	
	@media (max-width: 800px) {
		display: block;
	}
`

const GearContent = styled.div`
	padding: 16px;
	
	@media (max-width: 800px) {
		padding: 0 12px 12px;
	}
`

const MobileListOpen = styled.div`
	display: none;
	position: absolute;
	bottom: 20px;
	right: 12px;
	width: 52px;
	height: 52px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background: #EF2ADC;
	box-shadow: 0 4px 16px rgba(239, 42, 220, 0.7);
	border-radius: 73px;
	z-index: 10;
	
	@media (max-width: 800px) {
		display: flex;
	}
`


const FittingPage = (props) => {
	const [loaded, setLoaded] = useState(false)
	const [countLoaded, setCountLoaded] = useState(true)
	const [listOpened, setListOpened] = useState(false)
	const {t} = useTranslation();

	const selectedToy = useSelector((state) => state.toySelector.toy)
	const selectedGear = useSelector((state) => state.gearSelector)
	const selectedCategory = useSelector((state) => state.categorySelector.category)
	const dispatch = useDispatch()

	const sectionRef = useRef(null);
	const contentRef = useRef(null);

	useEffect(() => {
		document.addEventListener('gearSelect', gearSelectEvent)

		return () => {
			document.removeEventListener('gearSelect', gearSelectEvent)
		};
	}, []);

	useEffect(() => {
		getGearCount()
		setTimeout(() => {
			props.setLoaded(true)
			setLoaded(true)
			props.setPercent(100)
		}, 300)
		return () => {
			props.setLoaded(false)
		};
	}, []);

	useLayoutEffect(() => {
		setTimeout(() => {
			if(ww > 800) {

			}
		}, 1000)


	}, []);

	const getGearCount = () => {
		axios.post(API_HOST, {
			get_info: 1,
		}, )
				.then(response => response.data)
				.then(data => {
					dispatch(setCountDataState(data))
					setCountLoaded(true)
				})
				.catch((error) => {
					if (axios.isCancel(error)) {
						console.log("post Request canceled");
					}
				});
	}

	const gearSelectEvent = ({detail}) => {
		const {id} = detail
		if(id) setListOpened(false)
	}

	const unselectGear = () => {
		if(selectedCategory) {
			dispatch(unselectByCategory(selectedCategory))
			document.dispatchEvent(new CustomEvent('gearSelect', { detail: {category: selectedCategory, image: null}}));
		}else{
			dispatch(unselectAll())
			document.dispatchEvent(new CustomEvent('gearSelect', { detail: {category: 'all', image: null}}));
		}

	}

	const getTrashClass = () => {
		let btnClass = ''
		if(selectedCategory){
			btnClass = selectedGear[selectedCategory].id ? 'active' : ''
		}else{
			let count = 0
			{Object.keys(selectedGear).map((key, i) => {
				if(selectedGear[key].id){
					count++
				}
			})}
			if(count > 0) btnClass = 'active'
		}

		return btnClass
	}

	return (
		<>
			<NoScrollStyles/>
			<Section id="fitting" ref={sectionRef}>
				<Container>
					{/*<FittingDev/>*/}
					<FittingBackground/>

					<FittingView/>

					<Switcher/>
					<MobileListOpen onClick={() => setListOpened(true)}>
						<img src={svgOpen} alt=""/>
					</MobileListOpen>
					<GearWrapper className={listOpened ? 'active' : ''}>
						{!countLoaded && <Loader/>}
						<GearInner>
							<GearHeader>
								{selectedCategory &&
								<BackButton onClick={() => dispatch(selectCategory())}>
									<img src={svgBack} alt=""/>
								</BackButton>
								}
								{selectedCategory ?
									t(`fitting.${fittingData.categories[selectedCategory].transId}`)
									:
									t(`fitting.noCategory`)
								}
								<CloseButton onClick={() => setListOpened(false)}>
									<img src={svgClose} alt=""/>
								</CloseButton>
								<TrashButton className={getTrashClass()} onClick={() => unselectGear()}>
									<div className="TrashButtonIcon">
										<img src={svgTrash} alt=""/>
									</div>
									<div className="TrashButtonText">
										{selectedCategory ?
												t(`fitting.trashButtonText.${selectedCategory}`)
												:
												t(`fitting.trashButtonText.all`)
										}
									</div>
								</TrashButton>
							</GearHeader>
							{selectedCategory ?
									<GearList/>
									:
								<Scrollbar noScrollX={true}>
									<GearContent ref={contentRef}>
										<CategoriesList items={fittingData.categories}/>
									</GearContent>
								</Scrollbar>

							}
						</GearInner>
					</GearWrapper>
				</Container>
			</Section>

		</>
	)
}

export default FittingPage