import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react'
import styled, {ThemeProvider} from 'styled-components'
import {useTranslation} from "react-i18next";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {motion} from "framer-motion";
import Tilt from '../../libs/Tilt'
import { useSelector, useDispatch } from 'react-redux'
import {
	ShimmerThumbnail
} from "react-shimmer-effects";
import {
	unselectByCategory,
	selectByCategory
} from '../../store/features/Mint/gearSelectorState'
import {
	API_HOST,
	fittingData,
	fittingGearData,
	getServerCategory,
	Locked,
	LockedSVG,
	mintData, SuccessSVG,
	TimeSVG
} from "../../data/data";
import Loader from '../Loader'

import svgCheck from '../../assets/Svgs/check_circle.svg'
import axios from "axios";
import categoryMintSelectorReducer from "../../store/features/Mint/categorySelectorState";
import {useTimer} from "react-timer-and-stopwatch";
import moment from "moment";
import {selectContinueMintItem, selectError, selectWarn} from "../../store/features/modalSelectorState";
import {selectContinueData} from "../../store/features/ContinueMint/continueMintSelectorState";
import Rarity from "./UI/Elements/Rarity";
import Tooltip from "./UI/Elements/Tooltip";
import {unselectAll} from "../../store/features/Mint/gearSelectorState";

const itemVariants = {
	initial: { opacity: 0, y: -20 },
	open: {
		opacity: 1,
		y: 0,
		transition: { delay: 1 }
	},
	closed: {
		opacity: 0,
		y: +20,
		transition: { delay: .5 }
	}
};

const GearCard = (props) => {
	const gearCount = useSelector((state) => state.gearCount.countData)
	const gearInventory = useSelector((state) => state.gearCount.inventory)
	const selectedToy = useSelector((state) => state.toySelector.toy)
	const selectedGear = useSelector((state) => state.gearMintSelector)
	const selectedCategory = useSelector((state) => state.categoryMintSelector.category)
	const isActive = selectedGear[selectedCategory].id === props.id
	const [isLocked, setIsLocked] = useState(false);
	const [isRarity, setIsRarity] = useState(false);

	const [showTooltip, setShowTooltip] = useState(false);
	const [tooltipText, setTooltipText] = useState('');

	const [loaded, setLoaded] = useState(false);
	const [imgSrc, setImgSrc] = useState(null);
	const dispatch = useDispatch()

	const timer = useTimer({
		create: {
			timerWithDuration: {
				time: { // Set to a duration of 30 seconds
					seconds: 0
				}
			}
		}
	});
	const {resetTimer, timerText} = timer;

	const cardClass = `
		gear-card ${selectedToy.toString()} ${isActive && 'active'}
	`
	const gearData = {
		id: props.id,
		image:
			(selectedToy =='cat')
			? props.item.actual_image_url.replace("ufo", "cat")
			: props.item.actual_image_url.replace("cat", "ufo"),
		gear: selectedCategory,
		item: props.item
	}
	const gearSelectEvent = new CustomEvent('gearSelect', { detail: selectedCategory });

	useEffect(() => {
		if(gearData.item.locked_by) {
			let date = moment(gearData.item.locked_by.expiration_datetime).valueOf();
			resetTimer({
				create: {
					timerWithUnixTimestamp: {
						unixTimestampMilliseconds: date
					}
				}
			}, true)
			setIsLocked(true)
		}
		if(
			gearData.item.metadata.attributes.Rarity === 'Secret'
			|| gearData.item.metadata.attributes.Rarity === 'Mythic'
			|| gearData.item.metadata.attributes.Rarity === 'Legendary'
		){
			setIsRarity(true)
		}
		let address = gearData.item.address
		setTooltipText(address.substring(0, 4)+' • • • • '+address.slice(-4))

	}, [props]);

	useEffect(() => {
		if(imgSrc) {
			gearData.image = imgSrc
		}
		//if(isActive) dispatch(selectByCategory(gearData))
	}, [imgSrc])

	useEffect(() => {
		if(gearCount[selectedToy][selectedCategory]){
			getImg()
		}
	}, [selectedToy])

	const getImg = async () => {
		setImgSrc(gearData.image)
		console.log(gearData)
	}

	const setSelectedGear = () => {
		if(!props.item.is_secret) {
			if (isActive) {
				dispatch(unselectByCategory(selectedCategory))
				document.dispatchEvent(new CustomEvent('gearSelect', {
					detail: {
						category: selectedCategory,
						id: null,
						item: null,
						image: null
					}
				}));
			} else {
				console.log("SELECTED GEAR FALSE")
				console.log(gearData)
				dispatch(selectByCategory(gearData))
				//console.log(selectedGear)
				document.dispatchEvent(new CustomEvent('gearSelect', {
					detail: {
						category: selectedCategory,
						id: props.id,
						image: gearData.image,
						item: props.item,
						toy: selectedToy
					}
				}));
			}
		}
	}

	const getBlankToyImage = () => {
		return <LazyLoadImage
			effect="blur"
			wrapperClassName={'lazyWrap'}
			src={mintData[selectedToy].blank} // use normal <img> attributes as props
		/>
	}

	const imageUrl = imgSrc || props.thumbnail || null
	const downloading = isActive && selectedGear[selectedCategory].loading

	useEffect(()=>{
		console.log(selectedGear)
	}, [selectedGear])

	function handleGearClick(){
		if(isLocked  && gearData.gear !== 'character'){
			dispatch(selectContinueData(gearData.item))
			dispatch(selectContinueMintItem())
		}
		else if(isLocked  && gearData.gear === 'character'){
			dispatch(selectWarn())
			//dispatch(selectError("mintpage.error.5106"))
		}else{
			if(gearData.gear === 'character')
			{
				dispatch(unselectAll())
				document.dispatchEvent(new CustomEvent('gearSelect', {detail: {category: 'all', image: null}}))
			}
			setSelectedGear()
		}
	}


	return (
		<CardWrapper className={!loaded && 'loading'}
					 onMouseEnter={() => setShowTooltip(true)}
					 onMouseLeave={() => setShowTooltip(false)}
		>
			<Tilt className={'tilt'}>
				{
					isLocked &&
					<TimeBlock>
						{TimeSVG}
						<span>{timerText.charAt(timerText.length - 5)}{timerText.charAt(timerText.length - 4)}:{timerText.charAt(timerText.length - 2)}{timerText.charAt(timerText.length - 1)}</span>
					</TimeBlock>
				}
				<ToolPosition
					as={motion.div}
					variants={itemVariants}
					initial="initial"
					animate={showTooltip ? "open" : "closed"}
				>
					<Tooltip black text={tooltipText}/>
				</ToolPosition>
				{
					isRarity &&
					<RarityPosition>
						{!isActive
							?
							<Rarity
								mythic={(gearData.item.metadata.attributes.Rarity === 'Mythic')}
								secret={(gearData.item.metadata.attributes.Rarity === 'Secret')}
								legendary={(gearData.item.metadata.attributes.Rarity === 'Legendary')}
								text={gearData.item.metadata.attributes.Rarity}
							/>
							:
							<Rarity
								classic
								text={gearData.item.metadata.attributes.Rarity}
							/>
						}
					</RarityPosition>
				}

				<Card
						whileHover={{scale: 1.05}}
						className={cardClass}
						data-flip-id={isActive ? `flip-view-${selectedCategory}` : ''}
						onClick={handleGearClick}
				>
					{downloading && <Loader/>}
					{selectedCategory === 'background' &&
					<GearBackground>
						<LazyLoadImage
								beforeLoad={() => getImg()}
								afterLoad={() => setLoaded(true)}
								effect="blur"
								src={imageUrl} // use normal <img> attributes as props
						/>
					</GearBackground>
					}


					<PlaceholderImage src={mintData.cat.blank}/>
					{(props.isLoading || !loaded) && <ShimmerThumbnail className="shimmer-thumb" center rounded/>}
					{isActive && <CheckImage src={svgCheck}/>}

					{
						isLocked &&
						<LockedBlock>
							{LockedSVG}
						</LockedBlock>
					}


					<GearImage className={'blank'}>
						{selectedToy === 'cat' && selectedCategory !== 'body' && selectedCategory !== 'character' && getBlankToyImage()}
						{selectedToy === 'ufo' && selectedCategory !== 'body' && selectedCategory !== 'character' && getBlankToyImage()}
					</GearImage>
					{selectedCategory !== 'background' &&
					<GearImage>
						<LazyLoadImage
								beforeLoad={() => getImg()}
								afterLoad={() => setLoaded(true)}
								effect="blur"
								src={imageUrl} // use normal <img> attributes as props
						/>
					</GearImage>
					}
					{/*{(imgSrc && !props.isLoading) &&

					}*/}
				</Card>
			</Tilt>
		</CardWrapper>
	)
}

export default GearCard;

const CardWrapper = styled(motion.div)`
	position: relative;
	width: calc(33.33% - 36px/3);
	
	${props => props.bgi && `background-image: url(${props.bgi})`};
	
	&.loading {
		pointer-events: none;
	}
	
	@media (max-width: 1100px) {
		width: calc(50% - 8px);
	}
	@media (max-width: 800px) {
		width: calc(50% - 6px);
	}
	
`
const Card = styled(motion.div)`
	background: rgba(255, 255, 255, 0.05);
	position: relative;
	cursor: pointer;
	border-radius: 12px;
	max-height: 180px;
	overflow: hidden;
	
	&.active {
		&.cat {
			background-color: ${props => props.theme.colors.pink};
		}
		&.ufo {
			background-color: ${props => props.theme.colors.green};
		}
	}
	
	span {
		position: absolute;
		bottom: 0;
		left: 0;
	} 
	
	
	
	span, div {
		width: 100%;
		height: 100%;
	}
	
	> div.text-center {
		position: absolute;
		top: -50%;
		left: -50%;
		width: 200%;
		height: 200%;
		transform: rotate(20deg);
	}
	
	.tilt {
		position: absolute;
		top: -50%;
		left: -50%;
		width: 200%;
		height: 200%;
		transform: rotate(20deg);
	}
	
	.lazyWrap {
		display: flex;
		align-items: flex-end;
	}
	
	.shimmer-thumb {
		width: 100%;
		height: 100% !important;
		animation: shimmer 3s cubic-bezier(0.54,-0.15, 0.58, 1) infinite forwards;
		margin-bottom: 0;
		background: linear-gradient(to right, 
		rgba(255, 255, 255, 0.05) 8%, 
		rgba(255, 255, 255, 0.15) 18%, 
		rgba(255, 255, 255, 0.05) 33%);
		background-size: 1000px 100%;
	}
`
const PlaceholderImage = styled.img`
	display: block;
	max-width: 100%;
	height: auto;
	opacity: 0;
	visibility: hidden;
`
const CheckImage = styled.img`
	position: absolute;
	right: 8px;
	top: 8px;
	z-index: 5;
`
const GearImage = styled.span`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	pointer-events: none;
	text-align: center;
	
	&.blank {
		z-index: 1;
	}
	
	img {
		display: block;
		position: absolute;
		margin: 0 auto;
		max-width: 100%;
		max-height: 100%;
		height: 100%;
		width: 100%;
		object-fit: contain;
		object-position: center bottom;
	}
`

const GearBackground = styled.span`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	pointer-events: none;
	
	img {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`

const FlipPlaceholder = styled.span`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	
	img {
		max-width: 100%;
		height: auto;
	}
`
const LockedBlock = styled.div`
	svg{
		position: absolute;
		width: 24px;
		height: 24px;
		right: 10px;
		top: 10.45px;
		z-index: 5;
	}
`

const TimeBlock = styled.div`
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 5px;
	gap: 2px;
	
	position: absolute;
	left: 10px;
	bottom: 10.11px;
	width: 57px;
	height: 24px;
	z-index: 5;

	background: rgba(0, 0, 0, 0.8);
	border-radius: 8px;
	
	span{
		font-family: 'Roboto', sans-serif;
		font-style: normal;
		font-size: 12px;
		line-height: 14px;
		/* identical to box height */

		display: flex;
		align-items: center;

		color: #FFFFFF;
	}
`

const RarityPosition = styled.div`
	position: absolute;
	left: 10px;
	top: 10.89px;
	z-index: 5;
	`

const ToolPosition = styled.div`
    position: absolute;
    z-index: 9999;
    bottom: 10.4px;
    left: 0;
    right: 0;
    margin: 0 auto;
  
    display: inline-flex;
    justify-content: center;
    align-items: center;
`