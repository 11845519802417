import React, {useEffect, useState} from 'react';
import {Scrollbar} from "react-scrollbars-custom";
import styled from "styled-components";
import modal_top from "../../assets/Images/modal_top.png";
import image_topkeeper from "../../assets/Images/tonkeeper.png";
import image_tophub from "../../assets/Images/tophub.png";
import {useDispatch, useSelector} from "react-redux";
import {selectTopkeeper, selectMain, selectOther, selectTophub} from "../../store/features/modalSelectorState";
import {
    DeLabModal,
    DeLabButton,
    DeLabConnect,
    DeLabConnecting,
    DeLabTransaction,
    DeLabEvent
} from '@delab-team/connect'
import {useTranslation} from "react-i18next";
import QRCodeStyling from "qr-code-styling";
import ModalTonIntegration from "./ModalTonIntegration";
import Loader from "../Loader";



const isMobile = window.innerWidth <= 800

const Overlay = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0, .5);
    z-index: 9999;
`

const ModalWindow = styled.div`
  position: absolute;
  width: 502px;
  height: 590px;
  left: calc(50% - 502px/2);
  top: calc(50% - 653px/2 - 9.5px);

  background: #161616;
  border-radius: 22px;
  z-index: 15;

  @media (max-width: 800px) {
    position: relative;
    min-width: 100%;
    width: 100%;
    height: calc(100vh - calc(100vh - 100%));
    left: 0;
    top: 0;
    border-radius: 0px;
  }
`

const TextModal = styled.div`
  position: absolute;
  width: 349px;
  height: 33px;
  left: calc(50% - 349px/2 + 0.5px);
  top: 276px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  /* identical to box height */

  text-align: center;
  color: #FFFFFF;

  @media (max-width: 800px) {
    width: 249px;
    height: 23px;
    font-size: 20px;
    line-height: 23px;
    left: calc(50% - 249px/2 + 5.5px);
    top: calc(50% - 23px/2 - 39.5px);
  }
  
`

const TextDescription = styled.div`
  position: absolute;
  width: 388px;
  height: 96px;
  left: calc(50% - 388px/2 - 1px);
  top: 329px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */

  text-align: center;

  color: #FFFFFF;

  opacity: 0.5;

  @media (max-width: 800px) {
    width: 309px;
    height: 120px;
    left: calc(50% - 309px/2 + 5.5px);
    top: calc(50% - 120px/2 + 52px);

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }
`

const ModalImage = styled.div`
  overflow: hidden;
  
  img{
    position: absolute;
    max-width: 100%;
    height: auto;
    border-radius: 22px;
  }
  @media (max-width: 800px) {
    img{
      border-radius: 0px;
    }
  }
`

const LinkOther = styled.div`
  position: absolute;
  width: 90px;
  height: 16px;
  left: calc(50% - 90px/2);
  bottom: 30px;

  font-family: 'Roboto';
  font-style: normal;
  font-size: 14px;
  line-height: 16px;

  color: #207AF0;
`

const ModalButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 10px;
  gap: 10px;

  position: absolute;
  width: 422px;
  height: 56px;
  left: calc(50% - 422px/2);

  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  img{
    width: 19.74px;
    height: 20px;
  }
  :hover{
    background: rgba(255, 255, 255, 0.3);
  }
  @media (max-width: 800px) {
    left: calc(50% - 320px/2);
    width: 320px;
  }
`


const TextInfo = styled.span`
  position: absolute;
  display: inline;
  white-space: nowrap;
width: 208px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  
bottom: 30px;
text-align: center;

font-family: 'Roboto';
font-style: normal;
font-size: 12px;
line-height: 150%;
/* identical to box height, or 18px */


color: rgba(255, 255, 255, 0.5);
  
  a{
    color: #207AF0;
  }
`


const Modal = (props) => {
    // @ts-ignore
    const [loaded, setLoaded] = useState(false)
    const selectedModal = useSelector((state) => state.modalSelector.modal)
    const selectorLoading = useSelector((state) => state.modalSelector.modal)
    const selectedAuth = useSelector((state) => state.modalSelector.auth)
    const dispatch = useDispatch()
    const [link, setLink] = useState('');
    const [firstRender, setFirstRender] = useState(false)
    const {t} = useTranslation();

    const [isMain, setIsMain] = useState(true)

    useEffect(() => {
        if (!firstRender && props.DeLabConnector) {
            setFirstRender(true);
            registerListen();

        }
    }, []);

    function registerListen() {
        props.DeLabConnector.on('link', (data) => {
            setLink(data.data ?? '');
            if(data.data) {
                setLoaded(true)
            }
            console.log(data.data)
        });
    }

    useEffect(()=>{
        setLink("")
        // if(props.DeLabConnector) {
        switch (props.tonConnectType) {
            case 'tonkeeper':
                //console.log(props.DeLabConnector.tonConnectWallets)
                props.DeLabConnector.createTonConnect().then(()=>{
                    let wallets = props.DeLabConnector.tonConnectWallets
                    console.log(wallets)
                    props.DeLabConnector.connectTonkeeper(wallets[0])
                })

                //props.DeLabConnector.connectTonkeeper(wallets[0])
                break;
            case 'tonhub':
                props.DeLabConnector.createTonHub().then(()=>{
                    props.DeLabConnector.connectTonHub().then(()=>{
                        // setLink(props.DeLabConnector.sessionTonHub().link)
                    })
                })
                break;
            default:
                break;
        }
        // }

    }, [])



/*
    // @ts-ignore
    props.DeLabConnector.on('connect', async (dataDe) => {
        // @ts-ignore
        const connectConfig = dataDe.data
        // @ts-ignore
        const trans = {
            to: 'EQCkR1cGmnsE45N4K0otPl5EnxnRakmGqeJUNua5fkWhales',
            value: '1000000' // string value in nano-coins
        }
        const data = await props.DeLabConnector.sendTransaction(trans)
        if (connectConfig.typeConnect === 'tonkeeper') {
            // display qrcode code ...
            console.log('tonkeeper link: ', data)
        }
    })

    props.DeLabConnector.on('disconnect', () => {
        console.log('disconect')
    })

 */

// Use loadWallet() after the subscriptions are installed
    //DeLabConnector.disconnect()
    //props.DeLabConnector.loadWallet()
    //console.log(DeLabConnector.address)

/*()=>{
                    const inf = DeLabConnector.connectTonkeeper()
                    console.log(inf)
                }}*/

    /*<ModalButtonDiv style={{bottom: "66px"}} onClick={()=>{
    dispatch(selectTophub())
}}>
<img src={image_tophub}/>Tonhub
</ModalButtonDiv>

<LinkOther onClick={()=>{
                dispatch(selectOther())
            }}>
                {t("mintpage.modal.other_adress")}
            </LinkOther>
*/

    return (
        <Overlay>
            {(isMain)
            ? <ModalWindow>
                    <Scrollbar noScrollX={true} style={{paddingRight: "10px"}}>
                    {!loaded && <Loader/>}
                    <ModalImage>
                        <img src={modal_top}/>
                    </ModalImage>
                    <TextModal>
                        {t("mintpage.modal.title")}
                    </TextModal>
                    <TextDescription>
                        {t("mintpage.modal.description")}
                    </TextDescription>
                    <div>
                        <ModalButtonDiv style={{bottom: "78px"}} onClick={()=>{
                            if(isMobile){
                                window.open(link)
                            }else{
                                setIsMain(false)
                            }

                        }}>
                            <img src={image_topkeeper}/>Tonkeeper
                        </ModalButtonDiv>

                    </div>

                    <TextInfo>
                        Dear user please utilize <a href="https://tonkeeper.com/">Tonkeeper</a> only
                    </TextInfo>
                    </Scrollbar>
                </ModalWindow>
            : <ModalTonIntegration link={link} setIsMain={setIsMain}/>
            }
        </Overlay>

    );
};

export default Modal;