import React, {useEffect} from 'react';
import styled from "styled-components";
import {Scrollbar} from "react-scrollbars-custom";
import modal_top from "../../../assets/Images/mint/modals/Decompose_top.png";
import {useTranslation} from "react-i18next";
import ButtonStyle1 from "../UI/Buttons/ButtonStyle1";
import MiniNFT from "../UI/Elements/MiniNFT";
import {useDispatch, useSelector} from "react-redux";
import {selectDecompose, selectError, selectMain} from "../../../store/features/modalSelectorState";
import CloseModal from "../UI/Buttons/CloseModal";

const PreDecompose = () => {
    const {t} = useTranslation();
    const selectedGear = useSelector((state) => state.gearMintSelector)
    const dispatch = useDispatch()

    useEffect(() => {
        if(!selectedGear['character'].id){
            dispatch(selectError("Не выбран character"))
            return false
        }
        console.log(selectedGear['character'])

    }, []);

    function closeModal(){
        dispatch(selectMain())
    }

    return (
        <Overlay>
            <Scrollbar noScrollX={true}
                       thumbYProps={{style: {background: 'rgba(255, 255, 255, 0.3)'}}}
            >
                <CloseModal onClick={closeModal}/>
                <Window>
                    <Image>
                        <img src={modal_top}/>
                    </Image>
                    <Content>
                        <MainBlock>
                            <Head>
                                <h1>{t("mintpage.decompose.title")}</h1>
                                <span>Ваша NFT будет разобрана — все вещи и body станут отдельными NFT на вашем аккаунте</span>
                            </Head>
                            <Body>
                                <MiniNFT
                                    img={selectedGear['character'].item.actual_image_url}
                                    title={selectedGear['character'].item.metadata.name}
                                    description={'CHARACTER'}
                                    maxwidth={true}
                                />
                            </Body>
                            <ButtonStyle1 onClick={()=>dispatch(selectDecompose())} primary text={"Разобрать"}/>
                        </MainBlock>
                    </Content>
                </Window>
            </Scrollbar>
        </Overlay>
    );
};

export default PreDecompose;

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0, .5);
  z-index: 9999;

  @media (max-width: 800px) {
    background-color: #161616;
  }

`

const Window = styled.div`
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  gap: 40px;

  position: relative;
  width: 482px;
  left: 0;
  right: 0;
  margin: 120px auto 100px;
  
  background: #161616;
  border-radius: 22px;

  @media (max-width: 800px) {
    padding: 40px 20px;
    gap: 30px;
    margin: 0px 0px;
    position: relative;
    min-width: 100%;
    width: 100%;
    height: calc(100vh - calc(100vh - 100%));
    left: 0;
    top: 0;
    border-radius: 0px;
  }
`

const Image = styled.div`
      width: 100%;  
      position: absolute;
      top: 0;
      z-index: 0;
      img{
        border-top-right-radius: 22px;
        border-top-left-radius: 22px;
        width: 100%;
      }

  @media (max-width: 800px) {
    img{
      border-radius: 0px;
    }
  }
`

const Content = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  padding-top: 240px;
  gap: 40px;

  width: 402px;

  @media (max-width: 800px) {
    width: 320px;
  }
`

const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 30px;
  width: 100%;
  
`

const Head = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 20px;

  img{
    width: 100%;
  }

  
  h1{
    font-family: 'Roboto';
    font-style: normal;
    font-size: 28px;
    line-height: 33px;
    line-height: 150%;
    /* identical to box height */

    text-align: center;

    color: #FFFFFF;

    @media (max-width: 800px) {
      font-size: 20px;
      line-height: 150%; 
    }
  }
  span{
    font-family: 'Roboto';
    font-style: normal;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */
    text-align: center;
    color: rgba(255, 255, 255, .5);

    @media (max-width: 800px) {
      font-size: 16px;
    }
  }
  span.white{
    color: rgb(255, 255, 255);
  }
`

const Body = styled.div`
    box-sizing: border-box;

    /* Auto layout */
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    gap: 10px;
    width: 100%;
    
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    `