 import React, {useEffect, useState} from 'react';
import {
    selectTopkeeper,
    selectMain,
    selectOther,
    selectTophub,
    selectAuth,
    selectUnAuth
} from "../../store/features/modalSelectorState";

import {
    selectAuthorized,
} from "../../store/features/Mint/authSelectorState";

import {useDispatch, useSelector} from "react-redux";
import Modal from "./Modal";
import ModalOtherAdress from "./ModalOtherAdress";
import ModalTonIntegration from "./ModalTonIntegration";
import {
    DeLabConnect,
    DeLabConnecting,
    DeLabTransaction,
    DeLabEvent
} from '@delab-team/connect'
import ModalMint from "./modals/ModalMint"
import ModalError from "./ModalError";
import ModalQRcode from "./ModalQRcode";
import ModalSuccess from "./ModalSuccess";
import History from "./modals/History";
import RemintItemsInfo from "./modals/RemintItemsInfo";
import RemintStart from "./modals/RemintStart";
import PreMint from "./modals/PreMint";
import PreDecompose from "./modals/PreDecompose";
 import Decompose from "./modals/Decompose";
 import ContinueMint from "./modals/ContinueMint";
 import ContinueMintItem from "./modals/ContinueMintItem";
 import Success from "./modals/Success";
 import Error from "./modals/Error";
 import Warn from "./modals/Warn";

const DeLabConnector = new DeLabConnect('https://tonguys.org/', 'Tonguys', 'mainnet')
const SwitchModal = (props) => {
    const selectedModal = useSelector((state) => state.modalSelector.modal)
    const selectedUrl = useSelector((state) => state.modalSelector.link)
    const selectorLoading = useSelector((state) => state.modalSelector.modal)
    const selectedAuth = useSelector((state) => state.modalSelector.auth)
    const dispatch = useDispatch()
    const [firstRender, setFirstRender] = useState(false);


    function registerListen() {
        DeLabConnector.on('connect',  async(dataDe) => {
            if(!selectedAuth && !firstRender) {
                dispatch(selectAuthorized({auth: true, connector: DeLabConnector}))
                dispatch(selectAuth())
                props.setUserWallet(DeLabConnector.address)
            }
            // @ts-ignore
            const connectConfig = dataDe.data
            // @ts-ignore
            const trans = {
                to: 'EQCkR1cGmnsE45N4K0otPl5EnxnRakmGqeJUNua5fkWhales',
                value: '1000000' // string value in nano-coins
            }
            //const data = await DeLabConnector.sendTransaction(trans)
            if (connectConfig.typeConnect === 'tonkeeper') {
                // display qrcode code ...
                //console.log('tonkeeper link: ', data)
            }
           /* let data2 = DeLabConnector.sendNFTTransactionTonkeeper()
            console.log(data2)
            console.log("АУЕЕЕ")*/

        })

        DeLabConnector.on('disconnect', () => {
            dispatch(selectAuthorized({auth: false, connector: null}))
            dispatch(selectUnAuth())
            console.log('disconect')
        })

        DeLabConnector.on('error', (data) => {
            console.log('error-> ', data.data)
        })

        DeLabConnector.on('error-transaction', (data) => {
            console.log('error-transaction-> ', data.data)
        })

        DeLabConnector.on('error-toncoinwallet', (data) => {
            console.log('error-toncoinwallet-> ', data.data)
        })

        DeLabConnector.on('error-tonhub', (data) => {
            console.log('error-tonhub-> ', data.data)
        })

        DeLabConnector.on('error-tonkeeper', (data) => {
            console.log('error-tonkeeper-> ', data.data)
        })

        DeLabConnector.loadWallet()

        //DeLabConnector.disconnect()

    }

    useEffect(() => {
        if (!firstRender && DeLabConnector) {
            setFirstRender(true);
            registerListen();
            if(DeLabConnector.address && !selectedAuth){
                //DeLabConnector.sendTransaction()
                dispatch(selectAuth())
                props.setUserWallet(DeLabConnector.address)
            }
        }
    }, []);




    function ActiveModal(){
        switch (selectedModal) {
            case 'main':
                return <Modal DeLabConnector={DeLabConnector} setUserWallet={props.setUserWallet} tonConnectType="tonkeeper"/>
            case 'other':
                return <ModalOtherAdress setUserWallet={props.setUserWallet}/>
            case 'tonkeeper':
                return <ModalTonIntegration DeLabConnector={DeLabConnector} tonConnectType="tonkeeper"/>
            case 'tonhub':
                return <ModalTonIntegration DeLabConnector={DeLabConnector} tonConnectType="tonhub"/>
            case 'mint':
                return <ModalMint/>
            default:
                return <div></div>
        }
    }

    function AuthModals(){
        switch (selectedModal) {
            case 'premint':
                return <PreMint/>
            case 'mint':
                return <ModalMint DeLabConnector={DeLabConnector}/>
            case 'history':
                return <History DeLabConnector={DeLabConnector}/>
            case 'remint':
                return <RemintItemsInfo/>
            case 'remintStart':
                return <RemintStart/>
            case 'error':
                return <Error/>
            case 'warn':
                return <Warn/>
            case 'qr':
                return <ModalQRcode link={selectedUrl}/>
            case 'success':
                return <Success/>
            case 'predecompose':
                return <PreDecompose/>
            case 'decompose':
                return <Decompose/>
            case 'continuemint':
                return <ContinueMint/>
            case 'continueMintItem':
                return <ContinueMintItem/>
            default:
                return <div></div>
        }
    }


    return (
        <div>
            {(selectedAuth === false)
                ? ActiveModal()
                : AuthModals()
            }
        </div>
    );
};

export default SwitchModal;