import {ThemeProvider} from "styled-components";
import {GlobalStyles} from "./styles/GlobalStyles";
import {light} from "./styles/Themes";
import {LocomotiveScrollProvider} from 'react-locomotive-scroll'
import React, {useRef, useState, useEffect, useCallback} from "react";
/*const { pathname } = useLocation() // With react-router*/

import Loading from "./sections/Loading";
import NavBar from "./components/NavBar";

import 'locomotive-scroll/dist/locomotive-scroll.css'
import {AnimatePresence} from "framer-motion";
import ScrollTriggerProxy from "./components/ScrollTriggerProxy";
import {browserName} from 'react-device-detect'
import {Route, Routes, Navigate, useLocation} from "react-router-dom";
import HomePage from "./pages/HomePage";
import FittingPage from "./pages/FittingPage";
import {useNavigate} from "react-router";
import { useTranslation } from "react-i18next";
import FittingBackground from "./components/Fitting/FittingBg";
import MintPage from "./pages/MintPage";

function App() {
	const navigate = useNavigate();
	const { i18n } = useTranslation();
	const { pathname } = useLocation();
	const [loaded, setLoaded] = useState(false)
	const [ready, setReady] = useState(false)
	const [percentLoad, setPercent] = useState(0)
	const [navigationReady, setNavigationReady] = useState(false)
	const [firstRender, setFirstRender] = useState(true)
	//const [path, setPath] = useState('')
	//Access by hash
	//const checkAccess = window.location.hash === '#access'

	const containerRef = useRef(null)

	useEffect(() => {
		if (loaded && navigationReady){
			setFirstRender(false)
			setTimeout(() => {
				setReady(true)
			}, 300)
		}else{
			setReady(false)
		}
	}, [loaded]);

	useEffect(() => {
		if(window.location.pathname === '/'){
			navigate(`/${i18n.language}`);
		}
	}, [])

	//Access by hash
	//if(!checkAccess) return false

  return (
		<>
			<GlobalStyles/>
			<ThemeProvider theme={light}>
				{!ready && <Loading percent={percentLoad}/>}
				<LocomotiveScrollProvider
					options={
						{
							smooth: true,
							smartphone: {
								smooth: false
							},
							tablet: {
								smooth: false
							}
							// ... all available Locomotive Scroll instance options
						}
					}
					watch={
						[
							//..all the dependencies you want to watch to update the scroll.
							//  Basicaly, you would want to watch page/location changes
							//  For exemple, on Next.js you would want to watch properties like `router.asPath` (you may want to add more criterias if the instance should be update on locations with query parameters)
						]
					}
					containerRef={containerRef}
					location={pathname}
					onLocationChange={scroll => {
						scroll.setScroll(0, 0)
						scroll.scrollTo('#app', 0, 0)
					}} // If you want to reset the scroll position to 0 for example
					onUpdate={scroll => {
						scroll.scroll.scrollTo(0,0,0)
					}} // Will trigger on
				>
					<ScrollTriggerProxy/>
					<NavBar loaded={ready} onReady={setNavigationReady}/>
					<AnimatePresence>
						<main className="App" id="app" data-scroll-container ref={containerRef}>

							<Routes>
								<Route path={'/:lang'}
											 element={
												 <HomePage
													 ready={ready}
													 setPercent={setPercent}
													 setLoaded={setLoaded}/>
											 }>

								</Route>
								<Route path={'/:lang/fitting'}
											 element={
												 <FittingPage ready={ready} setPercent={setPercent} setLoaded={setLoaded}/>
											 }/>
								<Route path={'/:lang/mint'}
									   element={
										   <MintPage ready={ready} setPercent={setPercent} setLoaded={setLoaded}/>
									   }/>
								<Route
									path="*"
									element={<Navigate to="/" replace />}
								/>

							</Routes>
						</main>
					</AnimatePresence>
				</LocomotiveScrollProvider>
			</ThemeProvider>
		</>
  );
}

export default App;
