import React, {useState} from 'react';
import MintItemElement from "./MintItemElement";

const MintItemsList = ({list}) => {
    return (
        Object.keys(list).map((el, index)=>(
                <MintItemElement
                    key={index}
                    list={list}
                    start={index === 0}
                    end={index === Object.keys(list).length - 1}
                    el={el}
                />
            ))
    );
};

export default MintItemsList;