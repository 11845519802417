import React, {useEffect, useLayoutEffect, useRef} from 'react'
import styled from 'styled-components'
import {motion} from "framer-motion";

import {gsap} from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger'

import {light} from "../styles/Themes";
import {sequenceListCat} from "../data/data";
import Sequence from "../components/Sequence";
import {MouseParallaxChild, MouseParallaxContainer} from "react-parallax-mouse";
import {useTranslation} from "react-i18next";

import imgBg from '../assets/Images/collection/cat/bg-catnip-min.png'
import imgBgMobile from '../assets/Images/mobile/catnip_bg_mobile-min.jpg'
import imgBgMobileTop from '../assets/Images/mobile/catnip_top_bg-min.png'
import imgLampTL from '../assets/Images/collection/cat/lamp_tl-min.png'
import imgLampTR from '../assets/Images/collection/cat/lamp_tr-min.png'
import imgLampBL from '../assets/Images/collection/cat/lamp_bl-min.png'
import imgLampBR from '../assets/Images/collection/cat/lamp_br-min.png'
import pInstead from '../assets/Images/mobile/parallax_catnip_instead-min.png'
import seqInstead from '../assets/Images/mobile/sequence_catnip_instead-min.png'

let ww = window.innerWidth

const Section = styled.section`
	margin-top: -1px;
	position: relative;
	min-height: 250vh;
	width: 100vw;
	top: 0;
	left: 0;

	background-color: ${props => props.theme.colors.pink};
	
	@media (max-width: 800px) {
		min-height: auto;
	}
	
`

const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;	
	width: 100vw;
	height: 100vh;
	background-image: url(${ww > 800 ? imgBg : imgBgMobile}); 
	background-size: cover;
	background-position: top center;
	
	@media (max-width: 800px) {
		height: auto;
		
		&:before {
			content: '';
			display: block;
			position: absolute;
			z-index: 2;
			width: 100%;
			background-image:url(${imgBgMobileTop});
			background-position:left top;
			background-repeat: repeat-x;
			height: 80px;
		}
	}
`

const Top = styled.div`
	position: relative;
	width: 100vw;
	flex: 1;
	opacity:0;
	transform: translateY(200px);
	@media (max-width: 800px) {
		opacity: 1;
		transform: none;
	}
`

const TopInner = styled.div`
	position: absolute;
	
	height: 100%;
	width: 100%;
	
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	
	canvas {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);	
	}
	
	@media (max-width: 800px) {
		position: relative;
		top: 0;
		left: 0;
		padding: 60px 20px 0;
		transform: none;
		text-align: center;
		
		canvas {
			display: none;
		}
		img {
			max-width: 100%;
		}
	}
`

const Bottom = styled(motion.div)`
	text-align: center;
	padding: 0 5vw 5vmin;
	perspective: 800px;
	
	p {
		font-weight: 300;
		font-size: 32px;
		line-height: 48px;
		max-width: 717px;
		transform-origin: bottom center; 
	}
	
	@media (max-width: 800px) {
		p{
			font-size: 20px;
			line-height: 28px;
		}
	}
`

const Description = styled(motion.div)`
	font-weight: 500;
	font-size: 20px;
	line-height: 23px;
	text-align: center;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	
	@media (max-width: 800px) {
		font-size: 10px;
		line-height: 12px;
	}
`

const ToyName = styled(motion.h1)`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 12px;
	font-size: 17vmin;
	line-height: 0.9;
	font-family: 'Bebas Neue', sans-serif;
	text-transform: uppercase;
	perspective: 400px;
	transform-style: preserve-3d;
	
	span {
		display: block;
	}
	
	@media (max-width: 800px) {
		font-size: 110px;
		line-height: 110px;
		margin-top: 6px;
	}
`

const Parallax = styled.div`
	position: absolute;
	left: 0;
	top:0;
	height: 100vh;
	width: 100vw;
	z-index: 1;
`
const layerStyle = {
	'position': 'absolute',
	'width': '100vw',
	'height':'100vh',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
}
const ParallaxInstead = styled.div`
	position: absolute;
	left: 0;
	top: 52px;
	width: 100%;
	height: 100%;
	
	img {
		width: 100%;
		height: auto;
	}
`
const Image = styled(motion.img)`
	position: absolute;
	display: block;
`

const Cat = (props) => {

	gsap.registerPlugin(ScrollTrigger);
	const { t } = useTranslation();

	const sectionRef = useRef()
	const containerRef = useRef()
	const topRef = useRef()
	const topInnerRef = useRef()

	useEffect(() => {

		if(ww <= 800) {
			setTimeout(() => {
				props.onReady('cat')
			}, 300)
		}
	}, []);

	useLayoutEffect(() => {
		let element = sectionRef.current;
		let t1 = gsap.timeline()

		setTimeout(() => {
			if(ww > 800) {
				t1.to(element, {
					scrollTrigger: {
						trigger: element,
						start: 'top top',
						end: 'bottom bottom',
						scroller: '.App',
						pin: containerRef.current,
						scrub: true,
						zIndex: 5,
					},
					ease: 'none'
				})

				t1.fromTo(containerRef.current, {
						scrollTrigger: {
							trigger: element,
							start: '-50% top',
							end: '-49% top',
							scroller: '.App',
							scrub: true,
						},
						opacity: 0,
						scale: 1.2,
						ease: 'none'
					},
					{
						scrollTrigger: {
							trigger: element,
							start: '-20% top',
							end: 'top top',
							scroller: '.App',
							scrub: true,
						},
						opacity: 1,
						scale: 1,
						ease: 'none'
					})

				t1.to(topRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '-2% 20%',
						end: '5% 20%',
						scroller: '.App',
						scrub: true,
					},
					opacity:1,
					y:0,
					ease: 'none',
				})
				t1.to(topInnerRef.current, {
					scrollTrigger: {
						trigger: element,
						start: 'top top',
						end: '80% bottom',
						scroller: '.App',
						scrub: true,
					},
					scale: 0.8,
					yPercent: -42,
					ease: 'none',
				})

				t1.fromTo(containerRef.current, {
						scrollTrigger: {
							trigger: element,
							start: '68% bottom',
							end: '69% bottom',
							scroller: '.App',
							scrub: true
						},
						opacity: 1,
						ease: 'none'
					},
					{
						scrollTrigger: {
							trigger: element,
							start: '80% bottom',
							end: 'bottom bottom',
							scroller: '.App',
							scrub: true
						},
						opacity: 0,
						ease: 'none'
					})

				t1.to(sectionRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '70% bottom',
						end: '90% bottom',
						scroller: '.App',
						scrub: true
					},
					backgroundColor: '#000',
					ease: 'none'
				})

			}
			ScrollTrigger.refresh()
		}, 1000)


	}, []);

	const nameVariants = {
		hidden: {
			opacity: 0,
		},
		show: {
			opacity: 1,
			transition: {
				delayChildren: .2,
				staggerChildren: .1,
			}
		}
	}

	const nameItemVariants = {
		hidden:{
			opacity:0,
			rotateY: 10,
			color: light.colors.pink,
			y: 20,
			z: 50,
			transition: {
				duration: 0.1
			}
		},
		show:{
			opacity: 1,
			rotateY: 0,
			color: '#fff',
			y: 0,
			z: 0,
			transition: {
				duration: 1
			}
		}
	}

	return (
		<Section id="sequenceCat" ref={sectionRef}>
			<Container ref={containerRef}>
				<Parallax>
						{ww > 800 ?
							<MouseParallaxContainer
								className="parallax"
								containerStyles={{
									position: 'absolute',
									left:0,
									top:0,
									width: '100vw',
									height: '100vh',
									overflow: 'visible'
								}}
								resetOnLeave
							>
								<MouseParallaxChild
									factorX={-0.2}
									factorY={-0.08}
									updateStyles={layerStyle}
								>
									<Image style={{top: '18vh', left: '22vw', x: '-50%', y: '-50%', maxWidth: '444px', width: '16vw'}}
												 initial={{opacity: 0, zoom: 0.4, xPercent: -100, yPercent: -100}}
												 whileInView={{opacity: 1, zoom: 1, xPercent: -50, yPercent: -50}}
												 viewport={{once: false}}
												 transition={{duration: 2, delay: .1, ease: 'easeOut'}}
												 src={imgLampTL} alt=""/>
								</MouseParallaxChild>
								<MouseParallaxChild
									factorX={0.04}
									factorY={-0.05}
									updateStyles={layerStyle}
								>
									<Image style={{top: '18vh', right: '25vw', x: '50%', y: '-50%', maxWidth: '324px', width: '17vw'}}
												 initial={{opacity: 0, zoom: 0.4, xPercent: 100, yPercent: -100}}
												 whileInView={{opacity: 1, zoom: 1, xPercent: 50, yPercent: -50}}
												 viewport={{once: false}}
												 transition={{duration: 3, delay: .4, ease: 'easeOut'}}
												 src={imgLampTR} alt=""/>
								</MouseParallaxChild>
								<MouseParallaxChild
									factorX={0.2}
									factorY={0.1}
									updateStyles={layerStyle}
								>
									<Image style={{bottom: '38vh', left: '25vw', x: '-50%', y: '50%', maxWidth: '427px', width: '24vw'}}
												 initial={{opacity: 0, zoom: 0.4, xPercent: -100, yPercent: 100}}
												 whileInView={{opacity: 1, zoom: 1, xPercent: -50, yPercent: 50}}
												 viewport={{once: false}}
												 transition={{duration: 1, delay: .2, ease: 'easeOut'}}
												 src={imgLampBL} alt=""/>
								</MouseParallaxChild>
								<MouseParallaxChild
									factorX={0.1}
									factorY={0.08}
									updateStyles={layerStyle}
								>
									<Image style={{bottom: '38vh', right: '25vw', x: '50%', y: '50%', maxWidth: '457px', width: '27vw'}}
												 initial={{opacity: 0, zoom: 0.4, xPercent: 100, yPercent: 100}}
												 whileInView={{opacity: 1, zoom: 1, xPercent: 50, yPercent: 50}}
												 viewport={{once: false}}
												 transition={{duration: 4, delay: .3, ease: 'easeOut'}}
												 src={imgLampBR} alt=""/>
								</MouseParallaxChild>
							</MouseParallaxContainer>
							:
							<ParallaxInstead>
								<img src={pInstead} alt=""/>
							</ParallaxInstead>
						}
				</Parallax>
				<Top ref={topRef}>
					<TopInner ref={topInnerRef}>
						{ww > 800 ?
							<Sequence onReady={props.onReady} readyName={'cat'} slideList={sequenceListCat} container={'sequenceCat'}/>
							:
							<img src={seqInstead} alt=""/>
						}
					</TopInner>
				</Top>
				{ww > 800 ?
					<Bottom>
						<Description initial={{y: -200, opacity: 0}} whileInView={{y: 0, opacity: 1}} transition={{
							duration: 2,
							ease: 'easeOut'
						}}>{t('about.heroCat.number')}</Description>
						<ToyName variants={nameVariants} initial="hidden" whileInView="show">
							<motion.span variants={nameItemVariants}>{t('about.heroCat.name')[0]}</motion.span>
							<motion.span variants={nameItemVariants}>{t('about.heroCat.name')[1]}</motion.span>
							<motion.span variants={nameItemVariants}>{t('about.heroCat.name')[2]}</motion.span>
							<motion.span variants={nameItemVariants}>{t('about.heroCat.name')[3]}</motion.span>
							<motion.span variants={nameItemVariants}>{t('about.heroCat.name')[4]}</motion.span>
							<motion.span variants={nameItemVariants}>{t('about.heroCat.name')[5]}</motion.span>
							<motion.span variants={nameItemVariants}>{t('about.heroCat.name')[6]}</motion.span>
						</ToyName>
						<motion.p initial={{rotateX: -45, opacity: 0}} whileInView={{
							rotateX: 0,
							opacity: 1
						}} transition={{
							duration: 1,
							delay: 0.5,
							ease: 'easeOut'
						}}>{t('about.heroCat.description')}
						</motion.p>
					</Bottom>
					:
					<Bottom>
						<Description>{t('about.heroCat.number')}</Description>
						<ToyName>{t('about.heroCat.name')}</ToyName>
						<p>{t('about.heroCat.description')}</p>
					</Bottom>
				}
			</Container>
		</Section>
	)
}

export default Cat