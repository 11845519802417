import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {LazyLoadImage} from "react-lazy-load-image-component";

import "./index.css";
import axios from "axios";
import {API_HOST} from "../../data/data";
import {selectByCategory, selectSetLoadingByCategory} from "../../store/features/gearSelectorState";
import {useDispatch} from "react-redux";
const GearBackground = styled.div`
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
`

const CurrentImage = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: url(${props => props.bgi ? props.bgi : ''});
	background-size: cover;
	background-position:center;
	
	span {
		position: absolute;
		left: 0;
		top: 0;
		width: 100% !important;
		height: 100% !important;
	}
	
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

let cancel;

const FittingBackground = () => {
	const [curImgSrc, setCurImgSrc] = useState(null);
	const dispatch = useDispatch()

	useEffect(() => {
		document.addEventListener('gearSelect', gearSelectEvent)

		return () => {
			document.removeEventListener('gearSelect', gearSelectEvent)
		};
	}, []);

	const gearSelectEvent = async ({detail}) => {
		const {category, id, toy, image} = detail
		if(category === 'background'){
			if(id) {
				dispatch(selectSetLoadingByCategory({gear: category, loading: true}))
				source.cancel('Operation canceled by the user.');
				if (cancel !== undefined) {
					cancel();
				}
				await axios.post(API_HOST, {
					category: category,
					type: toy,
					size: 'large',
					model: id.toString(),
					color: "1"
				}, {
					cancelToken: new CancelToken(function executor(c) {
						cancel = c;
					}),
					headers: {"Content-type": "image/png"},
					responseType: 'blob',
				})
						.then(response => response.data)
						.then(data => {
							const img = new Image();
							img.src = URL.createObjectURL(data)
							img.onload = () => {
								dispatch(selectByCategory({gear: category, id, image: URL.createObjectURL(data)}))
								dispatch(selectSetLoadingByCategory({gear: category, loading: false}))
								setCurImgSrc(URL.createObjectURL(data))
							}
						})
						.catch((error) => {
							if (axios.isCancel(error)) {
								dispatch(selectSetLoadingByCategory({gear: category, loading: false}))
								console.log("post Request canceled");
							}
						});
			}
			else setCurImgSrc(null)

		}else if(category === 'all'){
			setCurImgSrc(null)
		}
	}

	const getBgImage = () => {
		return <LazyLoadImage
				effect="blur"
				width={720}
				height={864}
				src={curImgSrc} // use normal <img> attributes as props
		/>
	}

	return (
			<GearBackground>
				<CurrentImage>
					{curImgSrc && getBgImage()}
				</CurrentImage>
			</GearBackground>
	)
}

export default FittingBackground