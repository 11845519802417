import React, {useEffect, useRef} from "react";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {API_HOST, fittingData} from "../../data/data";
import {gsap} from "gsap/all";
import Flip from 'gsap/Flip'
import axios from "axios";
import {
	selectByCategory,
	selectSetLoadingByCategory,
	unselectByCategory
} from "../../store/features/Mint/gearSelectorState";
import {selectLoaded} from "../../store/features/toySelectorState";

const isMobile = window.innerWidth <= 800

const MainView = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	top: 0;
	width: 60%;
	pointer-events: none;
	display: flex;
	align-items: flex-end;
	
	@media (max-width: 800px) {
		display: flex;
		align-items: center;
		height: 100%;
		width: 100%;
	}
`
const MainViewImage = styled.div`
	width: 100%;
	height: 100%;
	max-width: 720px;
	position: relative;
	margin: 0 auto;
	max-height: 864px;
	
	> span {
		display: flex !important;
		align-items: flex-end;
		justify-content: center;
		position: relative;
		z-index: 5;
		max-width: 100%;
		max-height: 100%;
		height: 100% !important;
	}
	img {
		position: absolute;
		max-width: 100%;
		max-height: 100%;
		height: 100%;
		width: 100%;
		object-fit: contain;
		object-position: center bottom;
		margin: 0 auto;
	}
	
	@media (max-width: 800px) {
		display: flex;
		align-items: center;
		> span {
			align-items: center;
			img {
				height: auto !important;
				width: auto !important;
				object-fit: initial;
				border-bottom: 1px solid rgba(152, 152, 152, 0.2);
			}
		}
	}
`

const GearViewImage = styled.div`
	align-items: flex-end;
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 5;
	bottom: 0;
	left: 0;
	display: ${props => props.visible ? 'flex' : 'none'};
	
	> span {
		display: flex !important;
		align-items: flex-end;
		position: relative;
		max-width: 100%;
		height: 100% !important;
	}
	
	img {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		max-width: 100%;
		max-height: 100%;
		height: 100% !important;
		width: 100% !important;
		object-fit: contain;
		object-position: center bottom;
		margin: 0 auto;
		
		&.flipping {
			&:not(.active){
				object-fit: fill;
			}
		}
	}
	
	&.shirt {
		z-index: 7;
	}
	&.jacket {
		z-index: 8;
	}
	&.glasses {
		z-index: 8;
	}
	&.head {
		z-index: 8;
	}
	
	&.jewellery {
		z-index: 8;
	}
	&.special {
		z-index: 10;
	}
	&.body {
		z-index: 6;
	}
	&.special.two {
		z-index: 7;
	}
	
	&.ufo{
		width: 98%;
		height: 96%;
	}
	
	
	@media (max-width: 800px) {
		align-items: center;
		> span {
			align-items: center;
		}
		img {
			height: auto !important;
		}

		&.ufo{
			bottom: -9.42px;
			width: 98%;
			height: 100%;
		}
	}
`

const GearViewFlip = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 10;
	pointer-events: none;
	
	img {
		display: none;
	}
`



const CancelToken = axios.CancelToken;
const source = CancelToken.source();

let cancel;
const FittingView = () => {
	gsap.registerPlugin(Flip);
	const gearCount = useSelector((state) => state.gearCount.countData)
	const selectedToy = useSelector((state) => state.toySelector.toy)
	const selectedGear = useSelector((state) => state.gearMintSelector)
	const visibilityGear = useSelector((state) => state.gearMintVisibility)
	const selectedCategory = useSelector((state) => state.categoryMintSelector.category)
	const selectedAuth = useSelector((state) => state.modalSelector.auth)
	const dispatch = useDispatch()
	let countToLoad = 0;
	let countLoaded = 0;


	const refFlipImage = useRef();

	useEffect(() => {
		console.log("MOUNT BLOCK")
		document.addEventListener('gearSelect', gearSelectEvent)

		return () => {
			document.removeEventListener('gearSelect', gearSelectEvent)
		};
	}, []);

	useEffect(() => {
		countToLoad = 0;
		countLoaded = 0;
		let prevGear = document.querySelectorAll('.gear-image')
		if(prevGear.length > 0 && !isMobile) {
			prevGear.forEach(item => {
				animateOldGear('all', item)
			})
		}
		{Object.keys(selectedGear).map((key, i) => {
			if(key === 'background') return
			if(key == 'body' || key == 'character'){
				dispatch(unselectByCategory(key))
			}else {
				if (selectedGear[key].id) {
					if (gearCount[selectedToy][key] && gearCount[selectedToy][key] >= selectedGear[key].id) {
						countToLoad++
						console.log(selectedGear[key])
						animateGearOnToyChange(key,
							selectedGear[key].id,
							(selectedToy == 'cat') ? selectedGear[key].image.replace("ufo", "cat") : selectedGear[key].image.replace("cat", "ufo"),
							selectedGear[key].item)
					} else {
						if (isMobile) {
							dispatch(selectByCategory({
								gear: key,
								id: selectedGear[key].id,
								image: null,
								item: selectedGear[key].item
							}))
						}
					}
				} else {
					//console.log("СЫНЬ ВЫНЬ")
					/*if(!isMobile) {
                        animateGear(key, null)
                    }*/
				}
			}
		})}

		if(countToLoad === 0){
			dispatch(selectLoaded())
		}
	}, [selectedToy]);

	const animateGearOnToyChange = async (category, id, image, item, animate = true) => {
		/*const data = {
			category: category,
			type: selectedToy,
			size: 'medium',
			model: id.toString(),
			color: "1"
		}

		try {
			await fetch(API_HOST, {
				method: 'post',
				body: JSON.stringify(data),
				headers: {"Content-type": "image/png"},
			}, )
					.then(response => {
						return response.blob()
					})
					.then(data => {
						if(data.size > 0){
							dispatch(selectByCategory({gear: category, id, image: URL.createObjectURL(data)}))
							const img = new Image();
							img.src = URL.createObjectURL(data)
							//img.src = "https://server.tonguys.org/nfts/items/cat/skin_2903.png"
							img.onload = () => {
								countLoaded++
								if(!isMobile && animate){
									animateGear(category, img, true)
								}
								if(countLoaded >= countToLoad){
									dispatch(selectLoaded())
								}
							}
						}else{
							countToLoad--
							if(countLoaded >= countToLoad){
								dispatch(selectLoaded())
							}
							if(!isMobile) {
								animateGear(category, null)
							}
						}
					})
		} catch(err) {
			if (err.name === 'AbortError') { // обработать ошибку от вызова abort()
				console.log("Прервано!");
			} else {
				throw err;
			}
		}*/
		dispatch(selectByCategory({gear: category, id, image, item}))
		const img = new Image();
		img.src = image
		//img.src = "https://server.tonguys.org/nfts/items/cat/skin_2903.png"
		img.onload = () => {
			countLoaded++
			if(!isMobile && animate){
				animateGear(category, img, true)
			}
			if(countLoaded >= countToLoad){
				dispatch(selectLoaded())
			}
		}


	}

	const gearSelectEvent = ({detail}) => {
		console.log("Должно сработать")
		const {category, id, toy, item, image} = detail
		console.log(detail)

		if(category !== 'background'){
			if(id) {
				beforeAnimate(category, id, image, item, toy)
				dispatch(selectSetLoadingByCategory({gear: category, loading: true}))
			}
			else if(!isMobile) animateGear(category, null)
		}
	}

	/*const beforeAnimate = async (category, id, toy = "cat") => {
		source.cancel('Operation canceled by the user.');
		if (cancel !== undefined) {
			cancel();
		}
		await axios.post(API_HOST, {
			category: 'shirt',
			type: toy,
			size: 'medium',
			model: id.toString(),
			color: "1"
		}, {
			cancelToken: new CancelToken(function executor(c) {
				cancel = c;
			}),
			headers: {"Content-type": "image/png"},
			responseType: 'blob',
		})
				.then(response => response.data)
				.then(data => {
					dispatch(selectByCategory({gear: category, id, image: URL.createObjectURL(data)}))
					if(!isMobile){
						const img = new Image();
						img.src = URL.createObjectURL(data)
						//img.src = "https://server.tonguys.org/nfts/items/cat/skin_2903.png"
						//img.src = "https://cache.tonapi.io/imgproxy/q7oUhRNV7jVdTL0mxIFecKP7hxyAZHr1f5vCZcXaKHs/rs:fill:1500:1500:1/g:no/aHR0cHM6Ly9zZXJ2ZXIudG9uZ3V5cy5vcmcvbmZ0cy9pdGVtcy9zaGlydDlfOS5wbmc.webp"
						img.onload = () => {
							dispatch(selectSetLoadingByCategory({gear: category, loading: false}))
							animateGear(category, img)
						}
					}else{
						dispatch(selectSetLoadingByCategory({gear: category, loading: false}))
					}
				})
				.catch((error) => {
					if (axios.isCancel(error)) {
						dispatch(selectSetLoadingByCategory({gear: category, loading: false}))
						console.log("post Request canceled");
					}
				});
	}*/

	const beforeAnimate = async (category, id, image, item, toy = "cat") => {
		source.cancel('Operation canceled by the user.');
		if (cancel !== undefined) {
			cancel();
		}

		dispatch(selectByCategory({gear: category, id, image, item}))
		if(!isMobile){
			const img = new Image();
			//img.src = URL.createObjectURL(data)
			//img.src = "https://server.tonguys.org/nfts/items/cat/skin_2903.png"
			img.src = image
			//img.src = "https://cache.tonapi.io/imgproxy/q7oUhRNV7jVdTL0mxIFecKP7hxyAZHr1f5vCZcXaKHs/rs:fill:1500:1500:1/g:no/aHR0cHM6Ly9zZXJ2ZXIudG9uZ3V5cy5vcmcvbmZ0cy9pdGVtcy9zaGlydDlfOS5wbmc.webp"
			img.onload = () => {
				dispatch(selectSetLoadingByCategory({gear: category, loading: false}))
				animateGear(category, img)
			}
		}else{
			dispatch(selectSetLoadingByCategory({gear: category, loading: false}))
		}
	}


	const animateGear = (category, img, toyChange = false) => {
		let prevGear
		let flipCtn
		if(category !== 'all') {
			flipCtn = document.querySelector(`#flip-view-${category}`)
			prevGear = flipCtn.children[0]
			if (prevGear) animateOldGear(category, prevGear)
		}else{
			prevGear = document.querySelectorAll('.gear-image')
			if(prevGear.length > 0) {
				prevGear.forEach(item => {
					animateOldGear(category, item)
				})
			}
		}

		if(flipCtn && img) {
			if(toyChange){
				flipCtn.prepend(img)
				const newGear = flipCtn.children[0]
				newGear.style.display = 'block'
				newGear.classList.add('gear-image')

				let gearEasing = "back.in(1.1)"

				gsap.from(newGear, {
					opacity: 0,
					duration: 0.6,
					ease: gearEasing,
				});
			}else {
				flipCtn.prepend(img)
				const newGear = flipCtn.children[0]
				newGear.style.display = 'block'
				newGear.setAttribute('data-flip-id', `flip-view-${category}`)
				newGear.classList.add('gear-image')

				const state = Flip.getState('.gear-card.active');
				let gearEasing = "back.in(1.1)"

				if (category === 'glasses') gearEasing = 'elastic.out(.8, 0.8)'
				if (category === 'jewellery') gearEasing = 'elastic.out(0.4, 0.6)'
				if (category === 'head') gearEasing = 'elastic.out(.8, 1.5)'
				if (category === 'special') gearEasing = 'elastic.out(.8, 0.4)'

				setTimeout(() => {
					newGear.classList.add('active')
				}, 300)

				Flip.from(state, {
					duration: 0.6,
					targets: newGear,
					absolute: true,
					scale: true,
					toggleClass: 'flipping',
					ease: gearEasing,
				});
			}
		}

	}

	const animateOldGear = (category, prevGear) => {
		let gearEasing = "back.in(1.1)"

		if(category === 'glasses') gearEasing = 'elastic.out(.5, 1)'
		if(category === 'jewellery') gearEasing = 'elastic.out(.5, 1)'
		if(category === 'head') gearEasing = 'elastic.out(.5, 1)'
		if(category === 'special') gearEasing = 'elastic.out(.5, 1)'
		gsap.to(prevGear, {
			left: -500,
			opacity: 0,
			duration: gsap.utils.random(0.3, 0.6),
			delay: gsap.utils.random(0, 0.1),
			onComplete: () => prevGear.remove(),
			ease: gearEasing,
		})
	}


	const getMainToyImage = () => {
		return <LazyLoadImage
				effect="blur"
				width={720}
				height={864}
				src={fittingData[selectedToy].image} // use normal <img> attributes as props
		/>
	}
	//https://server.tonguys.org/nfts/items/transparent_bodies/2322.png
	const getGearImage = (category) => {
		if(selectedGear[category].image){
			return <LazyLoadImage
					effect="blur"
					width={720}
					height={864}
					src={selectedGear[category].image} // use normal <img> attributes as props
			/>
		}else{
			return false
		}
	}
	//https://server.tonguys.org/nfts/items/transparent_bodies/2322.png
	return (
			<MainView>
				<MainViewImage>
					{selectedToy === 'cat' && !selectedGear['body'].id && !selectedGear['character'].id && getMainToyImage()}
					{selectedToy === 'ufo' && !selectedGear['body'].id && !selectedGear['character'].id && getMainToyImage()}
					{Object.keys(selectedGear).map((key, i) => {
						if(key === 'background') return
						let additionalClass = key
						if(key === 'special' && selectedGear[key].id === 2) additionalClass += ' two'
						if(selectedToy === 'ufo' && key !=='body' && key !=='character') additionalClass += ' ufo'
						return (
								<GearViewImage
										className={additionalClass}
										visible={visibilityGear[key]}
										id={`flip-view-${key}`}
										key={`gear-view-${i}`}>
										{(isMobile) && getGearImage(key)}
								</GearViewImage>
						)
					})}
					<GearViewFlip ref={refFlipImage} data-flip-id={'flip-img'}/>
				</MainViewImage>
			</MainView>
	)
}

export default FittingView