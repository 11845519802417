import { createSlice } from '@reduxjs/toolkit'

export const gearVisibilitySlice = createSlice({
	name: 'gearVisibility',
	initialState: {
		shirt: true,
		jacket: true,
		head: true,
		glasses: true,
		jewellery: true,
		background: true,
		special: true
	},
	reducers: {
		setVisibilityByCategory: (state, action) => {
			state[action.payload] = true
		},
		unsetVisibilityByCategory: (state, action) => {
			state[action.payload] = false
		},
		unsetAllVisibility: (state) => {
			state.shirt = false
			state.jacket = false
			state.head = false
			state.glasses = false
			state.jewellery = false
			state.background = false
			state.special = false
		}
	},
})

// Action creators are generated for each case reducer function
export const {
	setVisibilityByCategory,
	unsetVisibilityByCategory,
	unsetAllVisibility
} = gearVisibilitySlice.actions

export default gearVisibilitySlice.reducer