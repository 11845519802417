import React from 'react'
import styled from 'styled-components'

const Title = styled.h1`
	font-family: 'Bebas Neue';
	font-weight: 700;
	font-size: 100px;
	line-height: 94%;
	text-align: center;
	
	@media (max-width: 800px) {
		font-weight: 700;
		font-size: 60px;
		line-height: 94%;
	}
`

const PageTitle = (props) => {

	return (
		<Title>
			{props.title}
		</Title>
	)
}

export default PageTitle