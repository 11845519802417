import { createSlice } from '@reduxjs/toolkit'

export const modalSelectorSlice = createSlice({
	name: 'modalSelector',
	initialState: {
		modal: 'main',
		loading: false,
		link: '',
		error: false,
		auth: false
	},
	reducers: {
		selectTopkeeper: (state, action) => {
			state.modal = 'tonkeeper'
			state.loading = true
		},
		selectTophub: (state, action) => {
			state.modal = 'tonhub'
			state.loading = true
		},
		selectOther: (state, action) => {
			state.modal = 'other'
			state.loading = true
		},
		selectMain: (state, action) => {
			state.modal = 'main'
			state.loading = true
		},
		selectPreMint: (state, action) => {
			state.modal = 'premint'
			state.loading = true
		},
		selectMint: (state, action) => {
			state.modal = 'mint'
			state.loading = true
		},
		selectContinueMint: (state, action) => {
			state.modal = 'continuemint'
			state.loading = true
		},
		selectContinueMintItem: (state, action) => {
			state.modal = 'continueMintItem'
			state.loading = true
		},
		selectHistory: (state, action) => {
			state.modal = 'history'
			state.loading = true
		},
		selectRemint: (state, action) => {
			state.modal = 'remint'
			state.loading = true
		},
		selectRemintStart: (state, action) => {
			state.modal = 'remintStart'
			state.loading = true
		},
		selectError: (state, action) => {
			state.modal = 'error'
			state.error = action.payload
			state.loading = true
		},
		selectWarn: (state) => {
			state.modal = 'warn'
			//state.error = action.payload
			state.loading = true
		},
		selectQR: (state, action) => {
			state.modal = 'qr'
			state.link = action.payload
			state.loading = true
		},
		selectSuccess: (state, action) => {
			state.modal = 'success'
			state.text = action.payload
			state.loading = true
		},
		selectPreDecompose: (state, action) => {
			state.modal = 'predecompose'
			state.loading = true
		},
		selectDecompose: (state, action) => {
			state.modal = 'decompose'
			state.loading = true
		},
		selectLoaded: (state) => {
			state.loading = false
		},
		selectAuth: (state) => {
			state.auth = true
		},
		selectUnAuth: (state) => {
			state.auth = false
		}
	},
})

// Action creators are generated for each case reducer function
export const {
	selectTopkeeper,
	selectTophub,
	selectOther,
	selectMain,
	selectContinueMint,
	selectContinueMintItem,
	selectLoaded,
	selectAuth,
	selectUnAuth,
	selectPreMint,
	selectMint,
	selectHistory,
	selectRemint,
	selectRemintStart,
	selectError,
	selectWarn,
	selectQR,
	selectSuccess,
	selectPreDecompose,
	selectDecompose} = modalSelectorSlice.actions

export default modalSelectorSlice.reducer