import axios from "axios";
import {apiServer} from "../data/data";
import {token} from "../data/data";

export default class Mint{
    static async getAllNfts(wallet){
        const data = await axios.get(apiServer+ `/api/get_all_nfts/?key=${token}&wallet_address=${wallet}`)
        return data.data
    }

    /*body - Адрес тела,
      userWallet - Кошелек пользователя
      nftItemsAddresses - массив с нфт вещей
     */
    static async dressUpRequest(formData){
        const data = await axios.post(apiServer+ `/api/dress_up_request/?key=${token}`,formData,{
            headers: {
                'Content-Type': `application/x-www-form-urlencoded`,
            }
        })
        return data.data
    }

    static createMintLink(nftItemAddress, newOwnerAddress, amount, forwardAmount, text){
        let response = `https://app.tonkeeper.com/v1/txrequest-url/server.biom.app/ton_links/?nftItemAddress=${nftItemAddress}&newOwnerAddress=${newOwnerAddress}&amount=${amount}&forwardAmount=${forwardAmount}&text=${text}`
        return response
    }

    static async undressUpRequest(formData){
        const data = await axios.post(apiServer + `/api/undress_request/?key=${token}`,formData,{
            headers: {
                'Content-Type': `application/x-www-form-urlencoded`,
            }
        })
        return data.data
    }

    //Метод предназначен для получения комиссии за разминт
    static async getRemintComissions(dress_up_request_id){
        const data = await axios.get(apiServer + `/api/get_remint_transaction_amount/?key=${token}&dress_up_request_id=${dress_up_request_id}`)
        return data.data
    }

    //Метод предназначен для начала разминта
    static async remintRequest(formData){
        const data = await axios.post(apiServer + `/api/remint_request/?key=${token}`, formData,{
            headers: {
                'Content-Type': `application/x-www-form-urlencoded`,
            }
        })
        return data.data
    }

    static async getHistory(wallet){
        const data = await axios.get(apiServer + `/api/get_operation_history/?key=${token}&wallet_address=${wallet}`)
        return data.data
    }

    static async getHistoryFlags(wallet){
        const data = await axios.get(apiServer + `/api/get_flags/?key=${token}&wallet_address=${wallet}`)
        return data.data
    }


}