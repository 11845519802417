import React from 'react';
import styled, {css} from "styled-components";

const Rarity = (props) => {
    return (
        <Flex {...props}>
            <span>{props.text}</span>
        </Flex>
    );
};

export default Rarity;

const Flex = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 10px;
    gap: 2px;

    border-radius: 8px;
    
    ${props => props.classic && css`
      background: rgba(0, 0, 0, 0.7);
    `}

    ${props => props.secret && css`
      background: linear-gradient(90deg, rgba(48, 67, 115, 0.7) 0%, rgba(66, 92, 230, 0.7) 98.5%);
    `}
    
    ${props => props.mythic && css`
      background: linear-gradient(90deg, rgba(243, 58, 51, 0.7) -33.33%, rgba(239, 42, 220, 0.7) 111.4%);
    `}

    ${props => props.legendary && css`
      background: linear-gradient(90deg, rgba(94, 46, 216, 0.7) 0%, rgba(160, 69, 226, 0.7) 100%);
    `}
    
    span{
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-size: 12px;
      line-height: 14px;
      /* identical to box height */

      color: #FFFFFF;
    }
    `