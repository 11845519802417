import {createGlobalStyle} from "styled-components";
import "@fontsource/roboto"
import "@fontsource/roboto/cyrillic.css"
import "@fontsource/roboto/latin.css"
import BebasWoff from '../assets/Fonts/bebas_neue-webfont.woff'
import BebasWoff2 from '../assets/Fonts/bebas_neue-webfont.woff2'
import {isBrowser, isMobile } from 'react-device-detect';

const NoScrollStyles = createGlobalStyle`
	html, body {
		min-height: 100%;
		height: 100%;
	}
	.c-scrollbar {
		display: none;
	}
	.App {
		transform: none !important;
		height: 100%;
		min-height: 100%;
	}
	#root {
		height: 100%;
		min-height: 100%;
	}
`

const GlobalStyles = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
    font-family: 'Bebas Neue';
    src: url('${BebasWoff2}') format('woff2'),
         url('${BebasWoff}') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
	font-family: 'Roboto', sans-serif;
	font-weight: normal;
	font-style: normal;
}

*, *::before, *::after{
	margin:0;
	padding:0;
	
	box-sizing: border-box;
}

body {
	font-family: 'Bebas Neue', sans-serif;
	overflow-x: hidden;
	background-color: #000;
	color: #fff;
}

h1,h2,h3,h4,h5,h6{
	font-family: 'Bebas Neue', sans-serif;
	margin:0;
	padding:0;
}

a {
	color: inherit;
	text-decoration: none;
}
.c-scrollbar_thumb{
	background-color: #fff;
}
.App {
	/*overflow-x: hidden;*/
}
${isBrowser && '' +
	'.App {overflow-y: visible !important;}'}

${isMobile && '' +
'.App {overflow-x: hidden;}'}

`

export {
	GlobalStyles,
	NoScrollStyles
}