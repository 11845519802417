import React from 'react'
import styled from 'styled-components'
import {motion, useMotionValue, useTransform} from "framer-motion";

import imgLogo from '../assets/Images/home_logo-min.png'

const Section = styled(motion.section)`
position: absolute;
height: 100vh;
width: 100vw;
top: 0;
left: 0;
right: 0;
bottom: 0;
z-index: 100000;
touch-action: none;

background-color: ${props => props.theme.colors.background}
`

const Logo = styled(motion.div)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	
	img {
		display: block;
		filter: grayscale(100%);
		max-width: 100%;
	}	
	
	@media (max-width: 800px) {
		width: 218px;
	}
`

const Fill = styled(motion.div)`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 0;
	background-image: url(${imgLogo});
	background-size: auto 100%;
	transition: width 1s 0s ease-out;
	@media (max-width: 800px) {
		background-size: 218px auto;
	}
`


const logoAnim = {
	initial: {
		opacity: 0,
		zoom: 0.7
	},
	show: {
		opacity: 1,
		zoom: 1,
		transition: {
			duration: 1,
			ease: 'easeOut'
		}
	}
}

const Loading = (props) => {

	const fillPercent = useMotionValue(0)
	const input = [0, 50, 100]
	const output = ['0%', '50%', '100%']
	const width = useTransform(fillPercent, input, output)
	fillPercent.set(props.percent)

	return (
		<Section>
			<Logo variants={logoAnim} initial="initial" animate="show">
				<img src={imgLogo} alt="Logo"/>
				<Fill style={{fillPercent, width}}/>
			</Logo>
		</Section>
	)
}

export default Loading