import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import img_copy from "../../assets/Images/copy.png";
import img_copy_blue from "../../assets/Images/copy_blue.png";
import image_tophub from "../../assets/Images/tophub.png";
import {selectTopkeeper, selectMain, selectOther, selectTophub, selectAuth} from "../../store/features/modalSelectorState";
import {useDispatch, useSelector} from "react-redux";
import useWebSocket, {ReadyState} from "react-use-websocket";
import auth from "../../api/Auth";
import Cookies from "universal-cookie";
import {ShimmerThumbnail} from "react-shimmer-effects";
import Loader from "../Loader";
import {useTranslation} from "react-i18next";





const OtherAdress = styled.div`
  position: absolute;
  width: 502px;
  height: 501px;
  left: calc(50% - 502px/2);
  top: calc(50% - 501px/2 - 34.5px);

  background: #161616;
  border-radius: 22px;
  z-index: 15;

  @media (max-width: 800px) {
    position: relative;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    border-radius: 0px;
  }
`

const TextModal = styled.div`
  position: absolute;
  
  left: calc(50% - 141px/2 - 0.5px);
  top: 40px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  /* identical to box height */

  text-align: center;

  color: #FFFFFF;
  
  @media (max-width: 800px) {
    width: 101px;
    height: 23px;
    left: calc(50% - 101px/2 + 0.5px);
    top: calc(50% - 23px/2 - 177.5px);

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    text-align: center;

    color: #FFFFFF;
    
  }
  
`

const TextDescription = styled.div`
  position: absolute;
  width: 420px;
  height: 72px;
  left: calc(50% - 420px/2);
  top: 93px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */

  text-align: center;

  color: #FFFFFF;

  opacity: 0.5;

  @media (max-width: 800px) {
    position: absolute;
    width: 320px;
    height: 120px;
    left: calc(50% - 320px/2);
    top: calc(50% - 120px/2 - 86px);

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */

    text-align: center;

    color: #FFFFFF;

    opacity: 0.5;

  }
`

const InputGroup = styled.div`
  position: absolute;
  width: 422px;
  height: 160px;
  left: calc(50% - 422px/2);
  bottom: 136px;

  @media (max-width: 800px) {
    left: calc(50% - 320px/2);
  }
  
`

const LabelText = styled.div`
    position: absolute;
    font-family: 'Roboto';
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */
    color: #FFFFFF;
`

const FirstLabel = styled(LabelText)`
  left: 16px;
  bottom: 146px;
`

const TwoLabel = styled(LabelText)`
  left: 16px;
  bottom: 58px;
`
const ThreeLabel = styled(LabelText)`
  left: 237px;
  bottom: 58px;
`

const CopyInput = styled.div`
  position: absolute;
  width: 422px;
  height: 48px;
  left: calc(50% - 422px/2);
  bottom: 88px;

  background: #222222;
  border-radius: 12px;
  
  span{
    position: absolute;
    width: 135px;
    height: 16px;
    left: calc(50% - 135px/2 + 0.5px);
    top: calc(50% - 16px/2);

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    opacity: 0.5;

    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  :hover{
    background: #303030;
    span{
      color: #207AF0;
      opacity: 1;
    }
    div{
      background-image: url(${img_copy_blue});
    }
  }
 
  
  div{
    position: absolute;
    background: url(${img_copy});
    background-size: contain;
    width: 16px;
    height: 16px;
    right: 16px;
    top: calc(50% - 16px/2);

    border:none;
    outline: none;
    
  }

  @media (max-width: 800px) {
    width: 320px;
    height: 48px;
  }
`

const CopyInputSumm = styled(CopyInput)`
  width: 201px;
  height: 48px;
  left: 0px;
  bottom: 0px;
  span{
    left: calc(50% - 36px/2 + 0.5px);
    top: calc(50% - 16px/2);
  }
  
  @media (max-width: 800px) {
    width: 150px;
    height: 48px;
  }
`

const CopyInputComment = styled(CopyInput)`
  
  width: 201px;
  height: 48px;
  left: 221px;
  bottom: 0px;
  span{
    overflow: hidden;
    text-overflow: ellipsis;
    left: calc(50% - 97px/2 - 10px);
    top: calc(50% - 16px/2);
  }

  @media (max-width: 800px) {
    left: 170px;
    width: 150px;
    height: 48px;
  }
`
const ModalButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 10px;
  gap: 10px;

  position: absolute;
  width: 422px;
  height: 56px;
  left: calc(50% - 422px/2);

  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  img{
    width: 19.74px;
    height: 20px;
  }
  :hover{
    background: rgba(255, 255, 255, 0.3);
  }
  @media (max-width: 800px) {
    width: 320px;
    height: 56px;
    left: calc(50% - 320px/2);
  }
`
const LinkOther = styled.div`
  position: absolute;
  width: 90px;
  height: 16px;
  left: calc(50% - 422px/2);
  bottom: 20px;

  font-family: 'Roboto';
  font-style: normal;
  font-size: 14px;
  line-height: 16px;

  color: red;
`



const ModalOtherAdress = (props) => {
    const [loaded, setLoaded] = useState(false)
    const {t} = useTranslation();
    const selectedModal = useSelector((state) => state.modalSelector.modal)
    const selectorLoading = useSelector((state) => state.modalSelector.modal)
    const selectedAuth = useSelector((state) => state.modalSelector.auth)
    const dispatch = useDispatch()

    const [adress, setAdress] = useState("Адрес")
    const [summ, setSumm] = useState(0)
    const [comment, setComment] = useState("Коммент")

    const cookies = new Cookies();

    const [socketUrl, setSocketUrl] = useState(null);
    const [session_key, setSessionKey] = useState(null)
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl,{
        share: true,
        shouldReconnect: () => false,
        /*shouldReconnect: (closeEvent) => {
            /*
            useWebSocket will handle unmounting for you, but this is an example of a
            case in which you would not want it to automatically reconnect

            return didUnmount.current === false;
        },
        reconnectAttempts: 10,
        reconnectInterval: 3000,*/
    });

    const [reasonNotAuth, setReasonNotAuth] = useState(null)

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];


    useEffect(()=>{
        console.log(socketUrl)
        console.log(connectionStatus)
    }, [socketUrl])

    //Создание сессии и подключение к вебсокету
    useEffect(()=>{
        async function createSess() {
            await auth.signInRequest().then(data => {
                //ws = new WebSocket(`ws://51.250.103.240:8030/ws/${data.session_key}?subscribe-broadcast`)
                if(data.success) {
                    setSocketUrl(`ws://51.250.103.240:8040${data.websocket_url}`)
                    setAdress(data.required_transaction.wallet)
                    setSumm(data.required_transaction.amount)
                    setComment(data.required_transaction.comment)

                    setLoaded(true)

                    console.log(socketUrl)
                }else{

                }
            })
        }
        createSess()


    }, [])
    //Авторизация пользователя после того как пришла оплата
    useEffect(()=>{
        console.log(lastMessage)
        //console.log(dataBack.args.current_states.user_ton_wallet)
        if(lastMessage !== null) {
            let dataJson = JSON.parse(lastMessage.data)
            console.log(dataJson)
            if (dataJson.type === "sign_in_request_success") {
                props.setUserWallet(dataJson.args.current_states.user_ton_wallet)
                cookies.set("wallet", dataJson.args.current_states.user_ton_wallet, { path: '/' })
                dispatch(selectAuth())
            }else if(dataJson.type === "sign_in_request_error"){
                setReasonNotAuth(dataJson.args.reason)
            }
        }
    }, [lastMessage])

    return (
        <OtherAdress>
            {!loaded && <Loader/>}
            <TextModal>
                Ваш адрес
            </TextModal>
            <TextDescription>
                Если вы будете отправлять самостоятельно - важно полное совпадение всех данных, представленных ниже, включая комментарий
            </TextDescription>
            <InputGroup>
                <FirstLabel className="first">Адрес</FirstLabel>
                <CopyInput onClick={() => {navigator.clipboard.writeText(adress)}}>
                    <span>{adress}</span>
                    <div/>
                </CopyInput>

                <TwoLabel className="two">Сумма</TwoLabel>
                <CopyInputSumm onClick={() => {navigator.clipboard.writeText(summ)}}>
                    <span>{summ}</span>
                    <div/>
                </CopyInputSumm>

                <ThreeLabel className="three">Комментарий</ThreeLabel>
                <CopyInputComment onClick={() => {navigator.clipboard.writeText(comment)}}>
                    <span>{comment}</span>
                    <div/>
                </CopyInputComment>
            </InputGroup>

            <ModalButtonDiv style={{bottom: "40px"}} onClick={()=>{
                dispatch(selectMain())
            }}>
                Назад
            </ModalButtonDiv>

            {reasonNotAuth!==null && <LinkOther>
                Error: {t(`mintpage.${reasonNotAuth}`)}
            </LinkOther>}

        </OtherAdress>
    );
};

export default ModalOtherAdress;