import React, {useLayoutEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player'
import {gsap, ScrollTrigger} from "gsap/all";

import imgPlay from '../assets/Images/playVideo-min.png'
import imgPreview from '../assets/Images/videoPreview-min.jpg'
import {motion} from "framer-motion";

const Container = styled.div`
	position: relative;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
	border-radius: 32px;
	margin-top: 40px;
	width: 100%;
`

const Inner = styled.div`
	position: relative;
	padding-top: 56%;
	height: 0;
	
	iframe {
		position: absolute;
		left:0;
		top:0;
		width: 100%;
		height: 100%;
	}
	
	@media (max-width: 800px) {
		min-height: 456px;
	}
	
`
const VideoPreview = styled.div`
	position: absolute;
	left:0;
	top:0;
	width: 100%;
	height: 100%;
	background-image: url('${imgPreview}');	
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 32px;
	overflow: hidden;
	
	:before {
		content: '';
		position: absolute;
		left:0;
		top:0;
		width: 100%;
		height: 100%;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
	}
	
	@media (max-width: 800px) {
		border-radius: 16px;
	}
`
const PlayButton = styled(motion.div)`
	position: absolute;
	left:50%;
	top: 50%;
	cursor: pointer;
	margin: -116px 0 0 -116px;
	z-index: 5;
	width: 232px;
	height: 232px;
	
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		pointer-events: none;
	}
	
	@media (max-width: 800px) {
		margin: -92px 0 0 -92px;
		z-index: 5;
		width: 185px;
		height: 185px;
	}
`

const UniverseVideo = (props) => {
	const [playVideo, setPlayVideo] = useState(false);
	const containerRef = useRef(null);

	useLayoutEffect(() => {
		let element = document.querySelectorAll('#universe');
		let t1 = gsap.timeline()

		setTimeout(() => {
			t1.to(containerRef.current, {
				scrollTrigger: {
					trigger: element,
					start: 'top top',
					end: '-10% top',
					scroller: '.App',
					scrub: true,
				},
				onUpdate: () => {
					setPlayVideo(false)
				},
				ease: 'none'
			})
			t1.to(containerRef.current, {
				scrollTrigger: {
					trigger: element,
					start: '80% top',
					end: '90% top',
					scroller: '.App',
					scrub: true,
				},
				onComplete: () => {
					setPlayVideo(false)
				},
				ease: 'none'
			})


			ScrollTrigger.refresh()
		}, 1000)


	}, []);

	return (
		<Container ref={containerRef}>
			<Inner>
				{!playVideo &&
						<VideoPreview>
							<PlayButton onClick={() => setPlayVideo(true)} whileHover={{ scale: 1.2 }}>
								<img src={imgPlay} alt=""/>
							</PlayButton>
						</VideoPreview>
				}
				{playVideo &&
					<ReactPlayer volume={playVideo ? '1' : '0'} playing={playVideo} controls={true}  url={`https://www.youtube.com/watch?v=${props.videoId}`}/>
				}
			</Inner>
		</Container>
		/*<YouTube videoId={props.videoId} onReady={onVideoReady} />*/
	)
}

export default UniverseVideo