import React, {useEffect, useLayoutEffect, useRef} from 'react'
import styled from 'styled-components'
import {motion} from "framer-motion";
import {gsap} from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger'
import {useTranslation} from "react-i18next";

import {cardsData} from "../data/data";

let ww = window.innerWidth

const Section = styled.section`
	position: relative;
	width: 100vw;
	height: 200vh;
	margin-top:-200vh;
	
	@media (max-width: 800px) {
		height: auto;
		margin-top: 0;
	}
`

const Container = styled.div`
	position: relative;
	top:0;
	left:0;
	width: 100vw;
	height: 100vh;
	opacity: 0;
	transform: scale(5);
	
	z-index: 5;
	
	@media (max-width: 800px) {
		transform: none;
		z-index: 2;
		opacity: 1;
		height: auto;
		min-height: 100vh;
	}
`

const Inner = styled(motion.div)`
	position: relative;
	display: flex;
	flex-wrap: wrap;
	width: 1450px;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	justify-content: center;
	gap: 13px;
	padding: 100px 40px;
	
	@media (max-width: 800px) {
		top: 0;
		width: 870px;
		transform: translate(-50%, 0);
		gap: 7px;
	}
`

const Card = styled(motion.div)`
	width: 217px;
	background: #171717;
	border-radius: 13.6454px;
	
	overflow: hidden;
	
	img {
		display: block;
		max-width: 100%;
		pointer-events: none;
		backface-visibility: visible;
	}
	
	.bottom {
		padding: 20px;
		display: flex;
		justify-content: space-between;
		
		font-size: 16.3745px;
		line-height: 19px;
	}
	
	.name {
		color: ${props => props.theme.colors.gold};
	}
	
	@media (max-width: 800px) {
		width: 112px;
		border-radius: 7.00199px;
		
		.bottom{
			padding: 10px 11px;
			font-size: 8.40239px;
			line-height: 10px;
		}
	}
`

const CardList = (props) => {
	gsap.registerPlugin(ScrollTrigger);
	const { t } = useTranslation();

	const sectionRef = useRef(null);
	const containerRef = useRef(null);
	const innerRef = useRef(null);

	useEffect(() => {
		setTimeout(() => {
			props.onReady('cardList')
		}, 300)
	}, []);

	useLayoutEffect(() => {
		let element = sectionRef.current;
		let t1 = gsap.timeline()

		{ww > 800 &&
			setTimeout(() => {
				t1.to(element, {
					scrollTrigger: {
						trigger: element,
						start: 'top top',
						end: 'bottom bottom',
						scroller: '.App',
						pin: containerRef.current,
						anticipatePin: true,
						scrub: true,
					},
					ease: 'none',
				})
				t1.to(containerRef.current, {
					scrollTrigger: {
						trigger: element,
						start: 'top top',
						end: '35% top',
						scroller: '.App',
						scrub: true,
					},
					opacity: 1,
					duration: 10,
					ease: 'none'
				})

				t1.to(containerRef.current, {
					scrollTrigger: {
						trigger: element,
						start: 'top top',
						end: '35% top',
						scroller: '.App',
						scrub: true,
					},
					scale: 1,
					duration: 10,
					ease: 'none'
				})

				t1.to(innerRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '85% bottom',
						end: 'bottom bottom',
						scroller: '.App',
						scrub: true,
					},
					opacity: 0,
					ease: 'none'
				})

				t1.to(containerRef.current, {
					scrollTrigger: {
						trigger: element,
						start: 'bottom bottom',
						end: '+=1% bottom',
						scroller: '.App',
						scrub: true,
					},
					display: 'none',
					ease: 'none'
				})

				ScrollTrigger.refresh()
			}, 1000)
		}


	}, []);

	return (
		<Section ref={sectionRef}>
			<Container ref={containerRef}>
				<Inner ref={innerRef}>
					{cardsData.map(({name, number, img,}, index) => {
						return (
							<Card key={`${index}-${number}-${name}`}>
								<div className="img">
									<img src={img} alt=""/>
								</div>
								<div className="bottom">
									<div className="name">{t(name)}</div>
									<div className="id">#{number}</div>
								</div>
							</Card>
						)
					})}
				</Inner>
			</Container>
		</Section>
	)
}

export default CardList