import {selectError} from "../store/features/modalSelectorState";
import {useDispatch} from "react-redux";

export default function errorHandler(error, dispatch){

    switch (error.code) {
        case 5001:
            let msg = ''
            if (error.error.data_invalid.nft_items_addresses_to_dress_up)
                msg += "mintpage.error.no_item"
            if (error.error.data_invalid.body_address)
                msg += "mintpage.error.no_body"
            if (error.error.data_invalid.user_ton_wallet)
                msg += "Неправильный кошелек\n"
            dispatch(selectError(msg))
            break;
        case 5101:
            dispatch(selectError('mintpage.error.5101'))
            break;
        case 5104:
            dispatch(selectError('mintpage.error.5104'))
            break;
        case 5102:
            dispatch(selectError('mintpage.error.5102'))
            break;
        case 5103:
            dispatch(selectError('mintpage.error.5103'))
            break;
        case 5105:
            dispatch(selectError("mintpage.error.5105"))
            break;
        case 5106:
            dispatch(selectError('mintpage.error.5106'))
            break;
        case 5107:
            dispatch(selectError('mintpage.error.5107'))
            break;
        default:
            dispatch(selectError("Не предвиденная ошибка"))
    }
}