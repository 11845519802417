import React from 'react';
import styled, {css} from "styled-components";

const MiniNft = ({img, title, description, maxwidth = false}) => {
    return (
        <ItemInfoBlock>
            <ItemImage maxwidth={maxwidth}>
                <img src={img}/>
            </ItemImage>
            <ItemTextBlock>
                <h1>{title}</h1>
                <span>{description}</span>
            </ItemTextBlock>
        </ItemInfoBlock>
    );
};

export default MiniNft;

const ItemInfoBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  
  height: 60px;`

const ItemImage = styled.div`
  width: 60px;
  height: 60px;

  background: #424242;
  border-radius: 10px 0px 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  img{
    width: 70%;
    //height: 100%;
    
    ${props=>props.maxwidth && css`
      width: 100%;
      height: 100%;
    `}
    border-radius: 10px 0px 0px 10px;
  }
`

const ItemTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 20px 10px 15px;
  gap: 5px;
  
  height: 60px;

  background: #2D2D2D;
  border-radius: 0px 10px 10px 0px;
  
  h1{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    display: flex;
    align-items: center;

    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    color: #FFFFFF;
    
    max-width: 213px;
    @media (max-width: 800px) {
      max-width: 141px;
    }
  }
  span{
    font-family: 'Roboto';
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;

    color: #FFFFFF;

    opacity: 0.5;
  }
`