import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import styled, {ThemeProvider} from 'styled-components'
import {useTranslation} from "react-i18next";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {motion} from "framer-motion";
import Tilt from '../../libs/Tilt'
import {gsap} from "gsap/all";
import {NoScrollStyles} from "../../styles/GlobalStyles";
import { useSelector, useDispatch } from 'react-redux'
import { selectCategory } from '../../store/features/Mint/categorySelectorState'
import {
	ShimmerThumbnail
} from "react-shimmer-effects";

const CardWrapper = styled(motion.div)`
	position: relative;
	width: calc(50% - 8px);
	
	> div {
		height: 100%;
	}
	.hide{
		display: none;
	}
	
	[data-tilt] {
		
	}
`
const Card = styled(motion.div)`
	background: rgba(255, 255, 255, 0.05);
	position: relative;
	cursor: pointer;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: hidden;
	height: 100%;
	
	img {
		display: block;
		max-width: 100%;
		margin: 0 auto;
	}
	
	
	
	span {
		width: 100%;
		height: 100%;
	}
	> div {
		position: absolute;
		top: -50%;
		left: -50%;
		width: 200%;
		height: 200%;
		transform: rotate(20deg);
	}
	
	.shimmer-thumb {
		width: 100%;
		height: 100% !important;
		animation: shimmer 2s cubic-bezier(1, -0.34, 0.73, 1.6) infinite forwards;
		margin-bottom: 0;
		background: linear-gradient(to right, 
		rgba(255, 255, 255, 0.05) 8%, 
		rgba(255, 255, 255, 0.15) 18%, 
		rgba(255, 255, 255, 0.05) 33%);
		background-size: 1000px 100%;
	}
`

const Title = styled.h4`
	font-family: 'Roboto', sans-serif;
	position: relative;
	font-weight: 700;
	font-size: 20px;
	line-height: 23px;
	z-index: 2;
	padding: 25px 23px 8px;
	
	@media (max-width: 1000px) {
		font-size: 16px;
		line-height: 19px;
		padding: 17px 16px 8px;
	}
`

const CategoryCard = (props) => {
	const [loaded, setLoaded] = useState(false);
	const selectedGear = useSelector((state) => state.gearMintSelector)
	const {t} = useTranslation();
	const dispatch = useDispatch()

	return (
			<CardWrapper style={selectedGear['character'].id && props.category !== 'character' && {display: "none"}} >
				<Tilt>
					<Card whileHover={{scale: 1.05}} className={props.className} onClick={() => dispatch(selectCategory(props.category))}>

						{!loaded && <ShimmerThumbnail className="shimmer-thumb" rounded/>}
						<Title>{t(`fitting.${props.transId}`)}</Title>
						<LazyLoadImage
							afterLoad={() => setLoaded(true)}
							effect="blur"
							src={props.image} // use normal <img> attributes as props
						/>

					</Card>
				</Tilt>
			</CardWrapper>
	)
}

export default CategoryCard