import React, {useEffect, useLayoutEffect, useRef} from 'react'
import styled from 'styled-components'
import {motion} from "framer-motion";

import {gsap} from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger'

import {light} from "../styles/Themes";
import {sequenceListCat, sequenceListUfo} from "../data/data";
import Sequence from "../components/Sequence";
import {MouseParallaxChild, MouseParallaxContainer} from "react-parallax-mouse";
import {useTranslation} from "react-i18next";

import imgBg from '../assets/Images/collection/ufo/ufo_collection_bg-min.jpg'
import imgBgMobile from '../assets/Images/mobile/ufo_bg_mobile-min.jpg'
import imgSplinePT from '../assets/Images/collection/ufo/spine_p_t-min.png'
import imgSplinePB from '../assets/Images/collection/ufo/spine_p_b-min.png'
import imgSplineTL from '../assets/Images/collection/ufo/spine_tl-min.png'
import imgSplineTR from '../assets/Images/collection/ufo/spine_tr-min.png'
import imgSplineBL from '../assets/Images/collection/ufo/spine_bl-min.png'
import imgSplineBR from '../assets/Images/collection/ufo/spine_br-min.png'
import pInstead from '../assets/Images/mobile/parallax_ufo_instead-min.png'
import seqInstead from '../assets/Images/mobile/sequence_ufo_instead-min.png'
import Home from "./Home";

let ww = window.innerWidth

const Section = styled.section`
	margin-top: -75vh;	
	position: relative;
	min-height: 250vh;
	width: 100vw;
	top: 0;
	left: 0;
	opacity: 0;
	@media (max-width: 800px) {
		margin-top: 0;
		min-height: auto;
		opacity: 1;
	}	
`

const Container = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	transform-origin: center center;
	
	@media (max-width: 800px) {
		display: block;
		height: auto;
		background-image: url(${imgBgMobile});
		background-size: cover;
		background-position: top center;
	}
`

const Top = styled.div`
	position: absolute;
	top: 100px;
	height: calc(100vh - 300px);
	width: 100vw;
	flex: 1;
	opacity:0;
	transform: translateY(200px);
	z-index: 3;
	
	@media (max-width: 800px) {
		position: relative;
		height: auto;
		top: 0;
		opacity: 1;
		transform: none;
	}
`

const TopInner = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	left: 50%;
	top: 50%;
	height: 100%;
	width: 100%;
	transform: translate(-50%, -50%);
	
	@media (max-width: 800px) {
		position: relative;
		top: 0;
		left: 0;
		padding: 51px 20px 0;
		transform: none;
		text-align: center;
		
		canvas {
			display: none;
		}
		img {
			max-width: 100%;
		}
	}
`

const Bottom = styled(motion.div)`
	position: relative;
	text-align: center;
	padding: 0 5vw 5vmin;
	perspective: 800px;
	z-index: 4;
	
	p {
		font-weight: 300;
		font-size: 32px;
		line-height: 48px;
		max-width: 717px;
		transform-origin: bottom center; 
	}
	
	@media (max-width: 800px) {
		padding-bottom: 10vh;
		position: relative;
		p{
			font-size: 20px;
			line-height: 28px;
		}
	}
`

const Description = styled(motion.div)`
	font-weight: 500;
	font-size: 20px;
	line-height: 23px;
	text-align: center;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	
	@media (max-width: 800px) {
		font-size: 10px;
		line-height: 12px;
	}
`

const ToyName = styled(motion.h1)`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 12px;
	font-size: 17vmin;
	line-height: 0.9;
	text-transform: uppercase;
	font-family: 'Bebas Neue', sans-serif;
	perspective: 400px;
	transform-style: preserve-3d;
	
	span {
		display: block;
	}
	
	@media (max-width: 800px) {
		font-size: 110px;
		line-height: 110px;
		margin-top: 6px;
	}
`

const Parallax = styled.div`
	position: absolute;
	left: 0;
	top:0;
	height: 100vh;
	width: 100vw;
	z-index: 6;
`
const layerStyle = {
	'position': 'absolute',
	'width': '100vw',
	'height':'100vh',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
}
const ParallaxInstead = styled.div`
	position: absolute;
	left: 0;
	top: 52px;
	width: 100%;
	height: 100%;
	
	img {
		width: 100%;
		height: auto;
	}
`
const Image = styled(motion.img)`
	position: absolute;
	display: block;
`

const Background = styled.div`
	position: absolute;
	left: 0;
	top:0;
	height: 100vh;
	width: 100vw;
	background-image: url(${imgBg}); 
	background-size: cover;
	background-position: top center;
	z-index: 1;
	opacity: 0;
	transform: scale(1.2);
`

const Spines = styled.div`
	position: absolute;
	left: 0;
	top:0;
	height: 100vh;
	width: 100vw;
	z-index: 2;
	
	div {
		position: absolute;
	}
	
	img{
		display: block;
		max-width: 100%;
	}
	
	.tl {
		top:0;
		left:0;
		transform-origin: left top;
		max-width: 5vw;
	}
	.tr {
		top:0;
		right:0;
		transform-origin: right top;
		max-width: 10vw;
		@media (max-width: 800px) {
			display: none;
		}
	}
	.bl {
		bottom:0;
		left:0;
		transform-origin: left bottom;
		max-width: 20vw;
		@media (max-width: 800px) {
			max-width: 35vw;
		}
	}
	.br {
		bottom:0;
		right:0;
		transform-origin: right bottom;
		max-width: 16vw;
		@media (max-width: 800px) {
			max-width: 35vw;
		}
	}			
`

const Ufo = (props) => {

	gsap.registerPlugin(ScrollTrigger);
	const { t } = useTranslation();

	const sectionRef = useRef()
	const containerRef = useRef()
	const bgRef = useRef()
	const topRef = useRef()
	const parallaxRef = useRef()
	const topInnerRef = useRef()
	const spineTLRef = useRef()
	const spineTRRef = useRef()
	const spineBLRef = useRef()
	const spineBRRef = useRef()

	useEffect(() => {

		setTimeout(() => {
			props.onReady('ufo')
		}, 300)
	}, []);

	useLayoutEffect(() => {
		let element = sectionRef.current;
		let t1 = gsap.timeline()

		if(ww > 800) {

			setTimeout(() => {
				t1.to(element, {
					scrollTrigger: {
						trigger: element,
						start: 'top top',
						end: 'bottom bottom',
						scroller: '.App',
						pin: containerRef.current,
						scrub: true,
					},
					zIndex: 5,
					ease: 'none'
				})

				t1.to(element, {
					scrollTrigger: {
						trigger: element,
						start: '-20% top',
						end: 'top top',
						scroller: '.App',
						scrub: true,
					},
					opacity: 1,
					scale: 1,
					ease: 'none'
				})

				t1.to(bgRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '5% top',
						end: '15% top',
						scroller: '.App',
						scrub: true,
					},
					opacity: 1,
					scale: 1,
					ease: 'none'
				})

				t1.from(parallaxRef.current, {
					scrollTrigger: {
						trigger: element,
						start: 'top top',
						end: '10% top',
						scroller: '.App',
						scrub: true,
					},
					opacity: 0,
					scale: 3,
					ease: 'back.out(1.7)'
				})

				/*t1.from(spineTLRef.current, {
					scrollTrigger: {
						trigger: element,
						start: 'top top',
						end: '50% top',
						scroller: '.App',
						scrub: true,
					},
					scale: .6,
					ease: 'back.out(1.7)'
				})*/

				t1.from(spineBRRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '10% top',
						end: '70% top',
						scroller: '.App',
						scrub: true,
					},
					scale: .2,
					ease: 'back.out(1.7)'
				})
				t1.from(spineTRRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '15% top',
						end: '55% top',
						scroller: '.App',
						scrub: true,
					},
					scale: .6,
					ease: 'back.out(1.7)'
				})
				t1.from(spineBLRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '5% top',
						end: '45% top',
						scroller: '.App',
						scrub: true,
					},
					scale: .4,
					ease: 'back.out(1.7)'
				})

				t1.to(topRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '-2% 20%',
						end: '5% 20%',
						scroller: '.App',
						scrub: true,
					},
					opacity: 1,
					y: 0,
					ease: 'none',
				})

				t1.to(topRef.current, {
					scrollTrigger: {
						trigger: element,
						start: 'top top',
						end: '80% bottom',
						scroller: '.App',
						scrub: true,
					},
					height: '57vh',
					ease: 'none',
				})

				ScrollTrigger.refresh()
			}, 1000)

		}


	}, []);

	const nameVariants = {
		hidden: {
			opacity: 0,
		},
		show: {
			opacity: 1,
			transition: {
				delayChildren: .5,
				staggerChildren: .2,
			}
		}
	}

	const nameItemVariants = {
		hidden:{
			opacity:0,
			rotateY: 10,
			color: light.colors.pink,
			y: 20,
			z: 50,
			transition: {
				duration: 0.1
			}
		},
		show:{
			opacity: 1,
			rotateY: 0,
			color: '#fff',
			y: 0,
			z: 0,
			transition: {
				duration: 1
			}
		}
	}

	return (
		<Section id="sequenceUfo" ref={sectionRef}>
			<Container ref={containerRef}>
				{ww > 800 &&
					<Spines>
						{/*<div ref={spineTLRef} className="tl">
							<img src={imgSplineTL} alt=""/>
						</div>*/}
						<div ref={spineTRRef} className="tr">
							<img src={imgSplineTR} alt=""/>
						</div>
						<div ref={spineBLRef} className="bl">
							<img src={imgSplineBL} alt=""/>
						</div>
						<div ref={spineBRRef} className="br">
							<img src={imgSplineBR} alt=""/>
						</div>
					</Spines>
				}
				{ww > 800 &&
					<Background ref={bgRef}/>
				}
				<Parallax ref={parallaxRef}>
					{ww > 800 ?
					<MouseParallaxContainer
						className="parallax"
						/*useWindowMouseEvents={true}*/
						containerStyles={{
							position: 'absolute',
							left: 0,
							top: 0,
							width: '100vw',
							height: '100vh',
							overflow: 'visible'
						}}
						resetOnLeave
					>
						<MouseParallaxChild
							factorX={-0.2}
							factorY={-0.08}
							updateStyles={layerStyle}
						>
							<Image style={{top: '18vh', left: '22vw', x: '-50%', y: '-50%', maxWidth: '444px', width: '16vw'}}
										 initial={{opacity: 0, zoom: 0.4, xPercent: -100, yPercent: -100}}
										 whileInView={{opacity: 1, zoom: 1, xPercent: -50, yPercent: -50}}
										 viewport={{once: false}}
										 transition={{duration: 2, delay: 1, ease: 'easeOut'}}
										 src={imgSplinePT} alt=""/>
						</MouseParallaxChild>
						<MouseParallaxChild
							factorX={0.1}
							factorY={0.08}
							updateStyles={layerStyle}
						>
							<Image style={{bottom: '38vh', right: '25vw', x: '50%', y: '50%', maxWidth: '257px', width: '20vw'}}
										 initial={{opacity: 0, zoom: 0.4, xPercent: 100, yPercent: 100}}
										 whileInView={{opacity: 1, zoom: 1, xPercent: 50, yPercent: 50}}
										 viewport={{once: false}}
										 transition={{duration: 4, delay: 1, ease: 'easeOut'}}
										 src={imgSplinePB} alt=""/>
						</MouseParallaxChild>
					</MouseParallaxContainer>
						:
						<ParallaxInstead>
							<img src={pInstead} alt=""/>
						</ParallaxInstead>
					}
				</Parallax>
				<Top ref={topRef}>
					<TopInner ref={topInnerRef}>
						{ww > 800 ?
							<Sequence onReady={props.onReady} readyName={'ufo'} slideList={sequenceListUfo} container={'sequenceUfo'}/>
							:
							<img src={seqInstead} alt=""/>
						}
					</TopInner>
				</Top>
				{ww > 800 ?
					<Bottom>
						<Description initial={{y: -200, opacity: 0}} whileInView={{y: 0, opacity: 1}} transition={{
							duration: 2,
							ease: 'easeOut'
						}}>{t('about.heroUfo.number')}</Description>
						<ToyName variants={nameVariants} initial="hidden" whileInView="show">
							<motion.span variants={nameItemVariants}>{t('about.heroUfo.name')[0]}</motion.span>
							<motion.span variants={nameItemVariants}>{t('about.heroUfo.name')[1]}</motion.span>
							<motion.span variants={nameItemVariants}>{t('about.heroUfo.name')[2]}</motion.span>
						</ToyName>
						<motion.p initial={{rotateX: -45, opacity: 0}} whileInView={{
							rotateX: 0,
							opacity: 1
						}} transition={{
							duration: 1,
							delay: 0.5,
							ease: 'easeOut'
						}}>{t('about.heroUfo.description')}
						</motion.p>
					</Bottom>
					:
					<Bottom>
						<Description>{t('about.heroUfo.number')}</Description>
						<ToyName>{t('about.heroUfo.name')}</ToyName>
						<p>{t('about.heroUfo.description')}</p>
					</Bottom>
				}
			</Container>
		</Section>
	)
}

export default Ufo