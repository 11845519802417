import i18n from 'i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

const options = {
	order: ['path', 'querystring', 'localStorage', 'navigator', 'cookie'],
	cache: ['localStorage', 'cookie'],
	lookupFromPathIndex: 0,
	lookupFromSubdomainIndex: 0,
	lookupQuerystring: 'lng'
}

i18n
	// модуль инициализации
	.use (initReactI18next)
	// Подключение бэкенда i18next
	.use(HttpApi)
	// Автоматическое определение языка
	.use(LanguageDetector)

	.init({
		detection: options,
		ns: ['translation'],
		defaultNS: 'translation',
		fallbackLng: 'ru',
		supportedLngs: ['ru', 'en'],
		interpolation: {
			escapeValue: false,
		},
		debug: false,
	})

export default i18n;