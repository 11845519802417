import { createSlice } from '@reduxjs/toolkit'

export const gearScrollSlice = createSlice({
	name: 'gearScroll',
	initialState: {
		tShirt: 0,
		jacket: 0,
		cap: 0,
		glass: 0,
		decor: 0,
		background: 0,
		bonus: 0
	},
	reducers: {
		setScrollTShirt: (state, action) => {
			state.tShirt = action.payload
		},
		setScrollJacket: (state, action) => {
			state.jacket = action.payload
		},
		setScrollCap: (state, action) => {
			state.cap = action.payload
		},
		setScrollGlass: (state, action) => {
			state.glass = action.payload
		},
		setScrollDecor: (state, action) => {
			state.decor = action.payload
		},
		setScrollBackground: (state, action) => {
			state.background = action.payload
		},
		setScrollBonus: (state, action) => {
			state.bonus = action.payload
		},
		setScrollByCategory: (state, action) => {
			const {gear, scroll} = action.payload
			state[gear] = scroll
		},
		unsetScrollByCategory: (state, action) => {
			state[action.payload] = {id: null, image: null}
		},
		unsetScrollAll: (state) => {
			state.tShirt = 0
			state.jacket = 0
			state.cap = 0
			state.glass = 0
			state.decor = 0
			state.background = 0
			state.bonus = 0
		}
	},
})

// Action creators are generated for each case reducer function
export const {
	setScrollTShirt,
	setScrollJacket,
	setScrollCap,
	setScrollGlass,
	setScrollDecor,
	setScrollBackground,
	setScrollBonus,
	setScrollByCategory,
	unsetScrollByCategory,
	unsetScrollAll
} = gearScrollSlice.actions

export default gearScrollSlice.reducer