import {selectCat, selectUfo} from "../../store/features/toySelectorState";
import {unselectByCategory} from "../../store/features/gearSelectorState";

import React from "react";

import imgSwitchCat from "../../assets/Images/fitting/catnipp_icon-min.png";
import imgSwitchUfo from "../../assets/Images/fitting/ufo_icon-min.png";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	position: absolute;
	left: 2vw;
	top: 50%;
	transform: translateY(-50%);
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(53px);
	border-radius: 12px;
	padding: 6px;
	z-index: 10;
	
	&.loading {
		opacity: 0.5;
		pointer-events: none;
	}
	
	@media (max-width: 800px) {
		top: 28px;
		left: 50%;
		padding: 5px;
		transform: translateX(-50%);
		flex-direction: row;
	}
`

const SwitchButton = styled.button`
	background: none;
	border-radius: 8px;
	border: none;
	padding: 8px;
	cursor: pointer;
	outline: none;
	opacity: 0.4;
	transition: opacity 0.3s 0s ease, background 0.3s 0s ease;
	
	&:not(:first-child) {
		margin-top: 8px;
	}
	
	&.active {
		opacity: 1;
		background: rgba(255, 255, 255, 0.1);
		box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
		backdrop-filter: blur(4px);
		pointer-events: none;
	}
	
	img {
		width: 60px;
		height: 60px;
	}
	
	@media (max-width: 800px) {
		padding: 6px;
		
		&:not(:first-child) {
			margin: 0 0 0 4px;
		}
		img {
			width: 45px;
			height: 45px;
		}
	}
`

const Switcher = () => {
	const selectedToy = useSelector((state) => state.toySelector.toy)
	const selectorLoading = useSelector((state) => state.toySelector.loading)
	const dispatch = useDispatch()

	return (
			<Container className={selectorLoading ? 'loading' : ''}>
				<SwitchButton className={selectedToy === 'cat' && 'active'} onClick={() => {
					dispatch(selectCat())
					dispatch(unselectByCategory('bonus'))
				}}>
					<img src={imgSwitchCat} alt=""/>
				</SwitchButton>
				<SwitchButton className={selectedToy === 'ufo' && 'active'} onClick={() => {
					dispatch(selectUfo())
					dispatch(unselectByCategory('bonus'))
				}}>
					<img src={imgSwitchUfo} alt=""/>
				</SwitchButton>
			</Container>
	)
}

export default Switcher