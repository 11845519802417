import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import styled, {css} from 'styled-components'
import {NoScrollStyles} from "../styles/GlobalStyles";
import {Scrollbar} from 'react-scrollbars-custom';
import CategoriesList from "../components/Mint/CategoriesList";
import GearList from "../components/Mint/GearList";
import {useTranslation} from "react-i18next";
import { useSelector, useDispatch } from 'react-redux';
import {
	unselectAll,
	unselectByCategory
} from '../store/features/Mint/gearSelectorState'

import {API_HOST, fittingData, mintData, SuccessSVG} from "../data/data";

import svgTrash from '../assets/Svgs/trash.svg'
import svgBack from '../assets/Svgs/arrow-back.svg'
import svgOpen from '../assets/Svgs/t-shirt-icon.svg'
import svgClose from '../assets/Svgs/close.svg'

import 'react-lazy-load-image-component/src/effects/blur.css';
import {selectCategory} from "../store/features/categorySelectorState";
import FittingView from "../components/Mint/FittingView";
import Switcher from "../components/Mint/Switcher";
import FittingBackground from "../components/Mint/FittingBg";
import axios from "axios";
import {setCountDataState, setInventoryDataState} from "../store/features/gearCountState";
import Loader from "../components/Loader";
import Modal from "../components/Mint/Modal";
import ModalOtherAdress from "../components/Mint/ModalOtherAdress";
import SwitchModal from "../components/Mint/SwitchModal";
import useWebSocket, {ReadyState} from "react-use-websocket";
import {
	selectAuth,
	selectDecompose,
	selectError,
	selectMint, selectPreDecompose,
	selectPreMint
} from "../store/features/modalSelectorState";

import Cookies from "universal-cookie";
import api_mint from "../api/Mint";


import Update from "../components/functions/updateInventory"
import ContinueMintItem from "../components/Mint/modals/ContinueMintItem";
import Success from "../components/Mint/modals/Success";
import Error from "../components/Mint/modals/Error";
import PreMint from "../components/Mint/modals/PreMint";
import Tooltip from "../components/Mint/UI/Elements/Tooltip";
import {AnimatePresence, motion} from "framer-motion";
import Lottie from "lottie-react";
import UpdateJSON from "../assets/lottie/Update.json";
import PendingJSON from "../assets/lottie/Pending.json";


let ww = window.innerWidth
const isMobile = window.innerWidth <= 800

const itemVariants = {
	initial: { opacity: 0, y: -100 },
	open: { opacity: 1, y: 0},
	closed: { opacity: 0, y: +100 }
};

const MintPage = (props) => {
	const [loaded, setLoaded] = useState(false)
	const [countLoaded, setCountLoaded] = useState(false)
	const [listOpened, setListOpened] = useState(false)
	const {t} = useTranslation();

	const selectedToy = useSelector((state) => state.toySelector.toy)
	const selectedGear = useSelector((state) => state.gearMintSelector)
	const selectedCategory = useSelector((state) => state.categorySelector.category)
	const dispatch = useDispatch()

	const selectedModal = useSelector((state) => state.modalSelector.modal)
	const selectorLoading = useSelector((state) => state.modalSelector.modal)
	const selectedAuth = useSelector((state) => state.modalSelector.auth)
	const DeLabConnector = useSelector((state) => state.authSelector.connector)



	const sectionRef = useRef(null);
	const contentRef = useRef(null);
	const lottieUpdateRef = useRef(null);
	const didUnmount = useRef(false);

	const [userWallet, setUserWallet] = useState(null)

	const cookies = new Cookies();

	const [tooltipShow, setTooltipShow] = useState(false);
	const [updateActive, setUpdateActive] = useState(false);

	const [isUpdated, setIsUpdated] = useState(false);

	useEffect(() => {
		document.addEventListener('tooltipCopy', tooltipCopy)
		document.addEventListener('updatedInv', updated)
		return () => {
			document.removeEventListener('tooltipCopy', tooltipCopy)
			document.removeEventListener('updatedInv', updated)
		};
	}, []);


	useEffect(()=>{
		//cookies.set("wallet", "1233333", { path: '/' })
		if(cookies.get("wallet")){
			setUserWallet(cookies.get("wallet"))
			dispatch(selectAuth())
		}
	},[])


	useEffect(()=>{
		if(selectedAuth && userWallet) {
			Update.updateInventory(userWallet, dispatch, setCountLoaded)
		}
	}, [selectedAuth, userWallet])


	useEffect(() => {
		setTimeout(() => {
			props.setLoaded(true)
			setLoaded(true)
			props.setPercent(100)
		}, 300)
		return () => {
			props.setLoaded(false)
		};
	}, []);

	useLayoutEffect(() => {
		setTimeout(() => {
			if(ww > 800) {

			}
		}, 1000)


	}, []);

	const getTrashClass = () => {
		let btnClass = ''
		if(selectedCategory){
			btnClass = selectedGear[selectedCategory].id ? 'active' : ''
		}else{
			let count = 0
			{Object.keys(selectedGear).map((key, i) => {
				if(selectedGear[key].id){
					count++
				}
			})}
			if(count > 0) btnClass = 'active'
		}

		return btnClass
	}

	const unselectGear = () => {
		if(selectedCategory) {
			dispatch(unselectByCategory(selectedCategory))
			document.dispatchEvent(new CustomEvent('gearSelect', { detail: {category: selectedCategory, image: null}}));
		}else{
			dispatch(unselectAll())
			document.dispatchEvent(new CustomEvent('gearSelect', { detail: {category: 'all', image: null}}));
		}

	}

	function tooltipCopy(){
		setTooltipShow(true)
		setTimeout(function() { //Start the timer
			setTooltipShow(false)
		}.bind(this), 2000)
	}



	function updateInventory(){
		dispatch(unselectAll())
		document.dispatchEvent(new CustomEvent('gearSelect', {detail: {category: 'all', image: null}}))
		setUpdateActive(true)
		lottieUpdateRef.current.play()
		Update.updateInventory(DeLabConnector.address, dispatch, false)
	}

	function updated(){
		setIsUpdated(true)
	}
/*<ImageLottie active={updateActive} onClick={updateInventory}>
										<Lottie
											lottieRef={lottieUpdateRef}
											animationData={UpdateJSON}
											loop={true}
											autoplay={false}
											onLoopComplete={()=>{
												if(isUpdated) {
													lottieUpdateRef.current.stop()
													setUpdateActive(false)
												}
											}}
										/>
									</ImageLottie>
									//сбросить по категориям
									{selectedCategory ?
												t(`fitting.trashButtonText.${selectedCategory}`)
												:
												t(`fitting.trashButtonText.all`)
											}
									*/
	return (
		<>
			<NoScrollStyles/>


			<Section id="fitting" ref={sectionRef}>

				<div></div>
				<SwitchModal setUserWallet={setUserWallet}/>
				<Container>
					{/*<FittingDev/>*/}
					<FittingBackground/>

					<FittingView/>
					<Switcher/>

					<ToolPosition
						as={motion.div}
						variants={itemVariants}
						initial="initial"
						animate={tooltipShow ? "open" : "closed"}
					>
						<Tooltip classic img={SuccessSVG} text={'Адрес скопирован'}/>
					</ToolPosition>


					{
						(ww > 650)
						?
							(selectedGear['character'].id)
								? <ButtonMint onClick={()=>dispatch(selectPreDecompose())}><span>{t("mintpage.buttons.decompose")}</span></ButtonMint>
								: <ButtonMint onClick={()=>dispatch(selectPreMint())}><span>{t("mintpage.buttons.mint")}</span></ButtonMint>
						:
							(selectedGear['character'].id)
								? <ButtonMintMobile onClick={()=>dispatch(selectPreDecompose())}><span>{t("mintpage.buttons.decompose")}</span></ButtonMintMobile>
								: <ButtonMintMobile onClick={()=>dispatch(selectPreMint())}><span>{t("mintpage.buttons.mint")}</span></ButtonMintMobile>
					}


					<MobileListOpen onClick={() => setListOpened(true)}>
						<img src={svgOpen} alt=""/>
					</MobileListOpen>
					<GearWrapper className={listOpened ? 'active' : ''}>
						{!countLoaded && <Loader/>}
						<GearInner>
							<GearHeader>
								{selectedCategory &&
								<BackButton onClick={() => dispatch(selectCategory())}>
									<img src={svgBack} alt=""/>
								</BackButton>
								}

								{selectedCategory ?
									t(`fitting.${mintData.categories[selectedCategory].transId}`)
									:
									t(`fitting.noCategory`)
								}
								<CloseButton onClick={() => setListOpened(false)}>
									<img src={svgClose} alt=""/>
								</CloseButton>

								<UpdateWrapper>

									<UpdateButton className={'active'} onClick={updateInventory}>
										<div className="TrashButtonIcon">
											<ImageLottie active={updateActive} >
												<Lottie
													lottieRef={lottieUpdateRef}
													animationData={UpdateJSON}
													loop={true}
													autoplay={false}
													onLoopComplete={()=>{
														if(isUpdated) {
															lottieUpdateRef.current.stop()
															setUpdateActive(false)
														}
													}}
												/>
											</ImageLottie>
										</div>
										<div className="TrashButtonText">
											Обновить
										</div>
									</UpdateButton>
									<TrashButton className={getTrashClass()} onClick={() => unselectGear()}>
										<div className="TrashButtonIcon">
											<img src={svgTrash} alt=""/>
										</div>
										<div className="TrashButtonText">
											Сбросить
										</div>
									</TrashButton>
								</UpdateWrapper>

							</GearHeader>
							{selectedCategory ?
									<GearList/>
									:
								<Scrollbar noScrollX={true}>
									<GearContent ref={contentRef}>
										<CategoriesList items={mintData.categories}/>
									</GearContent>
								</Scrollbar>

							}
						</GearInner>
					</GearWrapper>
				</Container>

			</Section>

		</>
	)
}

export default MintPage

const Section = styled.section`
	min-height: 100%;
	width: 100vw;
	position: relative;
	overflow: hidden;
	
	img {
		display: block;
	}
`

const Container = styled.div`
	background-color: ${props => props.theme.colors.black};
	min-height: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;	
`

const GearWrapper = styled.div`
	position: absolute;
	right: 12px;
	top: 12px;
	width: 40%;
	max-width: 544px;
	height: calc(100% - 24px);
	z-index: 6;	
	
	@media (max-width: 800px) {
		top: 0;
		height: 100%;
		left: 0;
		right: auto;
		width: 100%;
		max-width: none;
		z-index: 6000;
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		transition: 0.3s 0s ease;
		
		&.active {
			opacity: 1;
			visibility: visible;
			pointer-events: all;
		}
	}
`

const GearInner = styled.div`
	display: flex;
	flex-direction: column;
	background: rgba(36, 36, 36, 0.6);
	backdrop-filter: blur(337px); //TODO Удалить при торомзах в сраной сафаре
	border-radius: 24px;
	max-height: 100%;
	height: 100%;
	padding: 0 28px 20px;
	
	.ScrollbarsCustom-Track {
		right: -18px !important;
		top: 8px !important;
	}
	.ScrollbarsCustom-Wrapper {
		inset: 0 !important;
		margin: -16px -16px -20px !important;
		padding: 16px;
	}
	.ScrollbarsCustom-Scroller{
		overflow-x: hidden !important;
	}
	
	@media (max-width: 1000px) {
		padding: 0 12px 12px;
	}
	@media (max-width: 800px) {
		padding: 0 12px;
		border-radius: 0;
		background: #1c1c1c;
		backdrop-filter: none; 
		
		.ScrollbarsCustom-Track {
			right: -12px !important;
		}
		.ScrollbarsCustom-Wrapper {
			margin: 0 -12px !important;
			padding: 0 12px;
		}
	}
`
const GearHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	padding: 20px 0;
	min-height: 73px;	
	font-weight: 700;
	font-size: 28px;
	line-height: 33px;
	
	@media (max-width: 800px) {
		justify-content: center;
		flex-wrap: wrap;
		font-size: 20px;
		line-height: 23px;
		padding: 16px 0 12px;
		min-height: auto;
	}
`
const TrashButton = styled.button`
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	outline: none;
	background: none;
	cursor: pointer;
	
	img{
		opacity: .4;
	}
	
	:hover{
		img{
			opacity: 1;
		}
	}
	
	.TrashButtonText{
		display: none;
	}
	
	@media (max-width: 800px) {
		width: 100%;
		height: 36px;
		background: rgba(255, 255, 255, 0.05);
		border-radius: 8.25641px;
		opacity: 0.2;
		pointer-events: none;
		transition: 0.3s 0s ease;
		
		
		&.active {
			opacity: 1;
			pointer-events: all;
		}
		
		.TrashButtonText{
			display: block;
			font-size: 13px;
			line-height: 15px;
			margin-left: 4px;
			color: #fff;
		}
	}
`
const BackButton = styled.button`
	width: 32px;
	height: 32px;
	display: block;
	margin-right: 16px;
	border: none;
	outline: none;
	background: none;
	cursor: pointer;
	
	@media (max-width: 800px) {
		position: absolute;
		top: 12px;
		left: 0;
	}
`

const CloseButton = styled.div`
	width: 32px;
	height: 32px;
	padding: 4px;
	cursor: pointer;
	position: absolute;
	top: 12px;
	right: -4px;
	display: none;
	
	@media (max-width: 800px) {
		display: block;
	}
`

const GearContent = styled.div`
	padding: 16px;
	
	@media (max-width: 800px) {
		padding: 0 12px 12px;
	}
`

const MobileListOpen = styled.div`
	display: none;
	position: absolute;
	bottom: 20px;
	right: 12px;
	width: 52px;
	height: 52px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background: #EF2ADC;
	box-shadow: 0 4px 16px rgba(239, 42, 220, 0.7);
	border-radius: 73px;
	z-index: 10;
	
	@media (max-width: 800px) {
		display: flex;
	}
`

const ButtonMint = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 18px 0px;
	gap: 5px;
	
	
	position: absolute;
	width: 166px;
	height: 62px;
	z-index: 10;
	margin-left: 20%;
	margin-right: auto;
	left: 0;
	right: 0;
	text-align: center;
	top: 158px;
	
	background: #FFFFFF;
	box-shadow: 0px 0px 36px #EF2ADC;
	border-radius: 16px;
	
	:hover{
		background: #EF2ADC;
		span{
			color: #FFFFFF;
		}
	}
	span{
		width: 104px;
		height: 22px;
	
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 500;
		
		font-size: 18px;
		line-height: 21px;
	
		color: #EF2ADC;
	
	
		/* Inside auto layout */
	
		flex: none;
		order: 0;
		flex-grow: 0;
	}

	@media (max-width: 800px) {
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
		text-align: center;
		top: 120px
	}
	
`

const ButtonMintMobile = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 18px 0px;
	gap: 5px;
	
	
	position: absolute;
	width: 166px;
	height: 62px;
	z-index: 10;
	margin-left: 20%;
	margin-right: auto;
	left: 0;
	right: 0;
	text-align: center;
	bottom: 20px;
	
	background: #FFFFFF;
	box-shadow: 0px 0px 36px #EF2ADC;
	border-radius: 16px;
	
	:hover{
		background: #EF2ADC;
		span{
			color: #FFFFFF;
		}
	}
	span{
		width: 104px;
		height: 22px;
	
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 500;
		
		font-size: 18px;
		line-height: 21px;
	
		color: #EF2ADC;
	
	
		/* Inside auto layout */
	
		flex: none;
		order: 0;
		flex-grow: 0;
	}

	@media (max-width: 800px) {
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
		text-align: center;
		bottom: 20px
	}
	
`
const ToolPosition = styled.div`
    position: absolute;
    z-index: 9999;
    bottom: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
  
    display: inline-flex;
    justify-content: center;
    align-items: center;
`

const UpdateWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px;
	gap: 20px;

	@media (max-width: 800px) {
		width: 100%;
		margin-top: 16px;
		gap: 12px;
	}
	
	`
const ImageLottie = styled.div`
	width: 22px;
	height: 22px;
	display: flex;
	justify-content: center;
	
	
	div{
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	svg{
		opacity: .4;
		width: 100%;
		height: 100%;
	}
	${props=>props.active && css`
		svg{
			opacity: 1;
		}
	`}
		
	
	:hover{
		svg{
			opacity: 1;
		}
	}
`

const UpdateButton = styled.div`
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	outline: none;
	background: none;
	cursor: pointer;
	
	img{
		opacity: .4;
	}
	
	:hover{
		img{
			opacity: 1;
		}
	}
	
	.TrashButtonText{
		display: none;
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 15px;
		text-align: center;
	}
	
	@media (max-width: 800px) {
		width: 100%;
		height: 36px;
		background: rgba(255, 255, 255, 0.05);
		border-radius: 8.25641px;
		opacity: 0.2;
		pointer-events: none;
		transition: 0.3s 0s ease;
		
		
		&.active {
			opacity: 1;
			pointer-events: all;
		}
		
		.TrashButtonText{
			display: block;
			font-size: 13px;
			line-height: 15px;
			margin-left: 4px;
			color: #fff;
		}
	}
`