import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {gsap} from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger'

const Sequensor = styled.canvas`
	max-width: 200vw;
	max-height: 100%;
`

const Sequence = (props) => {
	gsap.registerPlugin(ScrollTrigger);

	const [slide, setSlide] = useState(props.slideList.start);

	const canvasRef = useRef(null);
	const containerRef = useRef(null);

	const slideNum = {current: slide}

	useLayoutEffect(() => {
		canvasRef.current.height = 1080
		canvasRef.current.width = 1920
		preloadImages()
	}, []);

	useEffect(() => {
		updateCanvas()
	}, [slide]);

	useLayoutEffect(() => {
		let element = document.getElementById(props.container);
		let t1 = gsap.timeline()

		setTimeout(() => {
			t1.to(slideNum, {
				scrollTrigger: {
					trigger: element,
					start: 'top top',
					end: '80% bottom',
					scroller: '.App',
					scrub: true,
				},
				current: props.slideList.end,
				duration: 1,
				ease: 'none',
				onUpdate: changeNumber
			})

			ScrollTrigger.refresh()
		}, 1000)


	}, []);

	function updateCanvas() {
		const ctx = canvasRef.current.getContext('2d');

		const img = new Image()
		img.src = currentSlide(slide)
		img.onload = function(){
			ctx.clearRect(0,0, canvasRef.current.width, canvasRef.current.height);
			ctx.drawImage(img, 0, 0)
		}
	}

	function changeNumber() {
		setSlide(slideNum.current.toFixed())
	}

	const currentSlide = index => (
		props.slideList[`img${index.toString().padStart(4, '0')}`]
	)

	const preloadImages = () => {
		let loaded = props.slideList.start
		for (let i = loaded; i < props.slideList.end; i++) {
			const img = new Image();
			img.src = currentSlide(i)
			img.onload = () => {
				loaded++;
				if(loaded === props.slideList.end) {
					props.onReady(props.readyName)
				}
			}
		}
	}

	return (
		<Sequensor ref={canvasRef}/>
	)
}

export default Sequence