import React, {useEffect, useLayoutEffect, useRef} from 'react'
import styled from 'styled-components'
import PageTitle from "../components/PageTitle";
import {gsap, ScrollTrigger} from "gsap/all";
import UniverseVideo from "../components/UniverseVideo";
import {useTranslation} from "react-i18next";

let ww = window.innerWidth

const Section = styled.section`
	min-height: 100vh;
	width: 100vw;
	opacity: 0;
	overflow: hidden;
	position: relative;
	@media (max-width: 800px) {
		opacity: 1;
	}
`

const Container = styled.div`
	min-height: 100vh;
	width: 100vw;
	padding: 68px 25px 33px;	
	background: ${props => props.bg};
	display: flex;
	align-items: center;
	justify-content: center;
	
	
	p {
		margin-top: 8px;
		font-weight: 300;
		font-size: 32px;
		line-height: 48px;
		max-width: 1068px;
		text-align: center;
	}
	
	@media (max-width: 800px) {
		padding: 30px 0;
		
		p{
			font-size: 24px;
			line-height: 127.19%;
		}
	}
`

const Inner = styled.div`
	background: ${props => props.theme.colors.pink};
	border-radius: 50px;
	padding: 71px 40px 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	
	@media (max-width: 800px) {
		padding: 58px 8px 8px;
		border-radius: 20px;
	}
`

const Title = styled.div`
	position: relative;
	transform-origin: top center;
`
const Universe = (props) => {
	gsap.registerPlugin(ScrollTrigger);
	const { t } = useTranslation();

	const sectionRef = useRef(null);
	const containerRef = useRef(null);
	const titleRef = useRef(null);


	useEffect(() => {
		setTimeout(() => {
			props.onReady('universe')
		}, 300)
	}, []);

	useLayoutEffect(() => {
		let element = sectionRef.current;
		let t1 = gsap.timeline()

		setTimeout(() => {
			if(ww > 800){
				t1.from(titleRef.current, {
					scrollTrigger: {
						trigger: element,
						start: 'top 80%',
						end: 'top center',
						scroller: '.App',
						scrub: true,
					},
					rotateX: -40,
					y: -20,
					ease: 'none'
				})
				t1.to(element, {
					scrollTrigger: {
						trigger: element,
						start: 'top bottom',
						end: 'top center',
						scroller: '.App',
						scrub: true
					},
					opacity:1,
					zIndex: 2,
					ease: 'none'
				})
				t1.to(containerRef.current, {
					scrollTrigger: {
						trigger: element,
						start: 'bottom 60%',
						end: 'bottom 20%',
						scroller: '.App',
						scrub: true,
					},
					opacity: 0,
					yPercent: -40,
					zIndex: -1,
					ease: 'none'
				})
			}


			/*t1.to(element, {
				scrollTrigger: {
					trigger: element,
					start: 'top top',
					end: 'bottom bottom',
					scroller: '.App',
					pin: containerRef.current,
				},
				ease: 'none'
			})
*/

			ScrollTrigger.refresh()
		}, 1000)


	}, []);

	const youtubeID = 'zWyUAQVqkH4'

	return (
		<Section id="universe" ref={sectionRef}>
			<Container ref={containerRef}>
				<Inner>
					<Title ref={titleRef}>
						<PageTitle title={t('universe.title')}/>
					</Title>
					<p>{t('universe.description')}</p>
					<UniverseVideo videoId={youtubeID}/>
				</Inner>
			</Container>
		</Section>
	)
}

export default Universe