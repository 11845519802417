import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {gsap, ScrollTrigger} from "gsap/all";
import ReactPlayer from 'react-player'
import {motion} from "framer-motion";
import {useTranslation} from "react-i18next";

import videoRight from '../assets/Video/integration-video.mp4'
import {MouseParallaxChild, MouseParallaxContainer} from "react-parallax-mouse";
import pImg11 from "../assets/Images/collection/p11-min.png";
import pImg8 from "../assets/Images/collection/p8-min.png";
import pImg16 from "../assets/Images/collection/p16-min.png";
import pImg14 from "../assets/Images/collection/p14-min.png";
import pImg10 from "../assets/Images/collection/p10-min.png";
import pImg15 from "../assets/Images/collection/p15-min.png";
import imgPlay from '../assets/Images/playVideo-min.png'
import pInstead from '../assets/Images/mobile/parallax_integration_instead-min.png'

let ww = window.innerWidth

const Section = styled.section`
	height: 400vh;
	width: 100vw;
	margin-top: -100vh;
	opacity: 0;
	overflow: hidden;
	
	@media (max-width: 800px) {
		height: auto;
		margin-top: 0;
		opacity: 1;
		overflow: visible;
	}
`

const Container = styled.div`
	height: 100vh;
	width: 100vw;
	padding: 136px 60px 60px;	
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	
	@media (max-width: 800px) {
		height: auto;
		padding: 45px 20px 81px;
	}
`

const Inner = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	max-width: 1440px;
	margin: 0 auto;
	@media (max-width: 800px) {
		position: relative;
		z-index: 2;
	}
`

const Left = styled.div`
	position: relative;
	align-self: flex-start;
	overflow: hidden;
	
	h1 {
		position: relative;
		font-family: 'Bebas Neue', sans-serif;
		font-weight: 700;
		font-size: 120px;
		line-height: 120px;
		z-index: 3;
		letter-spacing: 1px;
	}
	
	.shadow {
		background: linear-gradient(rgba(0, 0, 0, 1) 0.25%, rgba(0, 0, 0, 0.69) 99.75%);
		position: absolute;
		width: 100%;
		left:0;
		top:-10px;
		height: 0;
		z-index: 2;
		box-shadow: 0 0 50px 4px #000;
		border-top: 25px solid #000;
	}
	
	@media (max-width: 1200px) {
		h1 {
			font-size: 100px;
			line-height: 100px;
		}
	}
	@media (max-width: 800px) {
		overflow: visible;
		h1{
			font-size: 60px;
			line-height: 74%;
			text-align: center;
		}
		.shadow {
			display: none;
		}
	}
`
const LeftText = styled.div`
	margin-top: 27px;
	font-weight: 300;
	font-size: 32px;
	line-height: 127.19%;
	max-width: 613px;
	p {
		+ p {
			margin-top: 24px;	
		}
	}
	
	@media (max-width: 1200px) {
		font-size: 26px;
	}
	@media (max-width: 800px) {
		padding-top: 20px;
		margin-top: 0;
		font-size: 20px;
		line-height: 28px;
		p {
			margin-top: 20px;
		}
	}
`
const Right = styled.div`
		position: relative;
		border: 10px solid #480D42;
		/*filter: drop-shadow(0px 38px 184px rgba(239, 42, 220, 0.37)) drop-shadow(0px 8px 38px rgba(255, 0, 245, 0.2));*/
		box-shadow: 0 0 184px 0 #ef2adc5e;
		border-radius: 45px;
		overflow: hidden;
		max-width: 30vw;
		max-height: 100%;
		
		div:first-child {
			width: 100%;
			height: 100%;
		}
		
		video {
			display: block;
			top:0;
			width: 100% !important;
			max-height: 80vh;
		}
		
		@media (max-width: 800px) {
			max-width: 330px;
			width: 100%;
			margin: 28px auto 0;
			border: 6.1657px solid #480D42;
			filter: drop-shadow(0px 23.4297px 113.449px rgba(239, 42, 220, 0.37)) drop-shadow(0px 4.93256px 23.4297px rgba(255, 0, 245, 0.2));
			box-shadow: none;
			border-radius: 27.7457px;
			
			video {
				max-height: none;
			}
		}
`
const Parallax = styled.div`
	position: absolute;
	left: 0;
	top:0;
	height: 100vh;
	width: 100vw;
	z-index: 1;
	
	@media (max-width: 800px) {
		height: 100%;
	}
`
const layerStyle = {
	'position': 'absolute',
	'width': '100vw',
	'height':'100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
}

const Image = styled(motion.img)`
	position: absolute;
	display: block;
	${props => props.blur && 'filter: blur('+ props.blur +'px);' }
`

const PlayBtn = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	cursor:pointer;
	background-image:url(${imgPlay});
	width: 200px;
	height: 200px;
	background-size: contain;
	background-position:center;
	background-repeat: no-repeat;
`

const ParallaxInstead = styled.div`
	position: absolute;
	left: 0;
	top: 52px;
	width: 100%;
	height: 100%;
	
	img {
		width: 100%;
		height: auto;
	}
`

const Integration = (props) => {
	const [videoLoaded, setVideoLoaded] = useState(false);
	const [playVideo, setPlayVideo] = useState(ww > 800);
	const [messageComplete, setMessageComplete] = useState(false);

	gsap.registerPlugin(ScrollTrigger);
	const { t, i18n } = useTranslation();

	const sectionRef = useRef(null);
	const containerRef = useRef(null);
	const titleRef = useRef(null);
	const shadowRef = useRef(null);
	const textRef = useRef(null);
	const textP1Ref = useRef(null);
	const textP2Ref = useRef(null);
	const videoRef = useRef(null);
	const parallaxRef = useRef(null);

	let t2 = gsap.timeline()

	useEffect(() => {
		setTimeout(() => {
			props.onReady('integration')
		}, 300)
	}, [videoLoaded]);

	useEffect(() => {

		if(messageComplete) {
			setTextAnimation()
			t2.play()
		}
	}, [i18n.language]);


	const setTextAnimation = () => {
		t2.to(textP1Ref.current, {
			text: {value: t('integrations.firstParagraph'),delimiter: ''},
			duration: 1,
			ease: 'back.out(1.7)',
			onStart: () => setMessageComplete(false)
		})
			.to(textP2Ref.current, {
				text: {value: t('integrations.secondParagraph'),delimiter: ''},
				duration: 1,
				ease: 'back.out(1.7)',
				onComplete: () => setMessageComplete(true)
			}, '-=0.6')
		t2.pause()
	}

	useLayoutEffect(() => {
		let element = sectionRef.current;
		let t1 = gsap.timeline()

		t2.to(textP1Ref.current, {
			text: {value: t('integrations.firstParagraph'),delimiter: ''},
			duration: 2,
			ease: 'back.out(1.7)'
		})
		.to(textP2Ref.current, {
			text: {value: t('integrations.secondParagraph'),delimiter: ''},
			duration: 2,
			ease: 'back.out(1.7)',
			onComplete: () => setMessageComplete(true)
		}, '-=1')
		t2.pause()

		setTimeout(() => {

			if(ww > 800) {
				t1.to(element, {
					scrollTrigger: {
						trigger: element,
						start: 'top top',
						end: '300 top',
						scroller: '.App',
						scrub: true
					},
					opacity:1,
					zIndex: 2,
					ease: 'none'
				})
				t1.from(titleRef.current, {
					scrollTrigger: {
						trigger: element,
						start: 'top top',
						end: '15% top',
						scroller: '.App',
						scrub: true,
					},
					x: -300,
					opacity: 0,
					ease: 'back.out(2.7)',
					onComplete: () => t2.play()
				})

				t1.from(videoRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '5% top',
						end: '20% top',
						scroller: '.App',
						scrub: true,
					},
					xPercent: 100,
					opacity: 0,
					ease: 'back.out(1.7)'
				})

				t1.to(textRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '35% top',
						end: '60% top',
						scroller: '.App',
						scrub: true,
					},
					yPercent: -100,
					ease: 'none'
				})

				t1.to(shadowRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '30% top',
						end: '60% top',
						scroller: '.App',
						scrub: true,
					},
					height: '100%',
					ease: 'none'
				})

				t1.to(textRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '58% top',
						end: '60% top',
						scroller: '.App',
						scrub: true,
					},
					opacity: 0,
					ease: 'none'
				})

				t1.to(parallaxRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '55% top',
						end: '78% top',
						scroller: '.App',
						scrub: true,
					},
					opacity: 0,
					scale: 3,
					zIndex: -1,
					display: 'none',
					duration: 10,
					ease: 'none'
				})

				t1.to(containerRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '90% bottom',
						end: 'bottom bottom',
						scroller: '.App',
						scrub: true,
					},
					opacity:0,
					zIndex: -1,
					ease: 'none'
				})
				t1.to(element, {
					scrollTrigger: {
						trigger: element,
						start: 'top top',
						end: 'bottom bottom',
						scroller: '.App',
						pin: containerRef.current,
					},
					ease: 'none'
				})
			}

			ScrollTrigger.refresh()
		}, 1000)


	}, []);

	return (
		<Section id="integration" ref={sectionRef}>
			<Container ref={containerRef}>
				<Parallax ref={parallaxRef}>
					{ww > 800 ?
							<MouseParallaxContainer
								className="parallax"
								/*useWindowMouseEvents={true}*/
								containerStyles={{
									position: 'absolute',
									left: 0,
									top: 0,
									width: '100vw',
									height: '100%',
									overflow: 'visible'
								}}
								resetOnLeave
							>
								<MouseParallaxChild
									factorX={0.05}
									factorY={0.05}
									updateStyles={layerStyle}
								>
									<Image style={{bottom: '1vh', left: '6vw', x: '-50%', y: '-40%', rotate: '-20deg'}}
												 width={153}
												 initial={{opacity: 0, zoom: 0.7}}
												 whileInView={{opacity: 1, zoom: 1}}
												 viewport={{once: false}}
												 transition={{duration: 2, ease: 'easeOut'}}
												 src={pImg11} alt=""/>
								</MouseParallaxChild>
								<MouseParallaxChild
									factorX={0.5}
									factorY={0.4}
									updateStyles={layerStyle}
								>
									<Image style={{top: '35vh', left: '51vw', x: '-50%', y: '-50%', scale: 0.5}}
												 width={330}
												 initial={{opacity: 0, zoom: 0.7}}
												 whileInView={{opacity: 1, zoom: 1}}
												 viewport={{once: false}}
												 transition={{duration: 2, ease: 'easeOut'}}
												 src={pImg16} alt=""/>
								</MouseParallaxChild>
								<MouseParallaxChild
									factorX={0.25}
									factorY={0.4}
									updateStyles={layerStyle}
								>
									<Image style={{top: '10vh', right: '9vw', x: '-50%', y: '-50%', zIndex: 1}}
												 width={160}
												 blur={5}
												 initial={{opacity: 0, zoom: 0.7}}
												 whileInView={{opacity: 1, zoom: 1}}
												 viewport={{once: false}}
												 transition={{duration: 2, ease: 'easeOut'}}
												 src={pImg14} alt=""/>
								</MouseParallaxChild>
								<MouseParallaxChild
									factorX={0.09}
									factorY={-0.12}
									updateStyles={layerStyle}
								>
									<Image style={{bottom: '-2vh', right: '-1vw', x: '10%', y: '10%', zIndex: 10, scale: 0.8}}
												 width={324}
												 initial={{opacity: 0, zoom: 0.7}}
												 whileInView={{opacity: 1, zoom: 1}}
												 viewport={{once: false}}
												 transition={{duration: 2, ease: 'easeOut'}}
												 src={pImg8} alt=""/>
								</MouseParallaxChild>
								<MouseParallaxChild
									factorX={0.1}
									factorY={0.2}
									updateStyles={layerStyle}
								>
									<Image style={{bottom: '-5vh', left: '50vw', x: '-50%', scale: 1.1}}
												 width={167}
												 blur={3}
												 initial={{opacity: 0, zoom: 0.7}}
												 whileInView={{opacity: 1, zoom: 1}}
												 viewport={{once: false}}
												 transition={{duration: 2, ease: 'easeOut'}}
												 src={pImg10} alt=""/>
								</MouseParallaxChild>
								<MouseParallaxChild
									factorX={0.12}
									factorY={0.1}
									updateStyles={layerStyle}
								>
									<Image style={{top: '20vh', left: '0vw', x: '-50%', y: '-50%', rotate: '10deg', blurRadius: 2}}
												 width={210}
												 initial={{opacity: 0, zoom: 0.7}}
												 whileInView={{opacity: 1, zoom: 1}}
												 viewport={{once: false}}
												 transition={{duration: 2, ease: 'easeOut'}}
												 src={pImg15} alt=""/>
								</MouseParallaxChild>
							</MouseParallaxContainer>
						:
							<ParallaxInstead>
								<img src={pInstead} alt=""/>
							</ParallaxInstead>
								}
				</Parallax>
				<Inner>
					<Left>
						<div ref={shadowRef} className="shadow"> </div>
						<h1 ref={titleRef}>{t('integrations.title')}</h1>
						{ww <= 800 &&
							<Right ref={videoRef}>
								<ReactPlayer volume={0} playing={playVideo} width={'auto'} height={'auto'} loop={true} controls={false} url={videoRight} onReady={() => setVideoLoaded(true)}/>
								{!playVideo &&
									<PlayBtn onClick={() => setPlayVideo(true)}/>
								}
							</Right>
						}
						<LeftText ref={textRef}>
							<p ref={textP1Ref}>{ww <= 800 && 'Наша команда долгое время занимается интеграцией технологий захвата мимики человеческого лица, для синхронизации с NFT-аватаром, что позволяет в реальном времени использовать ваш NFT в качестве маски.'}</p>
							<p ref={textP2Ref}>{ww <= 800 && 'Эта возможность может быть использована на многих площадках уже сегодня. Уверены, что в скором времени вы сможете использовать эти функции прямо в Telegram!'}</p>
						</LeftText>
					</Left>
					{ww > 800 &&
						<Right ref={videoRef}>
							<ReactPlayer volume={0} playing={playVideo} width={'auto'} height={'auto'} loop={true} controls={false} url={videoRight} onReady={() => setVideoLoaded(true)}/>

						</Right>
					}
				</Inner>
			</Container>
		</Section>
	)
}

export default Integration