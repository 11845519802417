import api_mint from "../../api/Mint";
import {selectError} from "../../store/features/modalSelectorState";
import {setCountDataState, setInventoryDataState} from "../../store/features/gearCountState";

export default class Update {
    static async updateInventory(userWallet, dispatch, setCountLoaded = false) {
        api_mint.getAllNfts(userWallet).then(data => {
            console.log("ДАТА")
            console.log(data)
            if (data.success) {
                if (!data.has_any_nft) {
                    dispatch(selectError("У вас нет NFT ton guys"))
                    return false
                }


                /*if (!data.nfts.item && !nfts.head && !nfts.glasses && !nfts.shirt && !nfts.jacket && !nfts.special
                    && !nfts.jewellery && !nfts.background && nfts.unknown && nfts.unknown.length > 0
                    && !data.nfts.character && !nfts.body) {*/
                if (!data.has_any_nft) {
                    if(setCountLoaded)
                        setCountLoaded(false)
                } else {
                    let nfts = data.nfts.item
                    let jsonCountData = {
                        "cat": {
                            "head": (data.nfts.item && nfts.head && nfts.head.length > 0) ? nfts.head.length : 0,
                            "glasses": (data.nfts.item && nfts.glasses && nfts.glasses.length > 0) ? nfts.glasses.length : 0,
                            "shirt": (data.nfts.item && nfts.shirt && nfts.shirt.length > 0) ? nfts.shirt.length : 0,
                            "jacket": (data.nfts.item&& nfts.jacket && nfts.jacket.length > 0) ? nfts.jacket.length : 0,
                            "special": (data.nfts.item && nfts.special && nfts.special.length > 0) ? nfts.special.length : 0,
                            "jewellery": (data.nfts.item && nfts.jewellery && nfts.jewellery.length > 0) ? nfts.jewellery.length : 0,
                            "background": (data.nfts.item && nfts.background && nfts.background.length > 0) ? nfts.background.length : 0,
                            "body": (data.nfts.item && data.nfts.body && data.nfts.body.cat && data.nfts.body.cat.length > 0) ? data.nfts.body.cat.length : 0,
                            "character": (data.nfts.character && data.nfts.character["cat dressed"] && data.nfts.character["cat dressed"].length > 0) ? data.nfts.character["cat dressed"].length : 0
                        },
                        "ufo": {
                            "head": (data.nfts.item && nfts.head && nfts.head.length > 0) ? nfts.head.length : 0,
                            "glasses": (data.nfts.item && nfts.glasses && nfts.glasses.length > 0) ? nfts.glasses.length : 0,
                            "shirt": (data.nfts.item && nfts.shirt && nfts.shirt.length > 0) ? nfts.shirt.length : 0,
                            "jacket": (data.nfts.item && nfts.jacket && nfts.jacket.length > 0) ? nfts.jacket.length : 0,
                            "special": (data.nfts.item && nfts.special && nfts.special.length > 0) ? nfts.special.length : 0,
                            "jewellery": (data.nfts.item && nfts.jewellery && nfts.jewellery.length > 0) ? nfts.jewellery.length : 0,
                            "background": (data.nfts.item &&nfts.background && nfts.background.length > 0) ? nfts.background.length : 0,
                            "body": (data.nfts.item &&data.nfts.body && data.nfts.body.ufo && data.nfts.body.ufo.length > 0) ? data.nfts.body.ufo.length : 0,
                            "character": (data.nfts.character && data.nfts.character["ufo dressed"] && data.nfts.character["ufo dressed"].length > 0) ? data.nfts.character["ufo dressed"].length : 0
                        }
                    }
                    console.log(jsonCountData)
                    dispatch(setCountDataState(jsonCountData))
                    //let inventory = JSON.parse(JSON.stringify(nfts).replace("\"t-shirt\":", "\"shirt\":"));
                    console.log(nfts)
                    let newData = (data.nfts.item) ? data.nfts.item : data.nfts
                    if(!data.nfts.item)
                        newData.item = {}
                    let inventory = JSON.parse(JSON.stringify(newData));
                    console.log('ДЕБАГИНК')
                    console.log(inventory)

                    inventory.body = {
                        "cat": (data.nfts.body && data.nfts.body.cat && data.nfts.body.cat.length > 0) ? data.nfts.body.cat : 0,
                        "ufo": (data.nfts.body && data.nfts.body.ufo && data.nfts.body.ufo.length > 0) ? data.nfts.body.ufo : 0
                    }
                    inventory.character = {
                        "cat": (data.nfts.character && data.nfts.character["cat dressed"] && data.nfts.character["cat dressed"].length > 0) ? data.nfts.character["cat dressed"] : 0,
                        "ufo": (data.nfts.character && data.nfts.character["ufo dressed"] && data.nfts.character["ufo dressed"].length > 0) ? data.nfts.character["ufo dressed"] : 0
                    }
                    console.log(inventory)
                    dispatch(setInventoryDataState(inventory))
                    document.dispatchEvent(new CustomEvent('updatedInv'))
                    if(setCountLoaded)
                        setCountLoaded(true)
                }
            } else {
                if (data.code) {
                    if (data.code == 5108)
                        dispatch(selectError("Вашего кошелька нет в whitelist"))
                }
            }
        })
    }
}