import React from 'react';
import styled, {css} from "styled-components";

const Time = ({first1, first2, two1, two2}) => {
    return (
        <Overlay>
            <span>Минт будет
завершен через:</span>
            <TimeBlock>
                <TwoNumbers>
                    <Number><span>{first1}</span></Number>
                    <Number><span>{first2}</span></Number>
                </TwoNumbers>
                <span>:</span>
                <TwoNumbers>
                    <Number><span>{two1}</span></Number>
                    <Number><span>{two2}</span></Number>
                </TwoNumbers>
            </TimeBlock>
        </Overlay>
    );
};

export default Time;

const Overlay = styled.div`
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 23px;
      span{
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-size: 18px;
        line-height: 130%;
        /* or 23px */


        color: #FFFFFF;
      }

  @media (max-width: 800px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`
const TimeBlock = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;
    span{
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-size: 20px;
      line-height: 150%;
      /* identical to box height, or 30px */

      text-align: center;

      color: rgba(255, 255, 255, .3);
    }
      
    `
const TwoNumbers = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 5px;`

const Number = styled.div`
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  gap: 10px;

  width: 36px;
  height: 47px;

  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  
  span{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-size: 18px;
    line-height: 150%;
    /* identical to box height, or 27px */

    text-align: center;

    color: #FFFFFF;

  }
`