import React, {useEffect, useLayoutEffect, useRef} from 'react'
import styled from 'styled-components'
import {motion} from "framer-motion";
import {gsap} from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger'
import {useTranslation} from "react-i18next";

import imgBg from '../assets/Images/collection/collection-bg-min.png'
import imgBgMobile from '../assets/Images/mobile/collection_bg-min.png'
import imgCat1 from '../assets/Images/rarity/cat1-min.png'
import imgCat2 from '../assets/Images/rarity/cat2-min.png'
import imgCat3 from '../assets/Images/rarity/cat3-min.png'
import imgUfo1 from '../assets/Images/rarity/ufo1-min.png'
import imgUfo2 from '../assets/Images/rarity/ufo2-min.png'
import imgUfo3 from '../assets/Images/rarity/ufo3-min.png'
import {calculateFontSize} from "../utils";
import SecretSvg from "../components/SecretSvg";

let ww = window.innerWidth

const Section = styled.section`
	position: relative;
	width: 100vw;
	min-height: 100vh;
	opacity: 0;
	
	@media (max-width: 800px) {
		opacity: 1;
	}
`

const Container = styled.div`
	position: relative;
	width: 100vw;
	min-height: 100vh;
	z-index: 2;
`

const Inner = styled(motion.div)`
	width: 100vw;
	min-height: 100vh;	
	padding: 140px 40px 40px;
	position: relative;
	z-index: 1;	
	
	h2 {
		text-align: center;
		font-family: 'Bebas Neue', sans-serif;
		font-size: ${calculateFontSize(30, 100)};
		line-height: 1;
		max-height: 150px;
		backface-visibility: visible;
	}
	@media (max-width: 800px) {
		padding: 30px 20px 20px;
		
		h2 {
			font-size: 60px;
			line-height: 94%;
			text-transform:uppercase;
		}
	}
`
const List = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
	height: calc(100% - 200px);
	margin:70px auto 0;
	max-width: 1330px;
	
	@media (max-width: 800px) {
		gap: 20px;
		margin-top: 23px;
	}
`
const Card = styled.div`
	position: relative;
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(22.6744px);
	border-radius: 24.186px;
	padding: 36px 10px 0 30px;
	min-height: 480px;
	width: 312px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	
	.title {
		position: relative;
		font-family: 'Bebas Neue', sans-serif;
		font-weight: 700;
		font-size: 32px;
		line-height: 94%;
		z-index: 2;
	}
	
	img {
		display: block;
		position: absolute;
		bottom: 0;
		left: 50%;
		max-width: 98%;
		max-height: 90%;
		transform: translateX(-50%);
	}
	@media (max-width: 800px) {
		border-radius: 12.0022px;
		padding: 31px;
		backdrop-filter: blur(23.301px);
		min-height: 372px;
		max-width: 360px;
		width: 100%;
		
		.title {
			font-size: 28.5767px;
			line-height: 94%;
		}
	}
	
`

const Secret = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	
	svg {
		display: block;
		margin-left: -20px;
	}
	
	@media (max-width: 800px) {
		margin-top: 15px;
		svg {
			height: 200px;
		}
	}
`

const Background = styled.div`
	position: absolute;
	top: -25vh;
	left:-25vw;
	width: 150vw;
	height: 150vh;	
	opacity: 0;
	transform: rotate(45deg);
	background-image: url(${ww > 800 ? imgBg : imgBgMobile});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	z-index: 2;
	pointer-events: none;
`


const RarityList = (props) => {
	gsap.registerPlugin(ScrollTrigger);
	const { t } = useTranslation();

	const sectionRef = useRef(null);
	const containerRef = useRef(null);
	const innerRef = useRef(null);
	const bgRef = useRef(null);

	useEffect(() => {
		setTimeout(() => {
			props.onReady('rarityList')
		}, 300)
	}, []);

	useLayoutEffect(() => {
		let element = sectionRef.current;
		let t1 = gsap.timeline()

		setTimeout(() => {
			t1.to(element, {
				scrollTrigger: {
					trigger: element,
					start: 'top top',
					end: 'bottom bottom',
					scroller: '.App',
					pin: bgRef.current,
					pinSpacing: false,
					scrub: true,
				},
				ease: 'none'
			})
			if(ww > 800) {
				t1.to(element, {
					scrollTrigger: {
						trigger: element,
						start: '20% bottom',
						end: '25% center',
						scroller: '.App',
						scrub: true,
					},
					opacity: 1,
					duration: 10,
					ease: 'none'
				})

				t1.to(containerRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '90% top',
						end: 'bottom top',
						scroller: '.App',
						scrub: true,
					},
					opacity: 0,
					ease: 'none'
				})
				t1.to(sectionRef.current, {
					scrollTrigger: {
						trigger: element,
						start: 'bottom bottom',
						end: 'bottom bottom',
						scroller: '.App',
						scrub: true
					},
					zIndex: -1,
					ease: 'none'
				})
			} else {
				//mobile
				/*t1.to(element, {
					scrollTrigger: {
						trigger: element,
						start: 'top center',
						end: '20% center',
						scroller: '.App',
						scrub: true,
					},
					opacity: 1,
					duration: 10,
					ease: 'none'
				})

				t1.to(containerRef.current, {
					scrollTrigger: {
						trigger: element,
						start: 'bottom center',
						end: 'bottom top',
						scroller: '.App',
						scrub: true,
					},
					opacity: 0,
					ease: 'none'
				})*/
			}

			t1.to(bgRef.current, {
				scrollTrigger: {
					trigger: element,
					start: 'top bottom',
					end: 'bottom top',
					scroller: '.App',
					scrub: true,
				},
				rotate: 720,
				scale: 1,
				opacity: 0.7,
				duration: 10,
				ease: 'none'
			})

			ScrollTrigger.refresh()
		}, 1000)


	}, []);

	return (
		<Section ref={sectionRef}>
			<Container ref={containerRef}>
				<Background ref={bgRef}/>
				<Inner ref={innerRef}>
					<h2>{t('rarity.title')}</h2>
					<List>
						<Card>
							<div className="title">{t('rarity.usual')}</div>
							<img src={imgCat1} alt=""/>
						</Card>
						<Card>
							<div className="title">{t('rarity.epic')}</div>
							<img src={imgCat2} alt=""/>
						</Card>
						<Card>
							<div className="title">{t('rarity.legendary')}</div>
							<img src={imgCat3} alt=""/>
						</Card>
						<Card>
							<div className="title">{t('rarity.secret')}</div>
							<Secret>
								<SecretSvg fill="#EF2ADC"/>
							</Secret>
						</Card>
						<Card>
							<div className="title">{t('rarity.usual')}</div>
							<img src={imgUfo1} alt=""/>
						</Card>
						<Card>
							<div className="title">{t('rarity.epic')}</div>
							<img src={imgUfo2} alt=""/>
						</Card>
						<Card>
							<div className="title">{t('rarity.legendary')}</div>
							<img src={imgUfo3} alt=""/>
						</Card>
						<Card>
							<div className="title">{t('rarity.secret')}</div>
							<Secret>
								<SecretSvg fill="#00ED75"/>
							</Secret>
						</Card>
					</List>
				</Inner>
			</Container>
		</Section>
	)
}

export default RarityList