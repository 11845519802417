import React, {useEffect, useLayoutEffect, useRef} from 'react'
import styled from 'styled-components'
import PageTitle from "../components/PageTitle";
import {gsap, ScrollTrigger} from "gsap/all";
import SecretSvg from "../components/SecretSvg";
import {useTranslation} from "react-i18next";

let ww = window.innerWidth

const Section = styled.section`
	height: 400vh;
	width: 100vw;
	margin-top: -100vh;
	opacity: 0;
	overflow: hidden;
	
	@media (max-width: 800px) {
		height: auto;
		margin-top: 0;
		opacity: 1;
	}
`

const Container = styled.div`
	height: 100vh;
	width: 100vw;
	padding: 157px 50px 0;	
	background: ${props => props.bg};
	
	@media (max-width: 800px) {
		height: auto;
		padding: 40px 23px 50px;
	}
`

const Content = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`

const Title = styled.div`
	position: relative;
	transform-origin: top center;
	
	@media (max-width: 800px) {
		font-weight: 700;
		font-size: 60px;
		line-height: 94%;
	}
`
const InnerWrap = styled.div`
	padding-top: 70px;
	height: calc(100% - 70px);
	flex: 1;
	
	@media (max-width: 800px) {
		height: auto;
		padding-top: 0;
	}
`
const Inner = styled.div`
	width: 100%;
	max-width: 1400px;
	display: flex;
	height: 100%;
	margin: 0 auto;
	
	img {
		display: block;
		max-width: 100%;
	}
	.left {
		width: 59%;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		/*transform: translateY(100%);*/
	}	
	
	.right {
		width: 39%;
		margin-left: 2%;
	}
	
	@media (max-width: 800px) {
		flex-direction: column;
		
		.left {
			margin-top: 34px;
			width: 100%;
			border-bottom: 1px solid #404040;
		}
		
		.right {
			width: 100%;
			margin: 36px 0 0 0;
			
		}
	}
`

const List = styled.div`
	width: 100%;
	max-width: 480px;
	
	@media (max-width: 800px) {
		margin: 0 auto;
	}
`

const Card = styled.div`
	display: block;
	background: ${props => props.bg};
	border-radius: 32px;
	padding: 46px;
	position: relative;
	font-family: 'Bebas Neue', sans-serif;
	font-weight: 700;
	line-height: 0.9;
	padding-bottom: 92%;
	min-height: 600px;
	
	+ div {
		margin-top: 20px;
	}
	
	.rarity {
		position: relative;
		font-size: 42.93px;
		z-index: 2;
	}
	.count {
		position: relative;
		font-size: 82.93px;
		z-index: 2;
	}
	
	.image {
		position: absolute;
		left: 19px;
		right: 19px;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			width: 100%;
		}
		
	}
	
	.secret {
		position: absolute;
		left: 19px;
		right: 19px;
		top: 165px;
		bottom: 19px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	img {
		display: block;
		max-width: 100%;
	}
	
	@media (max-width: 800px) {
		padding: 31px 31px 92% 31px;
		border-radius: 12.0022px;
		backdrop-filter: blur(23.301px);
		min-height: 400px;
		
		.rarity {
			font-size: 28.58px;
			line-height: 26.86px;
		}
		
		.count {
			font-size: 55.2px;
		}
		.secret {
			top: 120px;
		}
	}
`

const Accessories = (props) => {
	gsap.registerPlugin(ScrollTrigger);
	const { t } = useTranslation();

	const sectionRef = useRef(null);
	const containerRef = useRef(null);
	const titleRef = useRef(null);
	const leftRef = useRef(null);
	const rightRef = useRef(null);
	const listRef = useRef(null);

	const {accData} = props

	useEffect(() => {
		setTimeout(() => {
			props.onReady(props.readyName)
		}, 300)
	}, []);

	useLayoutEffect(() => {
		let element = sectionRef.current;
		let t1 = gsap.timeline()

		setTimeout(() => {

			if(ww > 800) {
				t1.to(element, {
					scrollTrigger: {
						trigger: element,
						start: 'top top',
						end: '300 top',
						scroller: '.App',
						scrub: true
					},
					opacity:1,
					zIndex: 2,
					ease: 'none'
				})
				t1.from(titleRef.current, {
					scrollTrigger: {
						trigger: element,
						start: 'top top',
						end: '10% top',
						scroller: '.App',
						scrub: true,
					},
					rotateX: -40,
					y: -20,
					ease: 'none'
				})
				t1.to(containerRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '90% bottom',
						end: 'bottom bottom',
						scroller: '.App',
						scrub: true,
					},
					opacity:0,
					zIndex: -1,
					ease: 'none'
				})
				t1.from(rightRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '15% top',
						end: '30% top',
						scroller: '.App',
						scrub: true
					},
					yPercent: 100,
					ease: 'none'
				})
				t1.from(leftRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '5% top',
						end: '15% top',
						scroller: '.App',
						scrub: true
					},
					yPercent: 100,
					ease: 'none'
				})
				t1.to(listRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '30% top',
						end: '50% top',
						scroller: '.App',
						scrub: true,
					},
					y: `-${listRef.current.offsetHeight - rightRef.current.offsetHeight + 40}`,
					ease: 'none'
				})

				t1.to(leftRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '60% top',
						end: '70% top',
						scroller: '.App',
						scrub: true,
					},
					xPercent: -100,
					opacity: 0,
					ease: 'back.in(1.7)'
				})
				t1.to(rightRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '60% top',
						end: '70% top',
						scroller: '.App',
						scrub: true,
					},
					xPercent: 100,
					opacity: 0,
					ease: 'back.in(1.7)'
				})
				t1.to(titleRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '60% top',
						end: '70% top',
						scroller: '.App',
						scrub: true,
					},
					yPercent: -300,
					ease: 'back.in(1.7)'
				})

				t1.to(element, {
					scrollTrigger: {
						trigger: element,
						start: 'top top',
						end: 'bottom bottom',
						scroller: '.App',
						pin: containerRef.current,
					},
					ease: 'none'
				})

			}else{

			}




			ScrollTrigger.refresh()
		}, 1000)


	}, []);

	const AccItem = (item => {
		return (
			<Card key={item.id} bg={item.bg}>
				<div className="rarity">{t(`accessories.${props.accData.langName}.${item.rarity}.text`)}</div>
				<div className="count">{t(`accessories.${props.accData.langName}.${item.rarity}.count`)}</div>

				{item.img &&
				<div className="image">
					<img src={item.img} alt=""/>
				</div>
				}

				{(item.secret && item.fill) &&
				<div className="secret">
					<SecretSvg fill={item.fill}/>
				</div>
				}
			</Card>
		)
	})

	return (
		<Section ref={sectionRef}>
			<Container ref={containerRef} bg={props.accData.mainBg}>
				<Content>
					<Title ref={titleRef}>
						<PageTitle title={t(`accessories.${props.accData.langName}.title`)}/>
					</Title>
					<InnerWrap>
						<Inner>
							<div ref={leftRef} className="left">
								<img src={accData.mainImg} alt=""/>
							</div>
							<div ref={rightRef} className="right">
								<List ref={listRef}>
									{accData.cards.map(item => AccItem(item))}
								</List>
							</div>
						</Inner>
					</InnerWrap>
				</Content>
			</Container>
		</Section>
	)
}

export default Accessories