import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes, useNavigate  } from "react-router-dom";

import { Provider } from 'react-redux'
import store from './store/store'

import 'normalize.css'
import './i18n';
import App from "./App";

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<Suspense fallback={''}>
				<App/>
			</Suspense>
		</BrowserRouter>
	</Provider>,
  document.getElementById('root')
);
