import React, {useEffect, useState} from 'react';
import styled, {css} from "styled-components";
import {apiServer, mintData, SuccessSVG} from "../../../data/data";
import {Scrollbar} from "react-scrollbars-custom";
import DayList from "../List/DayList";
import axios from "axios";
import {selectMain} from "../../../store/features/modalSelectorState";
import {useDispatch, useSelector} from "react-redux";
import api from "../../../api/Mint"
import CloseModalMob from "../UI/Buttons/Mobile/CloseModalMob";
import CloseModal from "../UI/Buttons/CloseModal";

const isMobile = window.innerWidth <= 800

const History = () => {
    const [history, setHistory] = useState(null);
    const [apiData, setApiData] = useState(null);
    const dispatch = useDispatch()
    const DeLabConnector = useSelector((state) => state.authSelector.connector)
    const [comission, setComission] = useState(0);

    useEffect(()=>{
        updateHistory()
    }, [])


    useEffect(()=>{
        if(apiData){
            let newHistory = {}
            apiData.forEach(el=>{
                let date = new Date(el.created_at);
                console.log(date.toLocaleDateString())
                if(newHistory && newHistory[date.toLocaleDateString()]){
                    newHistory[date.toLocaleDateString()].push(el)
                }else{
                    newHistory[date.toLocaleDateString()] = []
                    newHistory[date.toLocaleDateString()].push(el)
                }

            })
            setHistory({...newHistory})
            document.dispatchEvent(new CustomEvent('updatedHistory'))
            console.log(newHistory)
        }
    }, [apiData])


    async function updateHistory(){
        api.getHistory(DeLabConnector.address)
            .then(data =>{
                setApiData([...data.operation_history])
            })
    }

    return (
        <Overlay>
            <Scrollbar noScrollX={true}
                       thumbYProps={{style: {background: 'rgba(255, 255, 255, 0.3)'}}}
            >

            {isMobile
                ?
                <CloseMobBlock onClick={()=>dispatch(selectMain())}>
                    <CloseModalMob/>
                </CloseMobBlock>
                :
                <CloseModal onClick={()=>dispatch(selectMain())}/>
            }


            <Window>
                <Title>История</Title>
                {
                    history &&
                    <DayList list={history} updateHistory={updateHistory}/>
                }
            </Window>
            </Scrollbar>
        </Overlay>
    );
};

export default History;

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0, .5);
  z-index: 9999;

  @media (max-width: 800px) {
    background-color: #161616;
  }

`

const Window = styled.div`
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  gap: 40px;

  position: relative;
  width: 482px;
  left: 0;
  right: 0;
  margin: 120px auto 100px;
  
  background: #161616;
  border-radius: 22px;

  @media (max-width: 800px) {
    padding: 20px 20px;
    gap: 30px;
    margin: 0px 0px;
    position: relative;
    min-width: 100%;
    width: 100%;
    height: calc(100vh - calc(100vh - 100%));
    left: 0;
    top: 0;
    border-radius: 0px;
  }
`

const Title = styled.div`
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    /* identical to box height */
    text-align: center;
    color: #FFFFFF;
`



const CloseMobBlock = styled.div`
  position: absolute;
  z-index: 1;
  right: 20px;
  top: 20px;
`


const TrackY = styled.span`
      background: red;
    `