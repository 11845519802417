import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import styled, {ThemeProvider} from 'styled-components'
import CategoryCard from "./CategoryCard";


const List = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	@media (max-width: 800px) {
		gap: 12px;
	}
`

const CategoriesList = (props) => {
	const {items} = props

	if(!items) return false

	return (
		<List>
			{Object.keys(items).map(key => {
				return (
					<CategoryCard
						className={key}
						key={`fitting-card-${key}`}
						transId={items[key].transId}
						category={items[key].categoryId}
						image={items[key].image}
					/>
				)
			})}
		</List>
	)
}

export default CategoriesList