import {ThemeProvider} from "styled-components";
import GlobalStyles from "../styles/GlobalStyles";
import {light} from "../styles/Themes";
import {LocomotiveScrollProvider, useLocomotiveScroll} from 'react-locomotive-scroll'
import React, {useRef, useState, useEffect, useCallback} from "react";
/*const { pathname } = useLocation() // With react-router*/

import Loading from "../sections/Loading";
import NavBar from "../components/NavBar";
import Home from "../sections/Home";
import Collection from "../sections/Collection";
import Ufo from "../sections/Ufo";
import Accessories from "../sections/Accessories";

import 'locomotive-scroll/dist/locomotive-scroll.css'
import {AnimatePresence} from "framer-motion";
import ScrollTriggerProxy from "../components/ScrollTriggerProxy";
import {accessoriesDataUfo, accessoriesDataCat} from "../data/data";
import Universe from "../sections/Universe";
import Game from "../sections/Game";
import Integration from "../sections/Integration";
import Roadmap from "../sections/Roadmap";
import Subscribe from "../sections/Subscribe";
import Cat from "../sections/Cat";
import TotalCount from "../sections/TotalCount";
import CardList from "../sections/CardList";
import CountByToy from "../sections/CountByToy";
import RarityList from "../sections/RarityList";
import Customization from "../sections/Customization";
import {browserName} from 'react-device-detect'
import {Route} from "react-router-dom";

function HomePage(props) {

	let percentLoad = 0
	let readyCount = 0
	const [loaded, setLoaded] = useState(false)

	const {scroll} = useLocomotiveScroll();

	const [readyComponents, setReadyComponents] = useState({
		home: false,
		collection: false,
		navigation: true,
		cat: false,
		ufo: false,
		totalCount: false,
		cardList: false,
		countByToy: false,
		rarityList: false,
		customization: false,
		accessoryCat: false,
		accessoryUfo: false,
		universe: false,
		game: false,
		integration: false,
		roadmap: false,
		subscribe: false
	})

	const onReadyComponent = useCallback((key, value = true) => {
		readyComponents[key] = value
		setReadyComponents({...readyComponents})
	}, [readyComponents])

	useEffect(() => {
		let newPercent

		Object.keys(readyComponents)
			.map( key => readyComponents[key] && readyCount++)
		newPercent = 100 / Object.keys(readyComponents).length * readyCount

		if(newPercent >= percentLoad) {
			props.setPercent(newPercent)
			percentLoad = newPercent
		}

		if(Object.keys(readyComponents).length === readyCount) {

			//Trigger resize to Fix some issues

			window.dispatchEvent(new Event('resize'));
			setTimeout(() => {
				setLoaded(true)
				props.setLoaded(true)
			}, 1000)

		}else {
			return () => {
				setLoaded(false)
				props.setLoaded(false)
			};
		}
	}, [readyComponents, props.loaded])

  return (
  	<>
			<Home data-scroll-section loaded={props.ready} onReady={onReadyComponent}/>
			<Collection data-scroll-section loaded={props.ready} onReady={onReadyComponent}/>
			<Cat data-scroll-section loaded={props.ready} onReady={onReadyComponent}/>
			<Ufo data-scroll-section loaded={props.ready} onReady={onReadyComponent}/>
			<TotalCount  data-scroll-section loaded={props.ready} onReady={onReadyComponent}/>
			<CardList data-scroll-section loaded={props.ready} onReady={onReadyComponent}/>
			<CountByToy data-scroll-section loaded={props.ready} onReady={onReadyComponent}/>
			<RarityList data-scroll-section loaded={props.ready} onReady={onReadyComponent}/>
			<Customization data-scroll-section loaded={props.ready} onReady={onReadyComponent}/>
			<Accessories accData={accessoriesDataCat} loaded={props.ready} readyName={'accessoryCat'} data-scroll-section onReady={onReadyComponent}/>
			<Accessories accData={accessoriesDataUfo} loaded={props.ready} readyName={'accessoryUfo'} data-scroll-section onReady={onReadyComponent}/>
			<Universe  data-scroll-section loaded={props.ready} onReady={onReadyComponent}/>
			<Game  data-scroll-section loaded={props.ready} onReady={onReadyComponent}/>
			<Integration data-scroll-section loaded={props.ready} onReady={onReadyComponent}/>
			<Roadmap data-scroll-section loaded={props.ready} onReady={onReadyComponent}/>
			<Subscribe data-scroll-section loaded={props.ready} onReady={onReadyComponent}/>
		</>
	);
}

export default HomePage;
