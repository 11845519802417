import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {motion} from "framer-motion";
import {MouseParallaxChild, MouseParallaxContainer} from "react-parallax-mouse";
import {gsap} from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger'
import {useTranslation} from "react-i18next";

import pImg2 from "../assets/Images/collection/p2-min.png";
import pImg3 from "../assets/Images/collection/p3-min.png";
import pImg5 from "../assets/Images/collection/p5-min.png";
import pImg6 from "../assets/Images/collection/p6-min.png";
import pImg8 from "../assets/Images/collection/p8-min.png";
import pImg10 from "../assets/Images/collection/p10-min.png";
import pImg14 from "../assets/Images/collection/p14-min.png";
import pImg17 from "../assets/Images/collection/p17-min.png";
import pInstead from "../assets/Images/mobile/parallax_count_instead-min.png";

let ww = window.innerWidth

const Section = styled(motion.section)`
position: relative;
height: 300vh;
width: 100vw;
z-index: 1;

	@media (max-width: 800px) {
		height: auto;
	}
`

const Container = styled.div`
	position: relative;
	height: 100vh;
	width: 100vw;
	
	transform: scale(7);
	opacity: 0;
	
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	
	color: ${props => props.theme.colors.green};
	
	z-index: 6;
	
	span {
		font-weight: 600;
		font-size: 60px;
		line-height: 1.2;
		
		@media (max-width: 800px) {
			font-size: 20.5128px;
			line-height: 27px;
		}
	}
	
	div{
		font-family: 'Bebas Neue', sans-serif;
		font-weight: 700;
		font-size: 48vmin;
		line-height: 1;
	}
	
	@media (max-width: 800px) {
		height: 100vh;
		transform: none;
		opacity: 1;
	}
`

const Parallax = styled.div`
	position: absolute;
	left: 0;
	top:0;
	height: 100vh;
	width: 100vw;
	z-index: 4;
`
const layerStyle = {
	'position': 'absolute',
	'width': '100vw',
	'height':'100vh',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
}
const ParallaxInstead = styled.div`
	position: absolute;
	left: 0;
	top: 52px;
	width: 100%;
	height: 100%;
	
	img {
		width: 100%;
		height: auto;
	}
`
const Image = styled(motion.img)`
	position: absolute;
	display: block;
	${props => props.blur && 'filter: blur('+ props.blur +'px);' }
`

const TotalCount = (props) => {
	gsap.registerPlugin(ScrollTrigger);
	const { t } = useTranslation();

	const [number, setNumber] = useState(5555);

	const numRef = useRef(null);
	const sectionRef = useRef(null);
	const containerRef = useRef(null);
	const parallaxRef = useRef(null);

	const num = {current: number}

	useEffect(() => {
		setTimeout(() => {
			props.onReady('totalCount')
		}, 300)
	}, []);

	useLayoutEffect(() => {
		let element = sectionRef.current;
		let t1 = gsap.timeline()

		{ww > 800 &&

			setTimeout(() => {
				t1.to(element, {
					scrollTrigger: {
						trigger: element,
						start: 'top top',
						end: 'bottom bottom',
						scroller: '.App',
						pin: containerRef.current,
						pinSpacing: false,
						scrub: true,
					},
					ease: 'none',
				})

				t1.to(containerRef.current, {
					scrollTrigger: {
						trigger: element,
						start: 'top top',
						end: '15% top',
						scroller: '.App',
						scrub: true
					},
					opacity: 1,
					duration: 10,
					ease: 'none',
				})

				t1.to(containerRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '-50% top',
						end: '-49% top',
						scroller: '.App',
						scrub: true,
					},
					display: 'flex',
					ease: 'none'
				})

				t1.to(containerRef.current, {
					scrollTrigger: {
						trigger: element,
						start: 'top top',
						end: '20% top',
						scroller: '.App',
						scrub: true,
					},
					scale: 1,
					duration: 10,
					ease: 'none'
				})

				t1.to(num, {
					scrollTrigger: {
						trigger: element,
						start: '19% top',
						end: '35% top',
						scroller: '.App',
						scrub: true,
					},
					current: 6282,
					duration: 5,
					ease: 'none',
					onUpdate: changeNumber
				})

				t1.to(sectionRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '37% top',
						end: '50% top',
						scroller: '.App',
						scrub: true,
					},
					opacity: 0,
					duration: 10,
					ease: 'none'
				})
				t1.fromTo(containerRef.current, {
						scrollTrigger: {
							trigger: element,
							start: '37% top',
							end: '55% top',
							scroller: '.App',
							scrub: true,
						},
						scale: 1,
						duration: 10,
						ease: 'none'
					},
					{
						scrollTrigger: {
							trigger: element,
							start: '37% top',
							end: '55% top',
							scroller: '.App',
							scrub: true,
						},
						scale: 0,
						duration: 10,
						ease: 'none'
					})

				t1.to(containerRef.current, {
					scrollTrigger: {
						trigger: element,
						start: 'bottom bottom',
						end: '+=1% bottom',
						scroller: '.App',
						scrub: true,
					},
					display: 'none',
					ease: 'none'
				})

				t1.to(sectionRef.current, {
					scrollTrigger: {
						trigger: element,
						start: 'bottom bottom',
						end: 'bottom bottom',
						scroller: '.App',
						scrub: true,
					},
					zIndex: -1,
					ease: 'none'
				})

				t1.to(parallaxRef.current, {
					scrollTrigger: {
						trigger: element,
						start: '45% top',
						end: '55% top',
						scroller: '.App',
						scrub: true,
					},
					opacity: 0,
					scale: 6,
					zIndex: -1,
					display: 'none',
					duration: 10,
					ease: 'none'
				})

				ScrollTrigger.refresh()
			}, 1000)

		}


	}, []);

	function changeNumber() {
		setNumber(num.current.toFixed())
	}

	return (
		<Section id="collection" ref={sectionRef}>
			<Container ref={containerRef}>
				<Parallax ref={parallaxRef}>
					{ww > 800 ?
					<MouseParallaxContainer
						className="parallax"
						/*useWindowMouseEvents={true}*/
						containerStyles={{
							position: 'absolute',
							left:0,
							top:0,
							width: '100vw',
							height: '100vh',
							overflow: 'visible'
						}}
						resetOnLeave
					>
						<MouseParallaxChild
							factorX={0.12}
							factorY={0.1}
							updateStyles={layerStyle}
						>
							<Image style={{top:'1vh', left:'45vw', x: '-50%', y: '-50%', blurRadius: 2}}
										 blur={5}
										 width={210}
										 initial={{opacity:0, zoom: 0.7}}
										 whileInView={{opacity: 1, zoom: 1}}
										 viewport={{ once: false }}
										 transition={{duration: 2,ease: 'easeOut'}}
										 src={pImg8} alt=""/>
						</MouseParallaxChild>
						<MouseParallaxChild
							factorX={0.09}
							factorY={-0.12}
							updateStyles={layerStyle}
						>
							<Image style={{top:'10vh', right:'3vw', x: '10%', y: '10%'}}
										 width={137}
										 initial={{opacity:0, zoom: 0.7}}
										 whileInView={{opacity: 1, zoom: 1}}
										 viewport={{ once: false }}
										 transition={{duration: 2,ease: 'easeOut'}}
										 src={pImg5} alt=""/>
						</MouseParallaxChild>

						<MouseParallaxChild
							factorX={0.2}
							factorY={0.1}
							updateStyles={layerStyle}
						>
							<Image style={{bottom:'10vh', left:'5vw', x: '-30%', y: '40%'}}
										 width={130}
										 blur={10}
										 initial={{opacity:0, zoom: 0.7}}
										 whileInView={{opacity: 1, zoom: 1}}
										 viewport={{ once: false }}
										 transition={{duration: 2,ease: 'easeOut'}}
										 src={pImg2} alt=""/>
						</MouseParallaxChild>

						<MouseParallaxChild
							factorX={0.1}
							factorY={0.2}
							updateStyles={layerStyle}
						>
							<Image style={{top:'25vh', left:'20vw', x: '-50%'}}
										 width={176}
										 blur={4}
										 initial={{opacity:0, zoom: 0.7}}
										 whileInView={{opacity: 1, zoom: 1}}
										 viewport={{ once: false }}
										 transition={{duration: 2,ease: 'easeOut'}}
										 src={pImg6} alt=""/>
						</MouseParallaxChild>

						<MouseParallaxChild
							factorX={0.04}
							factorY={0.2}
							updateStyles={layerStyle}
						>
							<Image style={{top:'60vh', left: '-6vw' , y: '-50%', rotate: '70deg'}}
										 width={328}
										 initial={{opacity:0, zoom: 0.7}}
										 whileInView={{opacity: 1, zoom: 1}}
										 viewport={{ once: false }}
										 transition={{duration: 2,ease: 'easeOut'}}
										 src={pImg10} alt=""/>
						</MouseParallaxChild>
						<MouseParallaxChild
							factorX={0.04}
							factorY={0.2}
							updateStyles={layerStyle}
						>
							<Image style={{bottom:'11vh', left: '40vw' , y: '-50%'}}
										 width={233}
										 initial={{opacity:0, zoom: 0.7}}
										 whileInView={{opacity: 1, zoom: 1}}
										 viewport={{ once: false }}
										 transition={{duration: 2,ease: 'easeOut'}}
										 src={pImg17} alt=""/>
						</MouseParallaxChild>
						<MouseParallaxChild
							factorX={0.04}
							factorY={0.2}
							updateStyles={layerStyle}
						>
							<Image style={{bottom:'-7vh', right: '30vw'}}
										 width={214}
										 initial={{opacity:0, zoom: 0.7}}
										 whileInView={{opacity: 1, zoom: 1}}
										 viewport={{ once: false }}
										 transition={{duration: 2,ease: 'easeOut'}}
										 src={pImg14} alt=""/>
						</MouseParallaxChild>
						<MouseParallaxChild
							factorX={0.04}
							factorY={0.2}
							updateStyles={layerStyle}
						>
							<Image style={{bottom:'2vh', right: '-10vw'}}
										 width={296}
										 blur={6}
										 initial={{opacity:0, zoom: 0.7}}
										 whileInView={{opacity: 1, zoom: 1}}
										 viewport={{ once: false }}
										 transition={{duration: 2,ease: 'easeOut'}}
										 src={pImg3} alt=""/>
						</MouseParallaxChild>
					</MouseParallaxContainer>
						:
						<ParallaxInstead>
							<img src={pInstead} alt=""/>
						</ParallaxInstead>
						}
				</Parallax>
				<span>{t('collection.title')}</span>
				<div ref={numRef}>{ww > 800 ? number.toString().padStart(4, '0') : 6282}</div>
			</Container>
		</Section>
	)
}

export default TotalCount