import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import styled from "styled-components";

import {gsap} from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useTranslation } from "react-i18next";
import { useNavigate  } from 'react-router-dom';

import imgLogo from '../assets/Images/logo_small-min.png'
import imgTg from '../assets/Svgs/tg.svg'
import {tgLink} from "../data/data";
import {useLocomotiveScroll} from "react-locomotive-scroll";
import imgLogoutMintpage from "../assets/Images/mint/exit.png"
import {DeLabConnect} from "@delab-team/connect";
import {useDispatch, useSelector} from "react-redux";
import {selectHistory} from "../store/features/modalSelectorState";
import api from "../api/Mint"
import OnOutsiceClick from "react-outclick";

let ww = window.innerWidth


const BurgerSVG = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M2.49984 5.9987H17.4998C17.7209 5.9987 17.9328 5.9109 18.0891 5.75462C18.2454 5.59834 18.3332 5.38638 18.3332 5.16536C18.3332 4.94435 18.2454 4.73239 18.0891 4.57611C17.9328 4.41983 17.7209 4.33203 17.4998 4.33203H2.49984C2.27882 4.33203 2.06686 4.41983 1.91058 4.57611C1.7543 4.73239 1.6665 4.94435 1.6665 5.16536C1.6665 5.38638 1.7543 5.59834 1.91058 5.75462C2.06686 5.9109 2.27882 5.9987 2.49984 5.9987ZM17.4998 13.999H2.49984C2.27882 13.999 2.06686 14.0868 1.91058 14.2431C1.7543 14.3994 1.6665 14.6113 1.6665 14.8324C1.6665 15.0534 1.7543 15.2653 1.91058 15.4216C2.06686 15.5779 2.27882 15.6657 2.49984 15.6657H17.4998C17.7209 15.6657 17.9328 15.5779 18.0891 15.4216C18.2454 15.2653 18.3332 15.0534 18.3332 14.8324C18.3332 14.6113 18.2454 14.3994 18.0891 14.2431C17.9328 14.0868 17.7209 13.999 17.4998 13.999ZM17.4998 9.16829H2.49984C2.27882 9.16829 2.06686 9.25609 1.91058 9.41237C1.7543 9.56865 1.6665 9.78061 1.6665 10.0016C1.6665 10.2226 1.7543 10.4346 1.91058 10.5909C2.06686 10.7472 2.27882 10.835 2.49984 10.835H17.4998C17.7209 10.835 17.9328 10.7472 18.0891 10.5909C18.2454 10.4346 18.3332 10.2226 18.3332 10.0016C18.3332 9.78061 18.2454 9.56865 18.0891 9.41237C17.9328 9.25609 17.7209 9.16829 17.4998 9.16829Z" fill="white"/>
	</svg>;
const ExitSVG = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g opacity="0.4">
		<path d="M4.4495 13.9418H6.9855C7.38695 13.9418 7.71277 14.269 7.71277 14.6738C7.71277 15.0787 7.38695 15.4059 6.9855 15.4059H4.4495C3.34914 15.4059 2.45459 14.5047 2.45459 13.3971V4.46311C2.45459 3.35624 3.34914 2.45508 4.4495 2.45508H6.9855C7.38695 2.45508 7.71277 2.78231 7.71277 3.18714C7.71277 3.59123 7.38695 3.91919 6.9855 3.91919H4.4495C4.15132 3.91919 3.90914 4.16297 3.90914 4.46311V13.3971C3.90914 13.6973 4.15132 13.9418 4.4495 13.9418Z" fill="white"/>
		<path d="M15.2646 8.65059C15.3388 8.82921 15.3388 9.03126 15.2646 9.20988C15.2275 9.29992 15.1744 9.38045 15.1068 9.44853L11.9381 12.6388C11.7955 12.7816 11.6101 12.8533 11.4239 12.8533C11.2377 12.8533 11.0515 12.7816 10.9097 12.6388C10.6253 12.3526 10.6253 11.8892 10.9097 11.6037L12.8377 9.66229H6.98533C6.58388 9.66229 6.25806 9.33433 6.25806 8.93023C6.25806 8.52614 6.58388 8.19817 6.98533 8.19817H12.8377L10.9097 6.25676C10.6253 5.97126 10.6253 5.50786 10.9097 5.22163C11.1933 4.93613 11.6537 4.93613 11.9381 5.22163L15.1068 8.41267C15.1744 8.48002 15.2275 8.56054 15.2646 8.65059Z" fill="white"/>
	</g>
</svg>;
const Notice = <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
	<circle cx="4" cy="4" r="4" fill="#F33A33"/>
</svg>;

const NavBar = (props) => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const dispatch = useDispatch();

	const selectedAuth = useSelector((state) => state.authSelector.auth)
	const selectedConnector = useSelector((state) => state.authSelector.connector)

	const [menuOpened, setMenuOpened] = useState(false);
	//const [lang, setLang] = useState(i18n.language);
	gsap.registerPlugin(ScrollTrigger);

	const {scroll} = useLocomotiveScroll();

	const isIndexPage = pathname.split('/').length <= 2

	const handleScroll = (id, offset = window.innerHeight + window.innerHeight / 2) => {
		let el = document.querySelector(id)

		setMenuOpened(false)
		scroll.scrollTo(el, {
			offset,
			duration: '5000',
			easing: [0.25, 0.0, 0.35, 1.0]
		})
	}

	const sectionRef = useRef(null)
	const menuRef = useRef(null)
	const logoRef = useRef(null)
	const tgRef = useRef(null)

	const location = useLocation();
	const [openBurger, setOpenBurger] = useState(false);
	const DeLabConnector = useSelector((state) => state.authSelector.connector)
	const [summNotice, setSummNotice] = useState(0);
	const selectedModal = useSelector((state) => state.modalSelector.modal)

	useEffect(() => {
		if(location.pathname === `/${i18n.language}/mint` && DeLabConnector){
			api.getHistoryFlags(DeLabConnector.address)
				.then(data=>{
					console.log(data)
					setSummNotice(data.notifications_count)
				})
		}
	}, [DeLabConnector, selectedModal]);



	const changeLanguage = (lang) => {
		i18n.changeLanguage(lang);
		const pathArr = window.location.pathname.split('/')
		pathArr[1] = lang
		const pathString = `${pathArr.join('/')}`
		navigate(pathString);
	}

	useEffect(() => {
		document.title = t('title')
	}, [i18n.language]);

	useEffect(() => {
		setTimeout(() => {
			props.onReady(true)
		}, 300)

		return() => {
			props.onReady(false)
		}
	}, []);

	useLayoutEffect(() => {
		if(props.loaded){
			const q = gsap.utils.selector(menuRef.current)

			gsap.from(q('li'), {
				y: -100,
				duration: 1,
				ease: "back.out(1.7)",
				stagger: {
					from: "edges",
					amount: .3
				}
			});
			gsap.from(tgRef.current, {x: 100, y: -100, duration: 1.5, delay: 0, ease: "elastic.out(.6, 0.5)"});
			//if(!isIndexPage) gsap.from(logoRef.current, {y:-100, duration: 1.5, ease: 'back.out(2.7)'})
			if(!isIndexPage) gsap.from(logoRef.current, {top:-180, duration: 1, ease: "back.out(2.7)"});
		}
	}, [props.loaded]);


		useLayoutEffect(() => {
			let element = document.getElementById('home');
			let wrapper = document.getElementById('app');
			let t1 = gsap.timeline()

			if(isIndexPage) {

				setTimeout(() => {
					t1.to(logoRef.current, {
							scrollTrigger: {
								trigger: wrapper,
								start: '600 top',
								end: '+=600 top',
								scroller: '.App',
								scrub: true,
							},
							y: 0,
							ease: 'back.out(2.7)'
						})

					ScrollTrigger.refresh()
				}, 1000)
			}

		}, []);


	return (
		<Section ref={sectionRef} isOpened={menuOpened} isIndex={isIndexPage}>
			<Logo ref={logoRef} isIndex={isIndexPage} onClick={() => handleScroll('#home', '0')}>
				<img src={imgLogo} alt=""/>
			</Logo>

			<MobileMenu className={menuOpened ? 'active' : ''}>
				{(ww <= 1110 && summNotice)
					?
					<MobileWarns>
						<span>{summNotice}</span>
					</MobileWarns>
					:
					<div></div>
				}
				<span className="toggle-menu" onClick={() => setMenuOpened(!menuOpened)}> </span>
				{
					((ww <= 1110) ||
						(ww > 1110 && location.pathname !== `/${i18n.language}/mint`))
						&&
						<MenuItems ref={menuRef} isIndex={isIndexPage}>
							<MenuItem><Link onClick={() => setMenuOpened(false)} className={(`/${i18n.language}` === pathname) ? 'disabled' : ''} to={`/${i18n.language}`}>{t('menu.about')}</Link></MenuItem>
							<MenuItem><Link onClick={() => setMenuOpened(false)} className={`/${i18n.language}/fitting` === pathname ? 'disabled' : ''} to={`/${i18n.language}/fitting`}>{t('menu.fitting')}</Link></MenuItem>
							<MenuItem><Link onClick={() => setMenuOpened(false)} className={`/${i18n.language}/mint` === pathname ? 'disabled' : ''} to={`/${i18n.language}/mint`}>{t('menu.mint')}</Link></MenuItem>
							{
								location.pathname === `/${i18n.language}/mint` &&
								<SemiItems>
									<MenuItem
										onClick={() => {
											setMenuOpened(false)
											dispatch(selectHistory())
										}}
									>
										<Link
											className={''}
										>
											История {Notice}
										</Link>
									</MenuItem>

								{selectedAuth &&
									<MenuItem>
										<Exit
											onClick={() => {
											selectedConnector.disconnect()
											navigate(`/${i18n.language}/fitting`);
										}}
											className={''}
										>
											{ExitSVG}
										</Exit>
									</MenuItem>
								}
								</SemiItems>
							}
						</MenuItems>

				}

				<RightMenu ref={tgRef} isIndex={isIndexPage}>
					{
						(location.pathname == `/${i18n.language}/mint` && ww > 1110) &&
						<OnOutsiceClick
							onOutsideClick={()=>setOpenBurger(false)}
						>
						<MintMenu>
							<Dropdown
								onClick={()=>setOpenBurger(!openBurger)}
									>
								<Burg>
									{BurgerSVG}
								</Burg>
								{(summNotice)
									?
									<DropdownWarns>
										<span>{summNotice}</span>
									</DropdownWarns>
									:
									<div></div>
								}
								{openBurger &&
									<DropdownContent>
										<DropdownItems>
											<DropdownItem to={`/${i18n.language}`}>
												{t('menu.about')}
											</DropdownItem>
											<DropdownItem to={`/${i18n.language}/fitting`}>
												{t('menu.fitting')}
											</DropdownItem>
											<DropdownItem to={`/${i18n.language}/mint`}>
												{t('menu.mint')}
											</DropdownItem>
											<div onClick={()=>dispatch(selectHistory())}>
												{summNotice
													?
													<DropdownItem>История {Notice}</DropdownItem>
													:
													<DropdownItem>История</DropdownItem>
												}
											</div>
											{selectedAuth &&
												<DropdownItem
													src={imgLogoutMintpage}
													onClick={() => {
														selectedConnector.disconnect()
														navigate(`/${i18n.language}/fitting`);
													}}
												>
													{ExitSVG}
												</DropdownItem>
											}
										</DropdownItems>
									</DropdownContent>
								}
							</Dropdown>
						</MintMenu>
						</OnOutsiceClick>
					}



					{ww > 1110 && <LangMenu>
						<LangBtn className={i18n.language === 'ru' ? 'disabled' : ''} onClick={() => changeLanguage('ru')}>
							RU
						</LangBtn>
						<LangBtn className={i18n.language === 'en' ? 'disabled' : ''} onClick={() => changeLanguage('en')}>
							EN
						</LangBtn>
					</LangMenu>}
					<TGLink target="_blank" rel="noreferrer" href={tgLink}>
						<img src={imgTg} alt="Telegram"/>
						Telegram
					</TGLink>
				</RightMenu>
				{ww <= 1110 &&
				<LangMenu>

					<LangBtn className={i18n.language === 'ru' ? 'disabled' : ''} onClick={() => {
						changeLanguage('ru')
						setMenuOpened(false)
					}}>
						RU
					</LangBtn>
					<LangBtn className={i18n.language === 'en' ? 'disabled' : ''} onClick={() => {
						changeLanguage('en')
						setMenuOpened(false)
					}}>
						EN
					</LangBtn>
				</LangMenu>
				}
			</MobileMenu>

		</Section>
	)
}

export default NavBar

const Section = styled.section`
	position: fixed; 
	top:24px;
	left:0;
	width: ${props => props.isIndex ? '100vw' : 'calc(100% - 544px)'};
	
	padding: 0 40px;
	
	color: ${props => props.theme.colors.text};
	
	display: flex;
	justify-content: center;
	align-items: center;
	z-index:1000;	
	
	@media (max-width: 1400px){
		width: ${props => props.isIndex ?
	'100vw'
	:
	props => props.isOpened ?
		'100vw'
		:
		'60%'
}
	
	}
	
	@media (max-width: 1110px){
		top: 0;
	}
	@media (max-width: 800px){
		width: 100vw;
	}
`

const Logo = styled.div`
	position: absolute;
	top: -4px;
	left: 40px;	
	width:103px;
	height: 67px;
	cursor: pointer;
	${props => props.isIndex ?
	'transform: translateY(-180px);'
	:
	'transform: translateY(0)!important;'
}
	
	a, img {
		display: block;
	}
	img {
		max-width: 100%;
	}
	
	@media (max-width: 1110px){
		width: 61px;
		top: 27px;
		left: 21px;
	}
`

const MenuItems = styled.ul`
	display: flex;
	align-items: center;
	list-style: none;
	
	min-height: 40px;
	
	@media (max-width: 1474px) {
		margin-left: -100px;
	}
	
	@media (max-width: 1110px) {
		padding: 40px 0 31px;
		flex-direction: column;
		align-items: flex-end;
		text-align: right;
	}
`
const SemiItems = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-self: flex-end;
`

const MenuItem = styled.li`
	display: flex;
	align-items: center;
	list-style: none;
	padding: 0 14px;
	
	img{
		width: 18px;
		height: 18px;
	}
	svg{
		align-self: flex-end;
	}
	
	div, a, button {
		cursor: pointer;
		font-weight: 500;
		font-size: 15px;
		line-height: 18px;
		border: none;
		background: none;
		color: #fff;
		
		text-shadow: 1px 1px 2px #000;

		letter-spacing: 0.04em;
		text-transform: uppercase;
		/*opacity: 0.6;*/
		transition: opacity 0.3s 0s ease;
		
		&.disabled {
			opacity: 0.6;
			pointer-events: none;
		}
		
		:hover{
			opacity: 1;
		}
	}
	
	@media (max-width: 1110px) {
		margin-top: 28px;
		padding: 0 16px;
		div, a {
			font-size: 28px;
			line-height: 33px;
		}

		img{
			width: 26px;
			height: 26px;
		}
	}
`

const Exit = styled.div`
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	svg{
		width: 26px;
		height: 26px;
		align-self: flex-end;
	}
`

const RightMenu = styled.div`
	position: absolute;
	right: 40px;
	top: 0;
	display: flex;
	align-items: center;
	@media (max-width: 1110px) {
		position: static;
		margin-right: 16px;	
	}
	
`

const TGLink = styled.a`
	
	display: flex;
	align-items: center;
	justify-content: center;

	padding: 5px 16px;
	height: 40px;

	font-size: 17px;
	line-height: 20px;

	background: #000000;
	border-radius: 61px;
	margin-left: 24px;

	img {
		display: block;
		width: 24px;

		margin-right: 4px;
	}

	@media (max-width: 1110px) {
		margin-top: 40px;
		font-size: 24px;
		line-height: 28px;
		background-color:#fff;
		color: #000;
		height: 53px;
		padding: 5px 20px 5px 23px;

		img {
			margin-right: 7px;
			width: 27px;
			filter: invert(100%);
		}
		
	}`

const MobileMenu = styled.div`
	.toggle-menu {
		display: none;
		position: absolute;
		width: 40px;
		height: 40px;
		right: 0;
		top: 0;
		transition: 0.3s 0s ease;
		border-radius: 50%;
		
		&:before, &:after {
			content: '';
			display: block;
			position: absolute;
			height: 1px;
			width: 14px;
			left: 50%;
			transform: translateX(-50%);
			background-color:#fff;
			transition: 0.3s 0.3s ease;
		}
		&:before {
			top: 17px;
		}
		&:after {
			bottom: 17px;
		}
	}
	@media (max-width: 1110px) {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		position: absolute;
		right: 12px;
		top:12px;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		
		background-color:#000;
		transition: right 0.3s 0s ease, 
								top 0.3s 0s ease, 
								width 0.6s 0s ease, 
								height 0.6s 0s ease, 
								border-radius 0.6s 0s ease;
								
		.toggle-menu{
			display: block;
		}
								
		&:not(.active){
			ul, div {
				opacity: 0;
				visibility: hidden;
				transition: 0.3s 0s ease;
			}
		}
		
		&.active {
			right: 0;
			top: 0;
			width: 100vw;
			height: 100vh;
			border-radius: 0;
			
			transition: right 0.3s 0s ease, 
									top 0.3s 0s ease, 
									width 0.6s 0.3s ease, 
									height 0.6s 0.3s ease, 
									border-radius 1.3s 0s ease;
			
			.toggle-menu {
				top: 12px;
				right: 12px;
				transition: 0.6s 0.3s ease;
				&:before, &:after {
					left: 50%;
					top: 50%;
					width: 20px;
					transform: translate(-50%, -50%);
				}
				&:before {
					transform: translate(-50%, -50%) rotate(45deg);
				}
				&:after {
					transform: translate(-50%, -50%) rotate(-45deg);
				}
			}
			
			ul, div {
				opacity: 1;
				visibility: visible;
				transition: 0.6s 0.6s ease;
			}
		}
	}
`

const LangBtn = styled.button`
	display: flex;
	margin-left: 12px;
	top: 0;
	height: 40px;
	align-items: center;
	cursor: pointer;
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	border: none;
	background: none;
	color: #fff;
	
	text-shadow: 1px 1px 2px #000;

	letter-spacing: 0.04em;
	text-transform: uppercase;
	/*opacity: 0.6;*/
	transition: opacity 0.3s 0s ease;
	
	&:first-child {
		margin-left: 0;
	}
	
	:hover{
		opacity: 1;
	}
	
	&.disabled {
		opacity: 0.6;
		pointer-events: none;
	}
	
	@media (max-width: 1110px){
		font-size: 28px;
		line-height: 33px;
		height: 33px;
		margin-left: 24px;
	}
`

const LangMenu = styled.div`
	display: flex;
	@media (max-width: 1110px) {
		position: absolute;
		right: 16px;
		bottom: 30px;
	}
`

const MintMenu = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	gap: 20px;
	
	margin-right: 40px;
`


const Dropdown = styled.div`
	width: 20px;
	height: 20px;
`
const Burg = styled.div`
	width: 20px;
	height: 20px;
	:hover{
		svg{
			opacity: .5;
		}
	}
`

const DropdownWarns = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px;
	gap: 5px;

	position: absolute;
	width: 16px;
	height: 16px;
	left: 12px;
	top: 6px;

	background: #F33A33;
	//border: 2px solid #000000;
	border-radius: 100px;
	
	span{
		font-family: 'Roboto', sans-serif;
		font-style: normal;
		font-size: 8px;
		line-height: 9px;
		display: flex;
		align-items: center;
		text-transform: uppercase;
		color: #FFFFFF;
	}
`

const DropdownContent = styled.div`
	position: absolute;
	z-index: 9997;
	
	left: -70px;
	margin-top: 10px;
	background: #161616;
	border-radius: 16px;
	
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 15px;
`

const DropdownItems = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 0px;
`

const DropdownItem = styled(Link)`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 15px;
	gap: 10px;

	width: 188px;
	height: 48px;

	border-radius: 8px;

	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-size: 15px;
	line-height: 18px;
	/* identical to box height */

	letter-spacing: 0.04em;
	text-transform: uppercase;

	color: rgba(255, 255, 255, .4);
	
	:hover{
		background: rgba(255, 255, 255, 0.05);
		color: rgba(255, 255, 255, 1);
		
		g{
			opacity: 1;
		}
	}
	
	img{
		width: 18px;
		height: 18px;
	}
`

const MobileWarns = styled.span`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px;
	gap: 5px;
	
	position: absolute;
	right: 0;
	top: 0;
	
	width: 16px;
	height: 16px;
	

	background: #F33A33;
	//border: 2px solid #000000;
	border-radius: 100px;

	span{
		font-family: 'Roboto', sans-serif;
		font-style: normal;
		font-size: 8px;
		line-height: 9px;
		display: flex;
		align-items: center;
		text-transform: uppercase;
		color: #FFFFFF;
	}
	`