import React, {useEffect, useRef, useState} from 'react';
import ModalRenderStart from "../ModalRenderStart";
import {Scrollbar} from "react-scrollbars-custom";
import MintItemsList from "../MintItemsList";
import Time from "../UI/Time/Time";
import ButtonStyle1 from "../UI/Buttons/ButtonStyle1";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import useWebSocket, {ReadyState} from "react-use-websocket";
import {useTimer} from "react-timer-and-stopwatch";
import {socketServer, SuccessSVG} from "../../../data/data";
import moment from "moment";
import api_mint from "../../../api/Mint";
import {unselectAll} from "../../../store/features/Mint/gearSelectorState";
import Update from "../../functions/updateInventory";
import {selectError, selectMain, selectSuccess} from "../../../store/features/modalSelectorState";
import Tooltip from "../UI/Elements/Tooltip";

const ContinueMint = () => {
    const dispatch = useDispatch()
    const selectedDataMint = useSelector((state) => state.continueMintSelector.data)
    const DeLabConnector = useSelector((state) => state.authSelector.connector)

    const timer = useTimer({
        create: {
            timerWithDuration: {
                time: { // Set to a duration of 30 seconds
                    seconds: 0
                }
            }
        }
    });
    const {resetTimer, timerText} = timer;
    const [selectedItems, setSelectedItems] = useState({})

    const [isRenderStart, setIsRenderStart] = useState(false)
    const [socketUrl, setSocketUrl] = useState(null);
    const didUnmount = useRef(false);
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl,{
        shouldReconnect: (closeEvent) => {
            /*
            useWebSocket will handle unmounting for you, but this is an example of a
            case in which you would not want it to automatically reconnect
          */
            return didUnmount.current === false;
        },
        reconnectAttempts: 10,
        reconnectInterval: 3000,
    });

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    useEffect(() => {
        console.log("DATA MINT")
        console.log(selectedDataMint)

        let items = {}
        let firstWait = false
        Object.keys(selectedDataMint['nft_items_to_burn']).forEach((key, index) =>{

            items[key] = JSON.parse(JSON.stringify(selectedDataMint['nft_items_to_burn'][key])); //делаем копию
            if(selectedDataMint['nft_items_to_burn'][key].burned){
                items[key].status = 'success'
            }else{
                items[key].status = 'wait'
                if(!firstWait) {
                    firstWait = true
                    items[key].open = true
                }
            }

        })
        let requestData = selectedDataMint.request_data

        setSocketUrl(socketServer + `${requestData.websocket_url}`)
        let date = moment(requestData.expiration_datetime).valueOf();
        resetTimer({
            create: {
                timerWithUnixTimestamp: {
                    unixTimestampMilliseconds: date
                }
            }
        }, true)

        Object.keys(items).map((key, i) => {
            const mint_link = api_mint.createMintLink(
                items[key].address,
                requestData.burning_address,
                50000000,
                30000000,
                '')
            items[key].url = mint_link
            items[key].burning_address = requestData.burning_address
        })
        setSelectedItems({...items})

        dispatch(unselectAll())
        document.dispatchEvent(new CustomEvent('gearSelect', {detail: {category: 'all', image: null}}))
        Update.updateInventory(DeLabConnector.address, dispatch, false)
        return () => {
            didUnmount.current = true;
        };
    }, []);
/*
Object.keys(items).map((key, i) => {
                        if (items[key].id && items[key].status === 'success' && items[key].open){
                            console.log("нашли открытый")
                            items[key].open = false
                        }
                    })
                    Object.keys(items).map((key, i) => {
                        if (items[key].id && items[key].status === 'wait'){
                            console.log("сделали закрытым")
                            items[key].open = true
                        }
                    })
 */
    useEffect(()=>{
        if(lastMessage !== null ){
            let dataJson = JSON.parse(lastMessage.data)
            switch (dataJson.type){
                case "nft_burned":
                    console.log(lastMessage)
                    let items = selectedItems
                    console.log(items)
                    Object.keys(items).map((key, i) => {
                        if (dataJson.args.nft_address == items[key].address){
                            items[key].status = 'success'
                        }
                        items[key].open = false
                    })
                    setSelectedItems({...items})
                    let first = false
                    Object.keys(items).map((key, i) => {
                        if (items[key].status === 'wait' && !first){
                            console.log("сделали закрытым")
                            items[key].open = true
                            first = true
                        }
                    })
                    console.log(items)
                    setSelectedItems({...items})
                    dispatch(unselectAll())
                    document.dispatchEvent(new CustomEvent('gearSelect', {detail: {category: 'all', image: null}}))
                    Update.updateInventory(DeLabConnector.address, dispatch, false)
                    break;
                case "dress_up_request_success":
                    dispatch(unselectAll())
                    document.dispatchEvent(new CustomEvent('gearSelect', {detail: {category: 'all', image: null}}))
                    Update.updateInventory(DeLabConnector.address, dispatch, false)
                    dispatch(selectSuccess({title: "mintpage.success.nft_created", description: "mintpage.success.nft_created_description"}))
                    break;
                case "render_started":
                    dispatch(unselectAll())
                    document.dispatchEvent(new CustomEvent('gearSelect', {detail: {category: 'all', image: null}}))
                    Update.updateInventory(DeLabConnector.address, dispatch, false)
                    setIsRenderStart(true)
                    break;
                case "dress_up_request_error":
                    if(dataJson.args.reason) {
                        dispatch(unselectAll())
                        Update.updateInventory(DeLabConnector.address, dispatch, false)
                        dispatch(selectError("Time out"))
                    }
                    break;
                //dispatch(selectError("Не предвиденная ошибка"))
            }
        }
    },[lastMessage])
    //<MintItemsList list={selectedItems}/>
    return (
        <Overlay>
            {(isRenderStart)
                ? <ModalRenderStart setIsRenderStart={setIsRenderStart}/>
                :
                <Scrollbar noScrollX={true}
                           thumbYProps={{style: {background: 'rgba(255, 255, 255, 0.3)'}}}
                >
                    <Window>
                        <Content>
                            <MainBlock>
                                <Head>
                                    <h1>Заминтить </h1>
                                    <span>Для минта ваших NFT необходимо оплатить комиссию сети. Перевод должен быть именно с того кошелька, к которому принадлежит NFT</span>
                                </Head>
                                <Body>
                                    <MintItemsList list={selectedItems}/>
                                </Body>
                                {
                                    timer &&

                                    <BottomBlock>
                                        <Time
                                            first1={timerText.charAt(timerText.length - 5)}
                                            first2={timerText.charAt(timerText.length - 4)}
                                            two1={timerText.charAt(timerText.length - 2)}
                                            two2={timerText.charAt(timerText.length - 1)}
                                        />
                                        <Line/>
                                        <span className={"classic"}>
                                    В случае бездействия по истечении этого времени, минт будет завершен,<span
                                            className={"main"}> а сожжённые вами NFT будут утеряны</span>. Дальнейшее их восстановление будет возможно только оплатив комиссию.
                                </span>
                                    </BottomBlock>
                                }


                            </MainBlock>

                            <ButtonStyle1 onClick={()=>dispatch(selectMain())} text={"Отменить"}/>
                        </Content>
                    </Window>
                </Scrollbar>
            }
        </Overlay>
    );
};

export default ContinueMint;

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0, .5);
  z-index: 9999;

  @media (max-width: 800px) {
    background-color: #161616;
  }

`

const Window = styled.div`
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  gap: 40px;

  position: relative;
  width: 482px;
  left: 0;
  right: 0;
  margin: 120px auto 100px;
  
  background: #161616;
  border-radius: 22px;

  @media (max-width: 800px) {
    margin: 0px 0px;
    position: relative;
    min-width: 100%;
    width: 100%;
    height: calc(100vh - calc(100vh - 100%));
    left: 0;
    top: 0;
    border-radius: 0px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;

  width: 402px;

  @media (max-width: 800px) {
    width: 320px;
  }
`

const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 30px;
  width: 100%;
`

const Head = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 20px;

  
  h1{
    font-family: 'Roboto';
    font-style: normal;
    font-size: 28px;
    line-height: 33px;
    line-height: 150%;
    /* identical to box height */

    text-align: center;

    color: #FFFFFF;

    @media (max-width: 800px) {
      font-size: 20px;
      line-height: 150%; 
    }
  }
  span{
    font-family: 'Roboto';
    font-style: normal;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */
    text-align: center;
    color: #FFFFFF;
    opacity: 0.5;

    @media (max-width: 800px) {
      font-size: 16px;
    }
  }
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 100%;
  `

const BottomBlock = styled.div`
    box-sizing: border-box;

    /* Auto layout */
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 20px;
    width: 100%;  
  
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 16px 16px 16px 16px;
  
    span.classic{
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-size: 14px;
      line-height: 130%;
      /* or 18px */
      color: rgba(255, 255, 255, 0.5);
    }
    span.main{
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-size: 14px;
      line-height: 130%;
      color: #FFFFFF;
    }
      
  `

const Line = styled.div`
  opacity: 0.1;
  background: #FFFFFF;
  height: 1px;
  width: 100%;
`

