import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import ModalRenderStart from "../ModalRenderStart";
import {Scrollbar} from "react-scrollbars-custom";
import ButtonStyle1 from "../UI/Buttons/ButtonStyle1";
import {selectContinueMint, selectError, selectMain, selectMint} from "../../../store/features/modalSelectorState";
import {useDispatch, useSelector} from "react-redux";
import MiniNFT from "../UI/Elements/MiniNFT";
import Time from "../UI/Time/Time";
import mintLoader from "../../../assets/lottie/guys_mint_loader.json";
import Lottie from "lottie-react";
import {useTimer} from "react-timer-and-stopwatch";
import moment from "moment/moment";
import {selectContinueData} from "../../../store/features/ContinueMint/continueMintSelectorState";
import {unselectAll} from "../../../store/features/Mint/gearSelectorState";
import Update from "../../functions/updateInventory";

const isMobile = window.innerWidth <= 800

const ContinueMintItem = () => {
    const dispatch = useDispatch()
    const selectedDataMint = useSelector((state) => state.continueMintSelector.data)
    const DeLabConnector = useSelector((state) => state.authSelector.connector)
    const [isBody, setIsBody] = useState(false);

    const timer = useTimer({
        create: {
            timerWithDuration: {
                time: { // Set to a duration of 30 seconds
                    seconds: 0
                }
            }
        }
    });
    const {resetTimer, timerText} = timer;

    useEffect(() => {
        console.log(selectedDataMint)
        if(selectedDataMint) {
            if (selectedDataMint.locked_by) {
                if (selectedDataMint.metadata.attributes.Type === 'Body')
                    setIsBody(true)

                let date = moment(selectedDataMint.locked_by.expiration_datetime).valueOf();
                resetTimer({
                    create: {
                        timerWithUnixTimestamp: {
                            unixTimestampMilliseconds: date
                        }
                    }
                }, true)
            }else{
                dispatch(unselectAll())
                document.dispatchEvent(new CustomEvent('gearSelect', {detail: {category: 'all', image: null}}))
                Update.updateInventory(DeLabConnector.address, dispatch, false)
                dispatch(selectError("Вещь не участвует в минте"))
            }
        }
    }, []);

    function handleClickBack(){
        dispatch(selectMain())
    }
    function handleClickNext(){
        dispatch(selectContinueData(selectedDataMint.locked_by))
        dispatch(selectContinueMint())
    }
    //Этот body уже добавлен в активный минт. Он будет заблокирован в течение текущей сессии минта!
    return (
        <Overlay>
            <Scrollbar noScrollX={true}>
                <Window>
                    <Content>
                        <MainBlock>
                            <Head>
                                <h1>Незаконченный минт</h1>
                                {
                                    isBody
                                    ?
                                        <span>Этот body уже добавлен в активный минт. Он будет заблокирован в течение текущей сессии минта!</span>
                                    :
                                        <span>Эта вещь уже добавлена в активный минт. Для удачного завершения минта сожгите эту вещь в течение текущей сессии!</span>
                                }
                            </Head>
                            <Body>
                                <TopItem>
                                    <MintItemHead>
                                        <MiniNFT
                                            img={selectedDataMint.cropped_image_url}
                                            title={selectedDataMint.metadata.name}
                                            description={selectedDataMint.metadata.attributes.Class}
                                        />
                                        <Image>
                                            <Lottie animationData={mintLoader} loop={true} />
                                        </Image>
                                    </MintItemHead>
                                </TopItem>
                                <BottomBlock>
                                    <Time
                                        first1={timerText.charAt(timerText.length - 5)}
                                        first2={timerText.charAt(timerText.length - 4)}
                                        two1={timerText.charAt(timerText.length - 2)}
                                        two2={timerText.charAt(timerText.length - 1)}
                                    />
                                    <Line/>
                                    {
                                        isBody
                                            ?
                                            <span className={"classic"}>
                                    В случае бездействия по истечении этого времени, минт будет завершен, <span
                                                className={"main"}>а body будет возвращен в минточную, сожжённые вами NFT будут утеряны</span>, дальнейшее их восстановление будет возможно только оплатив комиссию.
                                    </span>
                                            :
                                            <span className={"classic"}>
                                    В случае бездействия по истечении этого времени, минт будет завершен,<span
                                                className={"main"}> а сожжённые вами NFT будут утеряны</span>. Дальнейшее их восстановление будет возможно только оплатив комиссию.
                                    </span>
                                    }
                                </BottomBlock>
                            </Body>
                            <Buttons>
                                <ButtonStyle1
                                    onClick={handleClickBack}
                                    text={"Отмена"}
                                />
                                <ButtonStyle1
                                    onClick={handleClickNext}
                                    primary
                                    text={isMobile ? "Продолжить" :"Продолжить минт"}
                                />
                            </Buttons>

                        </MainBlock>
                    </Content>
                </Window>
            </Scrollbar>
        </Overlay>
    );
};

export default ContinueMintItem;

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0, .5);
  z-index: 9999;

  @media (max-width: 800px) {
    background-color: #161616;
  }

`

const Window = styled.div`
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  gap: 40px;

  position: relative;
  width: 482px;
  left: 0;
  right: 0;
  margin: 120px auto 100px;
  
  background: #161616;
  border-radius: 22px;

  @media (max-width: 800px) {
    margin: 0px 0px;
    position: relative;
    min-width: 100%;
    width: 100%;
    height: calc(100vh - calc(100vh - 100%));
    left: 0;
    top: 0;
    border-radius: 0px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;

  width: 402px;

  @media (max-width: 800px) {
    width: 320px;
  }
`

const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 30px;
  width: 100%;
`

const Head = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 20px;

  
  h1{
    font-family: 'Roboto';
    font-style: normal;
    font-size: 28px;
    line-height: 33px;
    line-height: 150%;
    /* identical to box height */

    text-align: center;

    color: #FFFFFF;

    @media (max-width: 800px) {
      font-size: 20px;
      line-height: 150%; 
    }
  }
  span{
    font-family: 'Roboto';
    font-style: normal;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */
    text-align: center;
    color: #FFFFFF;
    opacity: 0.5;

    @media (max-width: 800px) {
      font-size: 16px;
    }
  }
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  
  width: 100%;
`



const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 20px;
`

const TopItem = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  isolation: isolate;
  
  width: 100%;

  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px 16px 0px 0px;`

const MintItemHead = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  gap: 10px;
  isolation: isolate;

  width: 100%;
  height: 100px;
`

const BottomBlock = styled.div`
    box-sizing: border-box;

    /* Auto layout */
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 20px;
    width: 100%;  
  
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0px 0px 16px 16px;
  
    span.classic{
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-size: 14px;
      line-height: 130%;
      /* or 18px */
      color: rgba(255, 255, 255, 0.5);
    }
    span.main{
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-size: 14px;
      line-height: 130%;
      color: #FFFFFF;
    }
      
  `

const Image = styled.div`
  width: 30px;
  height: 30px;
  svg{
    width: 30px;
    height: 30px;
  }`

const Line = styled.div`
    opacity: 0.1;
    background: #FFFFFF;
    height: 1px;
    width: 100%;
    `