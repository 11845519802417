import React from 'react';
import RemintInfoElement from "./RemintInfoElement";

const RemintInfoList = ({list, comission, status}) => {
    console.log(list)
    return (
        Object.keys(list).map((el, index)=>(
            <RemintInfoElement key={index}
                               list={list}
                               start={(index === 0)}
                               end={(index === Object.keys(list).length - 1)}
                               img={(list[el].json_before_burn)
                                   ? list[el].json_before_burn.cropped_image_url
                                   : list[el].cropped_image_url}
                               name={(list[el].json_before_burn)
                                   ? list[el].json_before_burn.metadata.name
                                   : list[el].metadata.name}
                               classEl={(list[el].json_before_burn)
                                   ? list[el].json_before_burn.metadata.attributes.Class
                                   : list[el].metadata.attributes.Class}
                               el={el}
                               comission={comission}
            />
        ))
    );
};

export default RemintInfoList;